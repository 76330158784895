import { reactive, computed, onMounted, ref, } from '@nuxtjs/composition-api';
import { flattenDeep } from 'lodash';
export const PRESCRIPTION_DATA = 'PRESCRIPTION_DATA';
const PRESCRIPTION_IMAGE = 'PRESCRIPTION_IMAGE';
export const emptyPrescription = {
    od: {
        sphere: '0.00',
        cylinders: '0.00',
        axis: '0',
        add: '0.00',
        prismHor: '0.00',
        prismVer: '0.00',
        pd: 0,
        baseHor: '',
        baseVer: '',
    },
    os: {
        sphere: '0.00',
        cylinders: '0.00',
        axis: '0',
        add: '0.00',
        prismHor: '0.00',
        prismVer: '0.00',
        pd: 0,
        baseHor: '',
        baseVer: '',
    },
    lensType: 'SingleVision',
    pd: 0,
    prescriptionRequestId: '',
};
const state = reactive({
    prescription: emptyPrescription,
    image: undefined,
});
export const usePrescription = () => {
    const errorMessages = ref([]);
    const warningMessages = ref([]);
    const validateLensResults = ref(null);
    const image = computed(() => state.image);
    const setPrescription = (prescriptionNew) => {
        if (!prescriptionNew) {
            state.prescription = { ...emptyPrescription };
            sessionStorage.removeItem(PRESCRIPTION_DATA);
        }
        else {
            state.prescription = {
                lensType: emptyPrescription.lensType,
                pd: 0,
                od: {
                    ...emptyPrescription.od,
                    sphere: prescriptionNew.od.sphere,
                    cylinders: prescriptionNew.od.cylinders,
                    axis: prescriptionNew.od.axis,
                },
                os: {
                    ...emptyPrescription.os,
                    sphere: prescriptionNew.os.sphere,
                    cylinders: prescriptionNew.os.cylinders,
                    axis: prescriptionNew.os.axis,
                },
                prescriptionRequestId: prescriptionNew.prescriptionRequestId || '',
            };
            sessionStorage.setItem(PRESCRIPTION_DATA, JSON.stringify(state.prescription));
        }
    };
    onMounted(() => {
        // define default lens type in empty prescription
        const prescriptionData = sessionStorage.getItem(PRESCRIPTION_DATA);
        if (prescriptionData)
            state.prescription = JSON.parse(prescriptionData);
        const imageData = sessionStorage.getItem(PRESCRIPTION_IMAGE);
        if (imageData)
            state.image = imageData;
    });
    const prescription = computed(() => state.prescription);
    const validatePrescription = async (config) => {
        errorMessages.value = [];
        warningMessages.value = [];
        validateLensResults.value = null;
        const response = await fetch('/validatePrescriptionAndLens', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                savedPrescription: state.prescription,
                config,
            }),
        });
        const data = await response.json();
        const { validatePrescriptionResults: prescriptionValidateResults, validateLensResults: lensTypeValidationResults } = data;
        if (!prescriptionValidateResults.warning && prescriptionValidateResults.success && lensTypeValidationResults.hasLens) {
            validateLensResults.value = lensTypeValidationResults.itemTypes;
        }
        else if (!prescriptionValidateResults.success) {
            errorMessages.value = flattenDeep(Object.keys(prescriptionValidateResults.errorMessages).map((key) => prescriptionValidateResults.errorMessages[key]));
        }
        else if (!lensTypeValidationResults.hasLens) {
            errorMessages.value = [lensTypeValidationResults.errorMessage];
        }
        else if (prescriptionValidateResults.warning) {
            warningMessages.value = flattenDeep(Object.keys(prescriptionValidateResults.warningMessages).map((key) => prescriptionValidateResults.warningMessages[key]));
        }
    };
    const formatPrescriptionForMagento = (prescriptionData) => {
        var _a;
        // should validate again???
        const localPrescription = prescriptionData || state.prescription;
        return [
            ['OD.Sphere', localPrescription.od.sphere],
            ['OS.Sphere', localPrescription.os.sphere],
            ['OD.Cylinder', localPrescription.od.cylinders],
            ['OS.Cylinder', localPrescription.os.cylinders],
            ['OD.Axis', localPrescription.od.axis],
            ['OS.Axis', localPrescription.os.axis],
            ['OD.BaseVer', localPrescription.od.baseVer],
            ['OS.BaseVer', localPrescription.os.baseVer],
            ['OD.BaseHor', localPrescription.od.baseHor],
            ['OS.BaseHor', localPrescription.os.baseHor],
            ['OD.PrismHor', localPrescription.od.prismHor],
            ['OS.PrismHor', localPrescription.os.prismHor],
            ['OD.PrismVer', localPrescription.od.prismVer],
            ['OS.PrismVer', localPrescription.os.prismVer],
            ['PD', localPrescription.pd],
            ['LensType', localPrescription.lensType],
            ['OS.Add', localPrescription.os.add],
            ['OD.Add', localPrescription.od.add],
            ['OS.PD', localPrescription.os.pd],
            ['OD.PD', localPrescription.od.pd],
            ['PrescriptionRequestId', (_a = localPrescription.prescriptionRequestId) !== null && _a !== void 0 ? _a : ''],
        ];
    };
    const getPrescriptionFromMagento = (customOptions) => {
        if (!customOptions || customOptions.length === 0)
            return emptyPrescription;
        let localPrescription = { ...emptyPrescription };
        const mappedOptions = {
            'OD.Sphere': 'od.sphere',
            'OS.Sphere': 'os.sphere',
            'OD.Cylinder': 'od.cylinders',
            'OS.Cylinder': 'os.cylinders',
            'OD.Axis': 'od.axis',
            'OS.Axis': 'os.axis',
            'OD.BaseVer': 'od.baseVer',
            'OS.BaseVer': 'os.baseVer',
            'OD.BaseHor': 'od.baseHor',
            'OS.BaseHor': 'os.baseHor',
            'OD.PrismHor': 'od.prismHor',
            'OS.PrismHor': 'os.prismHor',
            'OD.PrismVer': 'od.prismVer',
            'OS.PrismVer': 'os.prismVer',
            PD: 'pd',
            LensType: 'lensType',
            'OS.Add': 'os.add',
            'OD.Add': 'od.add',
            'OS.PD': 'os.pd',
            'OD.PD': 'od.pd',
            PrescriptionRequestId: 'prescriptionRequestId',
        };
        const mappedOptionsKeys = Object.keys(mappedOptions);
        customOptions.forEach((option) => {
            if (mappedOptionsKeys.includes(option.label)) {
                const [head, subParam] = mappedOptions[option.label].split('.');
                localPrescription = {
                    ...localPrescription,
                    [head]: subParam ? { ...localPrescription[head], [subParam]: option.values[0].value } : option.values[0].value,
                };
            }
        });
        return localPrescription;
    };
    const storeImage = (imageData) => {
        state.image = imageData;
        sessionStorage.setItem('PRESCRIPTION_IMAGE', imageData);
    };
    const clearImage = () => {
        state.image = undefined;
        sessionStorage.removeItem('PRESCRIPTION_IMAGE');
    };
    const removePrescriptionSessionData = () => {
        sessionStorage.removeItem(PRESCRIPTION_DATA);
        sessionStorage.removeItem(PRESCRIPTION_IMAGE);
    };
    const handleRegularPrescriptionUpdate = async (payload) => {
        const updateReq = await fetch('/update-prescription', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });
        if (!updateReq.ok) {
            return false;
        }
        const updateRes = await updateReq.json();
        return updateRes.isSuccess;
    };
    return {
        image,
        prescription,
        errorMessages,
        warningMessages,
        validateLensResults,
        handleRegularPrescriptionUpdate,
        storeImage,
        clearImage,
        setPrescription,
        validatePrescription,
        formatPrescriptionForMagento,
        getPrescriptionFromMagento,
        removePrescriptionSessionData,
    };
};
