import { combineSphAndCyl } from './helpers/basicPrescription';
import { checkPrism } from './helpers/prism';
/*
 * Library description:
 * The libaray contains a bunch of functions to check if the client
 * provided prescription has suitable lens from our factory.
 * The output contains the following:
 * 1. hasLens: a bool value to indicate if we have lens to order.
 * 2. itemType: an unique lens item type string can be used to query Magento
 *    to get the item. Magento should keep the prism item and coating information
 *    about this item type.
 */
// ERROR MESSAGES
const ERROR_NO_LENS_FIT = 'The prescription provided does not fit any lens we are selling';
const ERROR_BIFOCAL_NOT_AVAILABLE = 'We are not able to build Bifocal lens based on your prescription, please choose other lens type';
const ERROR_FRAMELESS_NOT_AVAILABLE = 'We are not able to build Frameless lens based on your prescription, please choose other lens type';
const ERROR_PROGRESSIVE_NOT_AVAILABLE = 'We are not able to build Progressive lens based on your prescription, please choose other lens type';
// CONST VALUES IN USE
const ITEM_TYPES = {
    SINGLE_VISION: {
        BASIC_INDEX: {
            DEFAULT: {
                WITHOUT_PRISM: 'single-vision-lens-basic-index',
            },
        },
        HIGH_INDEX: {
            DEFAULT: {
                WITHOUT_PRISM: 'single-vision-lens-high-index',
                WITH_PRISM: 'single-vision-lens-high-index-prism',
            },
            HIGH_RX: {
                WITHOUT_PRISM: 'single-vision-lens-high-index-high-rx',
                WITH_PRISM: 'single-vision-lens-high-index-high-rx-prism',
            },
        },
    },
    BIFOCAL: {
        DEFAULT: 'bifocal-lens',
    },
    PROGRESSIVE: {
        DEFAULT: 'progressive-lens',
    },
    FRAMELESS: {
        DEFAULT: 'frameless-lens',
    },
};
const SINGLE_VISION_ITEM_TYPES_TO_FACTORS = {
    BASIC_INDEX: 0,
    HIGH_INDEX: 1,
    HIGH_INDEX_HIGH_RX: 2,
};
const SINGLE_VISION_FACTORS_TO_ITEM_TYPES = {
    0: 'BASIC_INDEX',
    1: 'HIGH_INDEX',
    2: 'HIGH_INDEX_HIGH_RX',
};
const NO_RESULT = -1;
const SINGLE_VISION_RANGES_WITHOUT_PRISM = {
    BASIC_INDEX: {
        SPH: {
            MIN: -9,
            MAX: 6,
        },
        CYL: {
            MIN: -4,
            MAX: 4,
        },
        JOINT: {
            MIN: -8,
            MAX: 4,
        },
    },
    HIGH_INDEX: {
        SPH: {
            MIN: -9,
            MAX: 6,
        },
        CYL: {
            MIN: -4,
            MAX: 4,
        },
        JOINT: {
            MIN: -26,
            MAX: 10,
        },
    },
    HIGH_INDEX_HIGH_RX: {
        SPH: {
            MIN: -20,
            MAX: 10,
        },
        CYL: {
            MIN: -6,
            MAX: 6,
        },
        JOINT: {
            MIN: -26,
            MAX: 10,
        },
    },
};
const SINGLE_VISION_RANGES_WITH_PRISM = {
    HIGH_INDEX: {
        SPH: {
            MIN: -8,
            MAX: 6,
        },
        CYL: {
            MIN: -2,
            MAX: 2,
        },
        JOINT: {
            MIN: -12,
            MAX: 6,
        },
    },
    HIGH_INDEX_HIGH_RX: {
        SPH: {
            MIN: -20,
            MAX: 10,
        },
        CYL: {
            MIN: -6,
            MAX: 6,
        },
        JOINT: {
            MIN: -12,
            MAX: 6,
        },
    },
};
const BIFOCAL_RANGES = {
    SPH: {
        MIN: -9,
        MAX: 6,
    },
    CYL: {
        MIN: -6,
        MAX: 6,
    },
    JOINT: {
        MIN: -15,
        MAX: 6,
    },
    ADD: {
        MIN: 1,
        MAX: 3.5,
    },
};
const PROGRESSIVE_RANGES = {
    SPH: {
        MIN: -10,
        MAX: 8,
    },
    CYL: {
        MIN: -6,
        MAX: 6,
    },
    JOINT: {
        MIN: -14,
        MAX: 8,
    },
    ADD: {
        MIN: 1,
        MAX: 3,
    },
};
const FRAMELESS_RANGES = {
    SPH: {
        MIN: -9,
        MAX: 6,
    },
    CYL: {
        MIN: -4,
        MAX: 4,
    },
    JOINT: {
        MIN: -8,
        MAX: 4,
    },
    ADD: {
        MIN: 1,
        MAX: 3,
    },
};
function singleVisionWithoutPrismCalculateFactorNumber(Sph, Cyl, Joint) {
    if (Sph >= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.SPH.MIN
        && Sph <= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.SPH.MAX
        && Cyl >= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.CYL.MIN
        && Cyl <= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.CYL.MAX
        && Joint >= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.JOINT.MIN
        && Joint <= SINGLE_VISION_RANGES_WITHOUT_PRISM.BASIC_INDEX.JOINT.MAX) {
        return SINGLE_VISION_ITEM_TYPES_TO_FACTORS.BASIC_INDEX;
    }
    if (Sph >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.SPH.MIN
        && Sph <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.SPH.MAX
        && Cyl >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.CYL.MIN
        && Cyl <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.CYL.MAX
        && Joint >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.JOINT.MIN
        && Joint <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX.JOINT.MAX) {
        return SINGLE_VISION_ITEM_TYPES_TO_FACTORS.HIGH_INDEX;
    }
    if (Sph >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.SPH.MIN
        && Sph <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.SPH.MAX
        && Cyl >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.CYL.MIN
        && Cyl <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.CYL.MAX
        && Joint >= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.JOINT.MIN
        && Joint <= SINGLE_VISION_RANGES_WITHOUT_PRISM.HIGH_INDEX_HIGH_RX.JOINT.MAX) {
        return SINGLE_VISION_ITEM_TYPES_TO_FACTORS.HIGH_INDEX_HIGH_RX;
    }
    return NO_RESULT;
}
function singleVisionWithPrismCalculateFactorNumber(Sph, Cyl, Joint) {
    if (Sph >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.SPH.MIN
        && Sph <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.SPH.MAX
        && Cyl >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.CYL.MIN
        && Cyl <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.CYL.MAX
        && Joint >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.JOINT.MIN
        && Joint <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX.JOINT.MAX) {
        return SINGLE_VISION_ITEM_TYPES_TO_FACTORS.HIGH_INDEX;
    }
    if (Sph >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.SPH.MIN
        && Sph <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.SPH.MAX
        && Cyl >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.CYL.MIN
        && Cyl <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.CYL.MAX
        && Joint >= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.JOINT.MIN
        && Joint <= SINGLE_VISION_RANGES_WITH_PRISM.HIGH_INDEX_HIGH_RX.JOINT.MAX) {
        return SINGLE_VISION_ITEM_TYPES_TO_FACTORS.HIGH_INDEX_HIGH_RX;
    }
    return NO_RESULT;
}
/*
 * Single Vision
 * 1. No Prism:
 *  a. Lens SPH range from [-6.00, +4.00], CYL range from [-2.00, +2.00],
 *    SPH + CYL range is from [-8.00, +4.00], same for both eyes, go to Single vision Lens 1.50
 *  b. Lens SPH range from [+4.25,  +6.00],  [-6.25, -8.00], and CYL range from [-2.00, +2.00],
 *    SPH + CYL range is from [-26.00, +10.00], same for both eyes, go to single vision Lens 1.61
 *  c. SPH range from [+6.25, +10.00],  [-8.25, -20.00],  and CYL range from [+2.25 , +6.00] and [-2.25, -6.00],
 *    SPH + CYL range is from [-26.00, +10.00], same for both eyes, go to single vision lens 1.61 high RX.
 * 2. With Prism:
 *  a. If SPH range from [-8.00, +6.00], and CYL range from [-2.00, +2.00], SPH + CYL range is from [-12.00, +6.00],
 *    same for both eyes, go to single vision lens 1.61 high index. PRISM range is from [0.00, 5.00].
 *    There is a PRISM id in Magento needs to add to the order.
 *  b. If SPH range from [-8.25, -20.00], and [+6.25, +10.00], and CYL range from [-2.25, -6.00], and [+2.25, +6.00],
 *    SPH + CYL range is from [-12.00, +6.00], same for both eyes, go to single vision lens high index high RX.
 *    PRISM range is from [0.00, 5.00]. There is a PRISM id in Magento that needs to add to the order.
 */
export function validateSingleVision(prescription) {
    // initialize the result object
    const result = {
        hasLens: false,
        itemTypes: {
            odType: null,
            osType: null,
            orderType: null,
        },
        errorMessage: null,
    };
    // od values
    const odPrismValueHorizontal = prescription.od.prismHor;
    const odPrismValueVertical = prescription.od.prismVer;
    // os values
    const osPrismValueHorizontal = prescription.os.prismHor;
    const osPrismValueVertical = prescription.os.prismVer;
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    // check if prism is enabled
    const { odPrismEnabled, osPrismEnabled } = checkPrism(odPrismValueHorizontal, odPrismValueVertical, osPrismValueHorizontal, osPrismValueVertical);
    // OD
    let odFactor;
    odFactor = !odPrismEnabled ? singleVisionWithoutPrismCalculateFactorNumber(odSph, odCyl, jointOd) : singleVisionWithPrismCalculateFactorNumber(odSph, odCyl, jointOd);
    // OS
    let osFactor;
    osFactor = !osPrismEnabled ? singleVisionWithoutPrismCalculateFactorNumber(osSph, osCyl, jointOs) : singleVisionWithPrismCalculateFactorNumber(osSph, osCyl, jointOs);
    if (odFactor === NO_RESULT || osFactor === NO_RESULT) {
        result.errorMessage = ERROR_NO_LENS_FIT;
        return result;
    }
    // get the max factor between both eyes, we always choose the higher type for both eyes
    const maxFactor = Math.max(odFactor, osFactor);
    // get the OD lens
    if (odPrismEnabled) {
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX') {
            result.itemTypes.odType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.DEFAULT.WITH_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX_HIGH_RX') {
            result.itemTypes.odType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.HIGH_RX.WITH_PRISM;
        }
    }
    else {
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'BASIC_INDEX') {
            result.itemTypes.odType = ITEM_TYPES.SINGLE_VISION.BASIC_INDEX.DEFAULT.WITHOUT_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX') {
            result.itemTypes.odType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.DEFAULT.WITHOUT_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX_HIGH_RX') {
            result.itemTypes.odType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.HIGH_RX.WITHOUT_PRISM;
        }
    }
    // get the OS lens
    if (osPrismEnabled) {
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX') {
            result.itemTypes.osType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.DEFAULT.WITH_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX_HIGH_RX') {
            result.itemTypes.osType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.HIGH_RX.WITH_PRISM;
        }
    }
    else {
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'BASIC_INDEX') {
            result.itemTypes.osType = ITEM_TYPES.SINGLE_VISION.BASIC_INDEX.DEFAULT.WITHOUT_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX') {
            result.itemTypes.osType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.DEFAULT.WITHOUT_PRISM;
        }
        if (SINGLE_VISION_FACTORS_TO_ITEM_TYPES[maxFactor.toString()] == 'HIGH_INDEX_HIGH_RX') {
            result.itemTypes.osType = ITEM_TYPES.SINGLE_VISION.HIGH_INDEX.HIGH_RX.WITHOUT_PRISM;
        }
    }
    // get the order lens, whichever has prism, we should use it as the order lens to send to magento
    if (odPrismEnabled && !osPrismEnabled) {
        result.itemTypes.orderType = result.itemTypes.odType;
    }
    else if (!odPrismEnabled && osPrismEnabled) {
        result.itemTypes.orderType = result.itemTypes.osType;
    }
    else if (result.itemTypes.odType === result.itemTypes.osType) {
        result.itemTypes.orderType = result.itemTypes.odType;
    }
    else {
        // This should not be the case, if ever this is raised, then it's a bug
        throw new Error('The od and os lens should be the same but they are different');
    }
    result.hasLens = true;
    return result;
}
/*
 * Bifocal
 * If the users select bifocal, go to Bifocal lens 1.61. The SPH range we can support is [+0.25, +6.00] and [-9.00, 0.00].
 * The CYL range we can support is [-6.00, +6.00]. ADD range we can support is [1.00, +3.50]. SPH + CYL range is from
 * [-15.00, +6.00], same for both eyes.
 */
export function validateBifocal(prescription) {
    // initialize the result object
    const result = {
        hasLens: false,
        itemTypes: {
            odType: null,
            osType: null,
            orderType: null,
        },
        errorMessage: null,
    };
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // calculate the max sph and cyl
    const maxSph = Math.max(odSph, osSph);
    const maxCyl = Math.max(odCyl, osCyl);
    // calculate the min sph and cyl
    const minSph = Math.min(odSph, osSph);
    const minCyl = Math.min(odCyl, osCyl);
    // od add and od add
    const odAdd = prescription.od.add;
    const osAdd = prescription.os.add;
    const maxAdd = Math.max(odAdd, osAdd);
    const minAdd = Math.min(odAdd, osAdd);
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const maxJoint = Math.max(jointOd, jointOs);
    const minJoint = Math.min(jointOd, jointOs);
    if (minSph >= BIFOCAL_RANGES.SPH.MIN
        && maxSph <= BIFOCAL_RANGES.SPH.MAX
        && minCyl >= BIFOCAL_RANGES.CYL.MIN
        && maxCyl <= BIFOCAL_RANGES.CYL.MAX
        && minJoint >= BIFOCAL_RANGES.JOINT.MIN
        && maxJoint <= BIFOCAL_RANGES.JOINT.MAX
        && minAdd >= BIFOCAL_RANGES.ADD.MIN
        && maxAdd <= BIFOCAL_RANGES.ADD.MAX) {
        result.hasLens = true;
        result.itemTypes.odType = ITEM_TYPES.BIFOCAL.DEFAULT;
        result.itemTypes.osType = ITEM_TYPES.BIFOCAL.DEFAULT;
        result.itemTypes.orderType = ITEM_TYPES.BIFOCAL.DEFAULT;
    }
    else {
        result.errorMessage = ERROR_BIFOCAL_NOT_AVAILABLE;
    }
    return result;
}
/*
 * Progressive
 * If the users select Progressive, go to Multifocal Lens 1.61. The SPH range we can support is [+0.25, +8.00], and
 * [-10.00, 0.00]. The CYL range we can support is [-6.00, +6.00]. ADD range we can support is [1.00, +3.00].
 * SPH + CYL range is from [-14.00, +8.00], same for both eyes.
 */
export function validateProgressive(prescription) {
    // initialize the result object
    const result = {
        hasLens: false,
        itemTypes: {
            odType: null,
            osType: null,
            orderType: null,
        },
        errorMessage: null,
    };
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // calculate the max sph and cyl
    const maxSph = Math.max(odSph, osSph);
    const maxCyl = Math.max(odCyl, osCyl);
    // calculate the min sph and cyl
    const minSph = Math.min(odSph, osSph);
    const minCyl = Math.min(odCyl, osCyl);
    // od add and od add
    const odAdd = prescription.od.add;
    const osAdd = prescription.os.add;
    const maxAdd = Math.max(odAdd, osAdd);
    const minAdd = Math.min(odAdd, osAdd);
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const maxJoint = Math.max(jointOd, jointOs);
    const minJoint = Math.min(jointOd, jointOs);
    if (minSph >= PROGRESSIVE_RANGES.SPH.MIN
        && maxSph <= PROGRESSIVE_RANGES.SPH.MAX
        && minCyl >= PROGRESSIVE_RANGES.CYL.MIN
        && maxCyl <= PROGRESSIVE_RANGES.CYL.MAX
        && minJoint >= PROGRESSIVE_RANGES.JOINT.MIN
        && maxJoint <= PROGRESSIVE_RANGES.JOINT.MAX
        && minAdd >= PROGRESSIVE_RANGES.ADD.MIN
        && maxAdd <= PROGRESSIVE_RANGES.ADD.MAX) {
        result.hasLens = true;
        result.itemTypes.odType = ITEM_TYPES.PROGRESSIVE.DEFAULT;
        result.itemTypes.osType = ITEM_TYPES.PROGRESSIVE.DEFAULT;
        result.itemTypes.orderType = ITEM_TYPES.PROGRESSIVE.DEFAULT;
    }
    else {
        result.errorMessage = ERROR_PROGRESSIVE_NOT_AVAILABLE;
    }
    return result;
}
export function validateFrameless(prescription) {
    const result = {
        hasLens: false,
        itemTypes: {
            odType: null,
            osType: null,
            orderType: null,
        },
        errorMessage: null,
    };
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // calculate the max sph and cyl
    const maxSph = Math.max(odSph, osSph);
    const maxCyl = Math.max(odCyl, osCyl);
    // calculate the min sph and cyl
    const minSph = Math.min(odSph, osSph);
    const minCyl = Math.min(odCyl, osCyl);
    // od add and od add
    const odAdd = Number(prescription.od.add);
    const osAdd = Number(prescription.os.add);
    const maxAdd = Math.max(odAdd, osAdd);
    const minAdd = Math.min(odAdd, osAdd);
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const maxJoint = Math.max(jointOd, jointOs);
    const minJoint = Math.min(jointOd, jointOs);
    if (minSph >= FRAMELESS_RANGES.SPH.MIN
        && maxSph <= FRAMELESS_RANGES.SPH.MAX
        && minCyl >= FRAMELESS_RANGES.CYL.MIN
        && maxCyl <= FRAMELESS_RANGES.CYL.MAX
    // && minJoint >= FRAMELESS_RANGES.JOINT.MIN
    // && maxJoint <= FRAMELESS_RANGES.JOINT.MAX
    // && (
    //   (minAdd >= FRAMELESS_RANGES.ADD.MIN && maxAdd <= FRAMELESS_RANGES.ADD.MAX)
    //   || (!odAdd && !osAdd)
    // )
    ) {
        result.hasLens = true;
        result.itemTypes.odType = ITEM_TYPES.FRAMELESS.DEFAULT;
        result.itemTypes.osType = ITEM_TYPES.FRAMELESS.DEFAULT;
        result.itemTypes.orderType = ITEM_TYPES.FRAMELESS.DEFAULT;
    }
    else {
        result.errorMessage = ERROR_FRAMELESS_NOT_AVAILABLE;
    }
    return result;
}
// Export all the functions
export const lensValidators = {
    validateSingleVision,
    validateBifocal,
    validateProgressive,
    validateFrameless,
};
