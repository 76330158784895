export const getItems = (review) => { var _a; return ((_a = review === null || review === void 0 ? void 0 : review.reviews) === null || _a === void 0 ? void 0 : _a.items) || []; };
export const getReviewId = (item) => `${item.nickname}_${item.created_at}_${item.average_rating}`;
export const getReviewAuthor = (item) => item.nickname;
export const getReviewMessage = (item) => item.text;
export const getReviewRating = (item) => {
    var _a;
    return Number.parseInt((_a = item.ratings_breakdown.find((r) => r.name === 'Rating')) === null || _a === void 0 ? void 0 : _a.value, 10) || 0;
};
export const getReviewDate = (item) => item.created_at;
export const getTotalReviews = (review) => (review === null || review === void 0 ? void 0 : review.review_count) || 0;
export const getAverageRating = (review) => { var _a, _b, _c; return ((_c = ((_b = (_a = review === null || review === void 0 ? void 0 : review.reviews) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.reduce((acc, curr) => Number.parseInt(`${acc}`, 10) + getReviewRating(curr), 0))) !== null && _c !== void 0 ? _c : 0) / ((review === null || review === void 0 ? void 0 : review.review_count) || 1) || 0; };
export const getRatesCount = (_review) => [];
export const getReviewsPage = (review) => { var _a; return ((_a = review === null || review === void 0 ? void 0 : review.reviews.page_info) === null || _a === void 0 ? void 0 : _a.page_size) || 0; };
export const getReviewMetadata = (reviewData) => reviewData === null || reviewData === void 0 ? void 0 : reviewData.map((m) => ({
    ...m,
    values: m.values.map((v) => ({
        label: (Number.parseInt(v.value, 10) || v.value),
        id: v.value_id,
    })),
}));
export const getProductName = (review) => { var _a; return ((_a = review === null || review === void 0 ? void 0 : review.product) === null || _a === void 0 ? void 0 : _a.name) || ''; };
const reviewGetters = {
    getAverageRating,
    getItems,
    getRatesCount,
    getReviewAuthor,
    getReviewDate,
    getReviewId,
    getReviewMessage,
    getReviewMetadata,
    getReviewRating,
    getReviewsPage,
    getTotalReviews,
    getProductName,
};
export default reviewGetters;
