export const mapIndicators = [
    {
        regex: '上ル',
        translation: 'agaru',
    },
    {
        regex: '下ル',
        translation: 'sagaru',
    },
    {
        regex: '東入ル',
        translation: 'higashi-iru',
    },
    {
        regex: '西入ル',
        translation: 'nishi-iru',
    },
];
