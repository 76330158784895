import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import { formatCurrencyWithLang } from '~/helpers/formatCurrency';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
export default defineComponent({
    name: 'ZnCurrency',
    props: {
        price: {
            type: [Number, String],
            default: 0,
            required: false,
        },
        currencyCode: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    setup(props) {
        const { languageCode } = useUrlLanguageParser();
        const { config } = useConfig();
        const selectedCurrency = computed(() => { var _a; return props.currencyCode || ((_a = config.value) === null || _a === void 0 ? void 0 : _a.default_display_currency_code); });
        const currencyValue = computed(() => formatCurrencyWithLang(selectedCurrency.value || 'USD', languageCode, Number(props.price)));
        return {
            currencyValue,
        };
    },
});
