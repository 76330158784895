import { ref, set } from '@nuxtjs/composition-api';
import { stores } from '~/modules/b2b/localization';
const storeConfigPlugin = async ({ $pinia, app }) => {
    // const { data }: { data: { storeConfig?: StoreConfig } } = await app.$vsf.$magento.api.customQuery({ query: StoreConfigGql });
    const { data } = await app.$vsf.$magento.api.storeConfigCustom();
    $pinia.use(({ store }) => {
        var _a, _b, _c;
        if (store.$id !== 'magentoConfig')
            return;
        const storeConfig = ref((_a = data === null || data === void 0 ? void 0 : data.storeConfig) !== null && _a !== void 0 ? _a : {});
        if ((_b = storeConfig.value) === null || _b === void 0 ? void 0 : _b.store_code) {
            // added for enabling the country in cart configurations instead of the storeCode itself
            storeConfig.value.is_region = ((_c = stores[storeConfig.value.store_code]) === null || _c === void 0 ? void 0 : _c.isRegion) === true || false;
        }
        // eslint-disable-next-line no-prototype-builtins
        if (!store.$state.hasOwnProperty('storeConfig')) {
            set(store.$state, 'storeConfig', storeConfig);
        }
        else {
            // eslint-disable-next-line no-param-reassign
            store.$state.storeConfig = storeConfig;
        }
    });
};
export default storeConfigPlugin;
