import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnLineBar',
    props: {
        percent: {
            type: Number,
            required: false,
            default: 100,
        },
    },
});
