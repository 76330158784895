import { ref, defineComponent } from '@nuxtjs/composition-api';
import AccordionItem from './AccordionItem.vue';
export default defineComponent({
    name: 'Accordion',
    components: {
        AccordionItem,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'faq-accordion',
        },
    },
    setup() {
        const openedItems = ref([]);
        const onClicked = (index) => {
            if (openedItems.value.includes(index)) {
                openedItems.value = openedItems.value.filter((item) => item !== index);
                return;
            }
            openedItems.value = [...openedItems.value, index];
        };
        return {
            onClicked,
            openedItems,
        };
    },
});
