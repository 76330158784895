import { defineComponent, computed } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnCheckbox',
    props: {
        label: {
            type: String,
            default: '',
            required: false,
        },
        value: {
            type: Boolean,
            default: false,
            required: false,
        },
        readonly: {
            type: Boolean,
            default: false,
            required: false,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-checkbox',
        },
    },
    setup(props, { emit }) {
        const checked = computed(() => props.value);
        const change = () => {
            if (!props.readonly) {
                emit('change', !checked.value);
            }
        };
        return {
            checked,
            change,
        };
    },
});
