import { ref, onMounted } from '@nuxtjs/composition-api';
export const useClipboard = () => {
    const clipboardAPISupported = ref(false);
    onMounted(() => {
        clipboardAPISupported.value = !!navigator.clipboard;
    });
    const copyToClipboard = async (text) => {
        if (clipboardAPISupported.value) {
            await navigator.clipboard.writeText(text);
        }
        else {
            // workaround if clipboard API is not supported
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.opacity = '0';
            document.body.append(textArea);
            textArea.focus();
            textArea.select();
            document.execCommand('copy');
            textArea.remove();
        }
    };
    return {
        copyToClipboard,
    };
};
