import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({
        search: null,
    });
    const search = async (params) => {
        var _a, _b, _c;
        let results = null;
        try {
            loading.value = true;
            Logger.debug('[Magento] search user orders', { params });
            const { data } = await app.$vsf.$magento.api.customerOrders(params, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            Logger.debug('[Result]:', { data });
            results = (_c = (_b = data === null || data === void 0 ? void 0 : data.customer) === null || _b === void 0 ? void 0 : _b.orders) !== null && _c !== void 0 ? _c : null;
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            Logger.error('useRelatedProducts/search', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    return {
        search,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export default useUserOrder;
export * from './useUserOrder';
