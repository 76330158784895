import { htmlDecode } from '~/helpers/htmlDecoder';
import { getTotalReviews, getAverageRating } from '~/modules/review/getters/reviewGetters';
export const getName = (product) => {
    if (!product) {
        return '';
    }
    return htmlDecode(product.name);
};
export const getSlug = (product, category) => {
    const rewrites = product === null || product === void 0 ? void 0 : product.url_rewrites;
    let url = (product === null || product === void 0 ? void 0 : product.sku) ? `/p/${product.sku}` : '';
    if (!rewrites || rewrites.length === 0) {
        return url;
    }
    url = `/${rewrites[0].url}`;
    // eslint-disable-next-line no-restricted-syntax
    for (const rewrite of rewrites) {
        if (category && category.uid) {
            url = `/${rewrite.url}`;
            break;
        }
    }
    return url;
};
export const getPrice = (product) => {
    let regular = 0;
    let special = null;
    let maximum = null;
    let final = null;
    if (product === null || product === void 0 ? void 0 : product.price_range) {
        regular = product.price_range.minimum_price.regular_price.value;
        final = product.price_range.minimum_price.final_price.value;
        maximum = product.price_range.maximum_price.final_price.value;
        if (final < regular) {
            special = final;
        }
    }
    return {
        regular,
        special,
        maximum,
        final,
    };
};
export const getGallery = (product, maxGallerySize = 4) => {
    const customAttributesForImages = [
        { attribute: 'image_url_front_view', position: 1 },
        { attribute: 'install_video_url', position: 2, alt: 'Video' },
        { attribute: 'image_url_angle_view', position: 3 },
        { attribute: 'image_url_side_view', position: 4 },
        { attribute: 'image_url_carousel_extra', position: 5 },
    ];
    const images = [];
    // if (!product?.media_gallery?.length && !product?.configurable_product_options_selection?.media_gallery?.length) {
    //   return images;
    // }
    // let selectedGallery = product.configurable_product_options_selection?.media_gallery.length
    //   ? product.configurable_product_options_selection.media_gallery
    //   : product.media_gallery;
    let selectedGallery = customAttributesForImages.map((attr) => {
        const image = product[attr.attribute];
        if (image) {
            return {
                url: image,
                position: attr.position,
                label: product.name,
                alt: attr.alt,
            };
        }
        return null;
    }).filter(Boolean);
    const canSort = selectedGallery.every((img) => !Number.isNaN(img.position));
    // eslint-disable-next-line no-restricted-syntax
    if (canSort)
        selectedGallery = selectedGallery.sort((a, b) => a.position - b.position);
    for (const galleryItem of selectedGallery) {
        images.push({
            small: galleryItem.url,
            normal: galleryItem.url,
            big: galleryItem.url,
            position: galleryItem.position,
            alt: galleryItem.alt || galleryItem.label,
        });
    }
    return images.slice(0, maxGallerySize);
};
export const getCoverImage = (product) => {
    if (!product || !product.image) {
        return null;
    }
    return product.image.url;
};
export const getProductThumbnailImage = (product) => {
    if (!product || !(product.image_thumbnail || product.image_url_front_view)) {
        return null;
    }
    return product.image_thumbnail || product.thumbnail.url;
};
export const getAttributes = (products, _filterByAttributeName) => {
    if (!products || !(products === null || products === void 0 ? void 0 : products.configurable_options)) {
        return {};
    }
    const attributes = {};
    const configurableOptions = products.configurable_options;
    // eslint-disable-next-line no-restricted-syntax
    for (const option of configurableOptions) {
        attributes[option.attribute_code] = {
            code: option.attribute_code,
            label: option.label,
            value: option.values.map((value) => {
                const obj = {};
                obj[value.value_index] = value.label;
                return obj;
            }),
        };
    }
    return attributes;
};
export const getDescription = (product) => {
    if (!product || !(product === null || product === void 0 ? void 0 : product.description)) {
        return '';
    }
    return product.description.html;
};
export const getShortDescription = (product) => {
    if (!product || !product.short_description) {
        return '';
    }
    return product.short_description.html;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCategoryIds = (product) => {
    const categoryIds = [];
    if (!(product === null || product === void 0 ? void 0 : product.categories)) {
        return categoryIds;
    }
    return product.categories.map((category) => category.uid);
};
export const getCategory = (product, currentUrlPath) => {
    if (!(product === null || product === void 0 ? void 0 : product.categories) || (product === null || product === void 0 ? void 0 : product.categories.length) === 0) {
        return null;
    }
    const categories = currentUrlPath.split('/');
    categories.pop();
    if (categories.length === 0) {
        return null;
    }
    const categoryPath = categories.join('/');
    // eslint-disable-next-line no-restricted-syntax
    for (const category of product.categories) {
        if (`/${category.url_path}` === categoryPath) {
            return category;
        }
    }
    return null;
};
export const getId = (product) => product.uid;
export const getProductSku = (product) => product.sku;
// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const getTypeId = (product) => product.__typename;
const getCategoryBreadcrumbs = (category) => {
    let breadcrumbs = [];
    if (!category) {
        return [];
    }
    if (Array.isArray(category === null || category === void 0 ? void 0 : category.breadcrumbs)) {
        breadcrumbs = category.breadcrumbs.map((breadcrumb) => ({
            text: breadcrumb.category_name,
            link: `/${breadcrumb.category_url_path}${category.url_suffix || ''}`,
        }));
    }
    breadcrumbs.push({
        text: category.name,
        link: `/${category.url_path}${category.url_suffix || ''}`,
    });
    return breadcrumbs;
};
export const getBreadcrumbs = (product, category) => {
    var _a, _b, _c;
    let breadcrumbs = [];
    if (!product) {
        return breadcrumbs;
    }
    if (category) {
        breadcrumbs = getCategoryBreadcrumbs(category);
    }
    breadcrumbs.push({
        text: getName(product),
        link: `/${(_c = (_b = (_a = product === null || product === void 0 ? void 0 : product.url_rewrites) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : product.url_key}`,
    });
    return breadcrumbs;
};
export { getTotalReviews, getAverageRating } from '~/modules/review/getters/reviewGetters';
export const getProductRelatedProduct = (product) => (product === null || product === void 0 ? void 0 : product.related_products) || [];
export const getProductUpsellProduct = (product) => (product === null || product === void 0 ? void 0 : product.upsell_products) || [];
export const getSwatchData = (swatchData) => swatchData === null || swatchData === void 0 ? void 0 : swatchData.value;
const sortProduct = (a, b) => a.position - b.position;
// eslint-disable-next-line no-underscore-dangle
export const getGroupedProducts = (product) => { var _a; return product.__typename === 'GroupedProduct' && ((_a = product === null || product === void 0 ? void 0 : product.items) === null || _a === void 0 ? void 0 : _a.sort(sortProduct)); };
// eslint-disable-next-line no-underscore-dangle
export const getBundleProducts = (product) => { var _a; return product.__typename === 'BundleProduct' && ((_a = product === null || product === void 0 ? void 0 : product.items) === null || _a === void 0 ? void 0 : _a.sort(sortProduct)); };
const productGetters = {
    getAttributes,
    getAverageRating,
    getBreadcrumbs,
    getCategory,
    getCategoryIds,
    getCoverImage,
    getDescription,
    getGallery,
    getId,
    getName,
    getPrice,
    getProductRelatedProduct,
    getProductSku,
    getProductThumbnailImage,
    getProductUpsellProduct,
    getShortDescription,
    getSlug,
    getTotalReviews,
    getTypeId,
    getSwatchData,
    getGroupedProducts,
    getBundleProducts,
};
export default productGetters;
