import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'DividerLine',
    props: {
        withOr: {
            type: Boolean,
            default: false,
        },
    },
});
