// eslint-disable @typescript-eslint/no-use-before-define
import { defineComponent, ref, computed, watch, useFetch, onMounted, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { ZnDualSelect, ZnSingleSelect, ZnButton, ZnTextButton, ZnModal, ZnFormItem, ZnFormItemError, } from '~/modules/b2b/components';
import { Divider } from '~/modules/b2b/components/beast';
import { PrescriptionManualDefaults } from '~/composables/useContentful/defaults';
import { usePrescription, emptyPrescription, usePdpFlow, } from '~/modules/b2b/prescription/composables';
import { RX_RANGES } from '~/modules/b2b/constants.client';
import isEqual from 'lodash/isEqual';
import { createForm, onFieldValueChange, onFormValuesChange, } from '@formily/core';
import { FormProvider } from '@formily/vue';
import prescriptionFormRules from './prescriptionFormRules';
export default defineComponent({
    name: 'PrescriptionManual',
    components: {
        ZnButton,
        ZnTextButton,
        ZnModal,
        Divider,
        FormProvider,
        ZnFormItem,
        ZnFormItemError,
    },
    props: {
        id: {
            type: String,
            default: 'prescription-manual',
            required: true,
        },
        contentfulId: {
            type: String,
            default: 'Prescription info',
            required: false,
        },
        isVoucher: {
            type: Boolean,
            default: false,
            required: false,
        },
        product: {
            type: Object,
            default: () => ({}),
            required: false,
        },
    },
    setup(props, { emit }) {
        const { image, prescription, setPrescription, } = usePrescription();
        const globalErrorMessages = ref([]);
        const rangeModal = ref(null);
        const initValueHandler = (data) => {
            const copyData = data;
            delete copyData.value;
            return copyData;
        };
        const sphAndCylRanges = computed(() => {
            var _a;
            // after goose launch on 10/15/2024, all ranges should be goose ranges
            // TODO: Create business logic to determine if goose ranges should be used
            if (!((_a = props.product) === null || _a === void 0 ? void 0 : _a.sku))
                return RX_RANGES.default;
            return RX_RANGES[props.product.sku] || RX_RANGES.default;
        });
        // const hasValue = (value) => value !== undefined
        //   && value !== null
        //   && value !== ''
        //   && value !== '0'
        //   && value !== '0.00'
        //   && value !== 0;
        const onFormValueChange = (form) => {
            let prescriptionData = form.values;
            // const nvAddHasValue = hasValue(prescriptionData.od.add);
            // We will let ocr to set values even if CYL is empty
            // -------------------------------------------------------------
            // const odCylHasValue = hasValue(prescriptionData.od.cylinders);
            // const osCylHasValue = hasValue(prescriptionData.os.cylinders);
            // if (!osCylHasValue && hasValue(prescriptionData.os.axis)) {
            //   prescriptionData.os.axis = '0';
            // }
            // if (!odCylHasValue && hasValue(prescriptionData.od.axis)) {
            //   prescriptionData.od.axis = '0';
            // }
            // -------------------------------------------------------------
            if (prescriptionData.od.sphere === '0.00'
                || prescriptionData.od.sphere === 0) {
                prescriptionData = {
                    ...prescriptionData,
                    od: {
                        ...prescriptionData.od,
                        prismHor: 0,
                        baseHor: '',
                        prismVer: 0,
                        baseVer: '',
                    },
                };
            }
            if (prescriptionData.os.sphere === '0.00'
                || prescriptionData.os.sphere === 0) {
                prescriptionData = {
                    ...prescriptionData,
                    os: {
                        ...prescriptionData.os,
                        prismHor: 0,
                        baseHor: '',
                        prismVer: 0,
                        baseVer: '',
                    },
                };
            }
            form.setValues(prescriptionData);
            globalErrorMessages.value = [];
        };
        const form = createForm({
            initialValues: initValueHandler(prescription.value),
            effects() {
                onFormValuesChange((formInstance) => {
                    onFormValueChange(formInstance);
                });
                onFieldValueChange(/.+/, (field) => {
                    if (props.isVoucher) {
                        return;
                    }
                    const reactionsMaps = {
                        /* eslint-disable @typescript-eslint/no-use-before-define */
                        'os.pd': dualPdReactions,
                        'od.pd': dualPdReactions,
                        'os.cylinders': osCylReactions,
                        'od.cylinders': odCylReactions,
                        'os.axis': osAxisReactions,
                        'od.axis': odAxisReactions,
                        'os.sphere': sphReactions,
                        'od.sphere': sphReactions,
                        'od.prismHor': odHorReactions,
                        'od.prismVer': odVerReactions,
                        'os.prismHor': osHorReactions,
                        'os.prismVer': osVerReactions,
                    };
                    // @ts-ignore
                    if (reactionsMaps[field.props.name]) {
                        // @ts-ignore
                        reactionsMaps[field.props.name]();
                    }
                });
            },
        });
        onMounted(() => {
            if (!isEqual(prescription.value, emptyPrescription)) {
                form.validate();
            }
        });
        const { validator: { 
        // pdValidator,
        // pdLeftValidator,
        // pdRightValidator,
        odSphValidator, odSphValidatorVoucher, osSphValidator, osSphValidatorVoucher, odCylValidator, odCylValidatorVoucher, osCylValidator, osCylValidatorVoucher, odAxisValidator, osAxisValidator, 
        // odAddValidator,
        // osAddValidator,
        globalValidator, }, reactions: { dualPdReactions, sphReactions, odCylReactions, osCylReactions, odHorReactions, odVerReactions, osHorReactions, osVerReactions, osAxisReactions, odAxisReactions, }, } = prescriptionFormRules(form, '1', sphAndCylRanges.value);
        const prescriptionData = ref({ ...emptyPrescription });
        const { search, content } = useContentful('prescriptionEditComponent');
        const { isPrescriptionValid } = usePdpFlow();
        const imageModal = ref(null);
        const warningModal = ref(null);
        useFetch(async () => {
            emit('group:loading', { id: props.id, loading: true });
            await search({
                custom: {
                    type: 'prescriptionEditComponent',
                    field: 'title',
                    value: props.contentfulId,
                },
            });
            emit('group:loading', { id: props.id, loading: false });
        });
        watch(() => prescription, (newPrescription) => {
            if (!isEqual(newPrescription.value, form.values)) {
                form.setValues(newPrescription.value);
                // Don't validate if it's a voucher. The validation should be done on submit
                if (!props.isVoucher) {
                    form.validate();
                }
            }
        }, { deep: true });
        const handleGlobalError = (prescriptionInfo) => {
            const res = globalValidator(prescriptionInfo);
            globalErrorMessages.value = res;
            return res.length > 0;
        };
        const closeWarningModal = () => {
            warningModal.value.close();
        };
        const closeAndSaveAndContinue = () => {
            closeWarningModal();
            saveAndContinue();
        };
        const saveAndContinue = () => {
            const prescriptionValues = {
                ...form.values,
                prescriptionRequestId: prescription.value.prescriptionRequestId,
            };
            setPrescription(prescriptionValues);
            emit('group:data', { data: prescriptionValues, select: '', id: props.id });
        };
        const onSubmit = async () => {
            try {
                handleGlobalError(form.values);
                await form.validate();
                if (form.valid) {
                    if (form.warnings.length > 0) {
                        warningModal.value.open();
                        return;
                    }
                    saveAndContinue();
                }
            }
            catch (err) {
                // this is an expected error of form validation
                console.log(err);
                emit('group:submitError', { id: props.id, error: err });
            }
        };
        const prescriptionContents = computed(() => { var _a; return ((_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) || PrescriptionManualDefaults; });
        const openImageModal = () => {
            imageModal.value.open();
        };
        const openRangeModal = () => {
            rangeModal.value.open();
        };
        const handleEmitCancelVoucherItem = () => {
            emit('group:cancelVoucherItem');
            rangeModal.value.close();
        };
        return {
            image,
            prescriptionData,
            prescriptionContents,
            isPrescriptionValid,
            sphAndCylRanges,
            imageModal,
            warningModal,
            form,
            odCylValidator,
            odCylValidatorVoucher,
            odSphValidator,
            odSphValidatorVoucher,
            odAxisValidator,
            osCylValidator,
            osCylValidatorVoucher,
            osSphValidator,
            osSphValidatorVoucher,
            osAxisValidator,
            globalErrorMessages,
            rangeModal,
            ZnDualSelect,
            ZnSingleSelect,
            openImageModal,
            closeWarningModal,
            openRangeModal,
            closeAndSaveAndContinue,
            onSubmit,
            handleEmitCancelVoucherItem,
        };
    },
});
