/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { capitalize } from 'lodash';
import moji from 'moji';
import Kuroshiro from 'kuroshiro';
import KuromojiAnalyzer from 'kuroshiro-analyzer-kuromoji';
import { mapIndicators } from './jpTranslationIndicators';
// Instantiate
// eslint-disable-next-line new-cap
const kuroshiro = new Kuroshiro();
// Initialize
// Here uses async/await, you could also use Promise
kuroshiro.init(new KuromojiAnalyzer({
    dictPath: '/jp-dict',
}));
/**
 *
 * @param {string} text
 * @param {RegExp} regex
 * @returns {string}
 */
export function insertSpaceOnMatch(text, regex) {
    let result = text;
    let iteration = 0;
    let match = [...result.matchAll(regex)];
    while (match.length > 0 && iteration < 100) {
        const firstMatch = /** @type {number} */ (match[0].index);
        result = `${result.slice(0, Math.max(0, firstMatch + 1))} ${result.slice(Math.max(0, firstMatch + 1))}`;
        iteration += 1;
        match = [...result.matchAll(regex)];
    }
    return result;
}
/**
 * @param {string} text
 * @returns {string}
 */
export function insertSpace(text) {
    let result = text;
    result = insertSpaceOnMatch(result, /([A-z]\d)/g); // Kawasaki3-20-23 => Kawasaki 3-20-23
    result = insertSpaceOnMatch(result, /(\d[A-z])/g); // 101goshitsu => 101 goshitsu
    result = insertSpaceOnMatch(result, /(,\w)/g); // 23,haitsu  => 23, haitsu
    return result;
}
/**
 *
 * @param {string} text
 * @returns {string}
 */
export function capitalizeAll(text) {
    return text.split(' ').map((element) => capitalize(element)).join(' ');
}
/**
 * @param {string} text
 * @returns {string}
 */
export function spaceRoomNumber(text) {
    let result = text;
    result = insertSpaceOnMatch(result, /([^\sA-Z][A-Z])/g); // ã‚¬ãƒ¬ãƒ¼ã‚¸ãƒã‚¦ã‚¹Kå·å®¤ => ã‚¬ãƒ¬ãƒ¼ã‚¸ãƒã‚¦ã‚¹ Kå·å®¤
    result = insertSpaceOnMatch(result, /([A-Z][^,-\sA-z])/g); // ã‚¬ãƒ¬ãƒ¼ã‚¸ãƒã‚¦ã‚¹ Kå·å®¤ => ã‚¬ãƒ¬ãƒ¼ã‚¸ãƒã‚¦ã‚¹ K å·å®¤
    return result;
}
/**
 *
 * @param {string} [text]
 * @returns {boolean}
 */
export function isJapanese(text) {
    if (!text) {
        return false;
    }
    for (let index = 0; index < text.length; index++) {
        const char = text.charAt(index);
        if (Kuroshiro.Util.isJapanese(char)) {
            return true;
        }
    }
    return false;
}
/**
 *
 * @param {string} text
 * @param {'normal'|'spaced'|'okurigana'} [mode]
 * @returns {Promise<string>}
 */
export async function kanaToRomaji(text, mode = 'normal') {
    let mojiText = moji(text).convert('ZE', 'HE').convert('ZS', 'HS').toString();
    if (!isJapanese(mojiText)) {
        return mojiText;
    }
    // Look for indicators and replace them with their translation
    mapIndicators.forEach((indicator) => {
        mojiText = mojiText.replace(new RegExp(indicator.regex, 'g'), indicator.translation);
    });
    /** @type {string} */
    let result = await kuroshiro.convert(spaceRoomNumber(mojiText.trim()), {
        to: 'romaji',
        mode,
        romajiSystem: 'passport',
    });
    // Remove invisible characters
    // eslint-disable-next-line no-control-regex
    result = result.replace(/[^\u0000-\u007E]/g, '');
    result = insertSpace(result);
    result = capitalizeAll(result);
    return result;
}
/**
 *
 * @param {string} text
 * @returns {Promise<string>}
 */
export function fullStringToHalf(text) {
    return moji(text).convert('ZE', 'HE').convert('ZS', 'HS').toString();
}
