import { stores } from '../constants';
export const useStoresHelpers = () => {
    const getStoreFromCurrencyCode = (currencyCode) => {
        const storeData = Object.entries(stores);
        const storeIndex = storeData.findIndex(([_, store]) => store.defaultCurrency === currencyCode);
        if (storeIndex !== -1)
            return storeData[storeIndex][0];
        throw new Error(`Store not found for currency code: ${currencyCode}`);
    };
    return {
        getStoreFromCurrencyCode,
    };
};
