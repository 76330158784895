import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnTextButton',
    props: {
        text: {
            type: String,
            required: false,
            default: null,
        },
        customClasses: {
            type: String,
            required: false,
            default: 'text-info cursor-pointer text-sm',
        },
        type: {
            type: String,
            required: false,
            default: 'text', // text | back button
        },
        dataTest: {
            type: String,
            required: false,
            default: 'zn-text-btn',
        },
    },
    emits: ['click'],
});
