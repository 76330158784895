import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { createArrayOfOptions } from '../../utils';
export default defineComponent({
    name: 'ZnDualSelect',
    directives: {
        clickOutside,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: undefined,
        },
        title: {
            type: String,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 3,
        },
        step: {
            type: Number,
            default: 0.25,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-dual-select',
        },
    },
    emits: ['change'],
    setup(props, { emit }) {
        const selected = computed(() => props.value);
        const isActive = ref(false);
        const dualSelectElm = ref(null);
        const optionsList = computed(() => createArrayOfOptions({
            start: props.min, end: props.max, step: props.step, formatted: true,
        }).map((val) => val.label));
        const minusOptionsList = computed(() => optionsList.value.filter((option) => Number(option) <= 0).sort((a, b) => Number(b) - Number(a)));
        const plusOptionsList = computed(() => optionsList.value.filter((option) => Number(option) >= 0));
        const dualDropdownStyle = computed(() => {
            if (dualSelectElm.value) {
                const bottom = window.innerHeight - dualSelectElm.value.getBoundingClientRect().top;
                if (bottom > 300)
                    return '!mt-1';
            }
            return '!bottom-3.5';
        });
        const toggleActive = () => {
            isActive.value = !isActive.value;
        };
        const toggleOutside = () => {
            if (isActive.value)
                toggleActive();
        };
        const emitChange = (val) => {
            if (isActive.value)
                toggleActive();
            emit('change', val.toFixed(2));
        };
        return {
            selected,
            isActive,
            optionsList,
            minusOptionsList,
            plusOptionsList,
            dualSelectElm,
            dualDropdownStyle,
            emitChange,
            toggleActive,
            toggleOutside,
        };
    },
});
