import { ref, computed, defineComponent, watchEffect, } from '@nuxtjs/composition-api';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { createArrayOfOptions } from '../../utils';
export default defineComponent({
    name: 'ZnSingleSelect',
    directives: {
        clickOutside,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        value: {
            type: [String, Number],
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: 'Select an option',
        },
        readonly: {
            type: Boolean,
            default: true,
        },
        min: {
            type: Number,
            default: 1,
        },
        max: {
            type: Number,
            default: 10,
        },
        step: {
            type: Number,
            default: 1,
        },
        dataTestPrefix: {
            type: String,
            default: 'zn-single-select',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['input', 'change'],
    setup(props, { emit }) {
        const inputValue = ref(props.value.toString() || '');
        const showOptions = ref(false);
        const searchTerm = ref('');
        const input = ref(null);
        const wrapper = ref(null);
        const optionsList = computed(() => createArrayOfOptions({
            start: props.min,
            end: props.max,
            step: props.step,
        }));
        watchEffect(() => {
            inputValue.value = props.value.toString();
        });
        const searchResult = computed(() => optionsList.value.filter((item) => {
            const searchItem = typeof item.label === 'number' ? `${item.label}` : item.label;
            return searchItem
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase());
        }));
        function handleInput(evt) {
            emit('input', evt.target.value);
            searchTerm.value = evt.target.value;
            inputValue.value = searchTerm.value;
        }
        const handleClickOutside = () => {
            if (showOptions.value) {
                showOptions.value = false;
            }
        };
        function handleClick(item) {
            emit('change', item);
            searchTerm.value = '';
            inputValue.value = item;
            showOptions.value = false;
        }
        const handleInputClick = () => {
            if (props.readonly) {
                showOptions.value = true;
            }
        };
        const handleShowOptions = () => {
            if (!props.disabled) {
                showOptions.value = !showOptions.value;
            }
        };
        return {
            showOptions,
            searchTerm,
            inputValue,
            input,
            wrapper,
            searchResult,
            handleInput,
            handleClick,
            handleInputClick,
            handleShowOptions,
            handleClickOutside,
        };
    },
});
