import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnTag',
    props: {
        label: {
            type: String,
            required: false,
            default: '',
        },
        color: {
            type: String,
            required: false,
            default: 'bg-primary',
        },
        textColor: {
            type: String,
            required: false,
            default: 'text-black',
        },
    },
});
