import { getName, getSlug as getSlugGetter, getProductThumbnailImage } from '~/modules/catalog/product/getters/productGetters';
export const getItems = (cart) => {
    if (!cart || !cart.items) {
        return [];
    }
    return cart.items;
};
export const getItemName = (product) => getName(product.product);
export const getSlug = (product) => getSlugGetter(product.product);
export const getItemImage = (product) => getProductThumbnailImage(product.product);
export const getItemPrice = (product) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!product || !product.prices) {
        return {
            regular: 0,
            special: 0,
        };
    }
    if (product.prices) {
        return {
            regular: product.prices.row_total.value || 0,
            special: product.prices.total_item_discount.value || 0,
        };
    }
    const regularPrice = (_d = (_c = (_b = (_a = product.product) === null || _a === void 0 ? void 0 : _a.price_range) === null || _b === void 0 ? void 0 : _b.minimum_price) === null || _c === void 0 ? void 0 : _c.regular_price) === null || _d === void 0 ? void 0 : _d.value;
    const specialPrice = (_h = (_g = (_f = (_e = product.product) === null || _e === void 0 ? void 0 : _e.price_range) === null || _f === void 0 ? void 0 : _f.minimum_price) === null || _g === void 0 ? void 0 : _g.final_price) === null || _h === void 0 ? void 0 : _h.value;
    return {
        regular: regularPrice || 0,
        special: specialPrice || 0,
        // @ts-ignore
        credit: Math.round(specialPrice / 10),
        discountPercentage: 100 - Math.round((specialPrice / regularPrice) * 100),
        total: (_k = (_j = product.prices) === null || _j === void 0 ? void 0 : _j.row_total) === null || _k === void 0 ? void 0 : _k.value,
    };
};
export const productHasSpecialPrice = (product) => getItemPrice(product).regular < getItemPrice(product).special;
export const getItemQty = (product) => product.quantity;
export const getItemAttributes = ({ product }, _filterByAttributeName) => {
    const attributes = {};
    if (!product || !product.configurable_options) {
        return attributes;
    }
    const configurableOptions = product.configurable_options;
    // eslint-disable-next-line no-restricted-syntax
    for (const option of configurableOptions) {
        attributes[option.attribute_code] = {
            name: option.attribute_code,
            label: option.label,
            value: option.values.map((value) => {
                const obj = {};
                obj[value.value_index] = value.label;
                return obj;
            }),
        };
    }
    return attributes;
};
export const getItemSku = (product) => { var _a; return ((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.sku) || ''; };
const calculateDiscounts = (discounts) => discounts.reduce((a, b) => Number.parseFloat(`${a}`) + Number.parseFloat(`${b.amount.value}`), 0);
export const getTotals = (cart) => {
    if (!cart || !cart.prices)
        return {};
    const subtotal = cart.prices.subtotal_including_tax.value;
    return {
        total: cart.prices.grand_total.value,
        subtotal: cart.prices.subtotal_including_tax.value,
        special: (cart.prices.discounts) ? subtotal - calculateDiscounts(cart.prices.discounts) : subtotal,
    };
};
export const getShippingPrice = (cart) => {
    if (!(cart === null || cart === void 0 ? void 0 : cart.shipping_addresses)) {
        return 0;
    }
    return cart === null || cart === void 0 ? void 0 : cart.shipping_addresses.reduce((acc, shippingAddress) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { selected_shipping_method } = shippingAddress;
        if (selected_shipping_method) {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            return acc + selected_shipping_method.amount.value;
        }
        return acc;
    }, 0);
};
export const getTotalItems = (cart) => {
    if (!cart) {
        return 0;
    }
    return cart.total_quantity;
};
export const getConfiguredVariant = (product) => (product === null || product === void 0 ? void 0 : product.configured_variant) || null;
export const getCoupons = (cart) => (Array.isArray(cart === null || cart === void 0 ? void 0 : cart.applied_coupons) ? cart.applied_coupons.map((c) => ({
    id: c.code,
    name: c.code,
    value: 0,
    code: c.code,
})) : []);
export const getDiscounts = (cart) => {
    var _a;
    return (Array.isArray((_a = cart === null || cart === void 0 ? void 0 : cart.prices) === null || _a === void 0 ? void 0 : _a.discounts) ? cart.prices.discounts.map((d) => ({
        id: d.label,
        name: d.label,
        description: '',
        value: d.amount.value,
        code: d.label,
    })) : []);
};
export const getDiscountAmount = (cart) => { var _a, _b; return calculateDiscounts((_b = (_a = cart === null || cart === void 0 ? void 0 : cart.prices) === null || _a === void 0 ? void 0 : _a.discounts) !== null && _b !== void 0 ? _b : []); };
export const getAppliedCoupon = (cart) => (Array.isArray(cart === null || cart === void 0 ? void 0 : cart.applied_coupons) && (cart === null || cart === void 0 ? void 0 : cart.applied_coupons.length) > 0 ? {
    id: cart.applied_coupons[0].code,
    name: cart.applied_coupons[0].code,
    value: 0,
    code: cart.applied_coupons[0].code,
} : null);
export const getSelectedShippingMethod = (cart) => {
    var _a, _b;
    return (((_a = cart === null || cart === void 0 ? void 0 : cart.shipping_addresses) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? (_b = cart === null || cart === void 0 ? void 0 : cart.shipping_addresses[0]) === null || _b === void 0 ? void 0 : _b.selected_shipping_method
        : null);
};
export const getAvailablePaymentMethods = (cart) => cart === null || cart === void 0 ? void 0 : cart.available_payment_methods.map((p) => ({
    label: p.title,
    value: p.code,
}));
export const getStockStatus = (product) => product.product.stock_status;
const cartGetters = {
    getAppliedCoupon,
    getAvailablePaymentMethods,
    getCoupons,
    getDiscounts,
    getItemAttributes,
    getItemImage,
    getItemName,
    getSlug,
    getItemPrice,
    getItemQty,
    getItems,
    getItemSku,
    getSelectedShippingMethod,
    getShippingPrice,
    getTotalItems,
    getTotals,
    getDiscountAmount,
    productHasSpecialPrice,
    getStockStatus,
    getConfiguredVariant,
};
export default cartGetters;
