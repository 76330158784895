import { computed, ref, defineComponent, useFetch, onMounted, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { PrescriptionManualDefaults } from '~/composables/useContentful/defaults';
import { ZnModal } from '~/modules/b2b/components';
export default defineComponent({
    name: 'PrescriptionDetailModal',
    components: {
        ZnModal,
    },
    props: {
        prescription: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const modal = ref(null);
        const { search, content } = useContentful('prescriptionEditComponent');
        useFetch(async () => {
            await search({
                custom: {
                    type: 'prescriptionEditComponent',
                    field: 'title',
                    value: 'Prescription info',
                },
            });
        });
        onMounted(() => {
            modal.value.open();
        });
        const prescriptionContents = computed(() => { var _a; return ((_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) || PrescriptionManualDefaults; });
        const setTwoRoundDecimal = (value) => Number(value).toFixed(2);
        return {
            modal,
            prescriptionContents,
            setTwoRoundDecimal,
        };
    },
});
