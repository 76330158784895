import { isMobile } from 'mobile-device-detect';
import { defineComponent, ref, nextTick, } from '@nuxtjs/composition-api';
import { OcrModal } from '~/modules/b2b/prescription/components/ocr/components';
import { useOcr } from '~/modules/b2b/prescription';
import { ZnCard, ZnModal, ZnButton } from '~/modules/b2b/components';
import { usePrescription } from '~/modules/b2b/prescription/composables';
export default defineComponent({
    name: 'TakePictureCard',
    components: {
        OcrModal,
        ZnCard,
        ZnModal,
        ZnButton,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'take-picture-card',
        },
    },
    emits: ['click', 'loading'],
    setup(props, { emit }) {
        const ocrModal = ref(null);
        const modal = ref(null);
        const imageData = ref(null);
        const nativeCameraFileInput = ref(null);
        const { ocrPrescription, prescription, prescriptionHasPrism } = useOcr();
        const { setPrescription, storeImage } = usePrescription();
        const handleClick = () => {
            var _a;
            if (isMobile) {
                (_a = nativeCameraFileInput === null || nativeCameraFileInput === void 0 ? void 0 : nativeCameraFileInput.value) === null || _a === void 0 ? void 0 : _a.click();
                return;
            }
            ocrModal.value.open();
        };
        const continueWithoutPrism = () => {
            modal.value.close();
            emit('loading', false);
            if (imageData.value instanceof File) {
                // convert file async and store in sessionStorage
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64data = reader.result;
                    storeImage(base64data);
                };
                reader.readAsDataURL(imageData.value);
            }
            else {
                storeImage(imageData.value);
            }
            setPrescription(prescription.value);
            nextTick(() => emit('click', props.item));
        };
        const processImage = async (data, isUpload = false) => {
            imageData.value = data;
            emit('loading', true);
            try {
                await ocrPrescription(imageData.value, isUpload);
            }
            catch (err) {
                console.log(err);
            }
            if (!prescriptionHasPrism.value) {
                continueWithoutPrism();
            }
            else {
                emit('loading', false);
                modal.value.open();
            }
        };
        const onNativeCamChanged = (e) => {
            var _a;
            const file = (_a = nativeCameraFileInput === null || nativeCameraFileInput === void 0 ? void 0 : nativeCameraFileInput.value) === null || _a === void 0 ? void 0 : _a.files[0];
            /* data from native camera return as blob. so we set upload flag true while calling composition function */
            processImage(file, true);
        };
        return {
            modal,
            ocrModal,
            nativeCameraFileInput,
            isMobile,
            handleClick,
            processImage,
            onNativeCamChanged,
            continueWithoutPrism,
        };
    },
});
