import { useContext, computed, reactive, } from '@nuxtjs/composition-api';
const products = reactive(new Map());
export const useProductPrices = () => {
    const { $vsf } = useContext();
    const getProductPriceBySku = async (sku) => {
        if (products.has(sku)) {
            return products.get(sku);
        }
        const prdPrice = await $vsf.$magento.api.getProductPriceBySku({
            filter: {
                sku: {
                    eq: sku,
                },
            },
        });
        products.set(sku, prdPrice);
        return prdPrice;
    };
    return {
        getProductPriceBySku,
        products: computed(() => products),
    };
};
