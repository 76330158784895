const formatCurrency = (value, locale, options) => {
    if (typeof value === 'string') {
        // eslint-disable-next-line no-param-reassign
        value = Number(value);
    }
    // eslint-disable-next-line no-param-reassign
    return new Intl.NumberFormat(locale, { style: 'currency', ...options }).format(value);
};
/* currency formatter */
export function currencyFormatter(currency, lang) {
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
    }).format;
}
export function formatCurrencyWithLang(currency, lang, value) {
    return currencyFormatter(currency, lang)(value);
}
export default formatCurrency;
