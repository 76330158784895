/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
export const clickOutside = {
    bind(el, binding) {
        const closeHandler = binding.value;
        el._outsideClickHandler = (event) => {
            if (!el.contains(event.target)) {
                event.stopPropagation();
                closeHandler(event, el);
            }
        };
        document.addEventListener('mousedown', el._outsideClickHandler);
        document.addEventListener('touchstart', el._outsideClickHandler);
    },
    unbind(el) {
        document.removeEventListener('mousedown', el._outsideClickHandler);
        document.removeEventListener('touchstart', el._outsideClickHandler);
        el._outsideClickHandler = null;
    },
};
