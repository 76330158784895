//
//
//
//
//
//
//
//
//
//
//

import {
  connect, Field, mapProps, useForm,
} from '@formily/vue';
import Vue from 'vue';
import ZnFormDecorator from './ZnFormDecorator.vue';

const FormItem = connect(
  ZnFormDecorator,
  mapProps(
    { validateStatus: true, required: true, disabled: true },
    (props, field) => {
      const form = useForm();
      return {
        field,
        form: form.value,
      };
    },
  ),
);

export default {
  name: 'ZnFormItem',
  components: {
    Field,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    reactions: {
      type: Function,
      required: false,
      default: () => {},
    },
    validator: {
      type: [String, Function, Object, Array],
      default: '',
    },
    component: {
      type: Vue.component,
      default: '',
    },
    componentProps: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
      required: false,
    },
  },
  setup() {
    return {
      FormItem,
    };
  },
};
