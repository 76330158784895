import { useRoute, useContext, computed } from '@nuxtjs/composition-api';
import { defaultStore, stores } from '../constants';
export const useUrlLanguageParser = () => {
    const { app } = useContext();
    const route = useRoute();
    const parsePath = (path) => {
        const pathParts = path.split('/');
        let storeCode = defaultStore;
        let languageCode = stores[storeCode].defaultLanguage;
        if (pathParts[1] in stores) {
            [, storeCode] = pathParts;
            const store = stores[storeCode];
            languageCode = storeCode && pathParts[2] in store.languages
                ? pathParts[2]
                : store.defaultLanguage;
        }
        const resourcePath = path
            .replace(`/${storeCode}/`, '/')
            .replace(`/${languageCode}/`, '/');
        return {
            storeCode,
            languageCode,
            pathParts,
            resourcePath,
        };
    };
    const showZendeskChat = computed(() => {
        const { languageCode, } = parsePath(route.value.path);
        const enabledLanguagesForZendesk = new Set(['en', 'gb', 'au']);
        return enabledLanguagesForZendesk.has(languageCode);
    });
    const localePath = (path, query) => {
        const { storeCode, languageCode, } = parsePath(route.value.path);
        const { resourcePath: targetResourcePath, } = parsePath(path);
        const localizedPath = `/${storeCode}/${languageCode}${targetResourcePath}`;
        return app.localePath({
            path: localizedPath,
            query,
        });
    };
    const { storeCode, languageCode, pathParts, resourcePath, } = parsePath(route.value.path);
    return {
        storeCode,
        languageCode,
        pathParts,
        store: stores[storeCode],
        resourcePath,
        showZendeskChat,
        parsePath,
        localePath,
    };
};
