/** This enumeration states whether a product stock status is in stock or out of stock */
export var ProductStockStatus;
(function (ProductStockStatus) {
    ProductStockStatus["InStock"] = "IN_STOCK";
    ProductStockStatus["OutOfStock"] = "OUT_OF_STOCK";
})(ProductStockStatus || (ProductStockStatus = {}));
/** This enumeration indicates whether to return results in ascending or descending order */
export var SortEnum;
(function (SortEnum) {
    SortEnum["Asc"] = "ASC";
    SortEnum["Desc"] = "DESC";
})(SortEnum || (SortEnum = {}));
export var SubscriptionStatusesEnum;
(function (SubscriptionStatusesEnum) {
    SubscriptionStatusesEnum["NotActive"] = "NOT_ACTIVE";
    SubscriptionStatusesEnum["Subscribed"] = "SUBSCRIBED";
    SubscriptionStatusesEnum["Unconfirmed"] = "UNCONFIRMED";
    SubscriptionStatusesEnum["Unsubscribed"] = "UNSUBSCRIBED";
})(SubscriptionStatusesEnum || (SubscriptionStatusesEnum = {}));
export var WishlistCartUserInputErrorType;
(function (WishlistCartUserInputErrorType) {
    WishlistCartUserInputErrorType["InsufficientStock"] = "INSUFFICIENT_STOCK";
    WishlistCartUserInputErrorType["NotSalable"] = "NOT_SALABLE";
    WishlistCartUserInputErrorType["ProductNotFound"] = "PRODUCT_NOT_FOUND";
    WishlistCartUserInputErrorType["Undefined"] = "UNDEFINED";
})(WishlistCartUserInputErrorType || (WishlistCartUserInputErrorType = {}));
// # sourceMappingURL=GraphQL.d.ts.map
