export var SortingOptionsValuesEnum;
(function (SortingOptionsValuesEnum) {
    SortingOptionsValuesEnum["DEFAULT"] = "";
    SortingOptionsValuesEnum["NAME_ASC"] = "name_ASC";
    SortingOptionsValuesEnum["NAME_DESC"] = "name_DESC";
    SortingOptionsValuesEnum["PRICE_ASC"] = "price_ASC";
    SortingOptionsValuesEnum["PRICE_DESC"] = "price_DESC";
})(SortingOptionsValuesEnum || (SortingOptionsValuesEnum = {}));
export const sortingOptions = [
    {
        label: 'Sort: Default',
        value: SortingOptionsValuesEnum.DEFAULT,
    },
    {
        label: 'Sort: Name A-Z',
        value: SortingOptionsValuesEnum.NAME_ASC,
    },
    {
        label: 'Sort: Name Z-A',
        value: SortingOptionsValuesEnum.NAME_DESC,
    },
    {
        label: 'Sort: Price from low to high',
        value: SortingOptionsValuesEnum.PRICE_ASC,
    },
    {
        label: 'Sort: Price from high to low',
        value: SortingOptionsValuesEnum.PRICE_DESC,
    },
];
