import { defineComponent, ref, watch, computed, useContext, } from '@nuxtjs/composition-api';
import { ZnModal, ZnSelect, } from '~/modules/b2b/components';
import { useCheckout } from '~/modules/b2b/checkout/composables';
import { useGuestUser } from '~/modules/b2b/customer/composables';
import { useUrlLanguageParser, stores } from '~/modules/b2b/localization';
import { useCart } from '~/composables';
import { QUEST_GOOSE_BUNDLE_SKU } from '~/modules/b2b/constants.client';
export default defineComponent({
    name: 'LanguageSelector',
    components: {
        ZnSelect,
        ZnModal,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close', 'loading'],
    setup(props, { emit }) {
        const { app } = useContext();
        const { clear, cartType } = useCart();
        const { $vsf: { $magento: { config: { state } } }, } = app;
        const { storeCode: currentStoreCode, languageCode: currentLanguageCode, pathParts, } = useUrlLanguageParser();
        const { updateUser, removeTokens, } = useGuestUser();
        const { clearSessionStorage } = useCheckout();
        const getStoreOption = (store) => ({
            label: stores[store].name,
            code: store,
        });
        const getLanguageOption = (store, language) => {
            var _a, _b;
            return ({
                label: (_b = (_a = stores[store]) === null || _a === void 0 ? void 0 : _a.languages[language]) === null || _b === void 0 ? void 0 : _b.name,
                code: language,
            });
        };
        const languageSelectionModal = ref(null);
        const selectedStoreCode = ref(currentStoreCode);
        const selectedLanguageCode = ref(currentLanguageCode);
        const modalVisible = computed(() => props.isVisible);
        watch(modalVisible, (newValue, _) => {
            if (newValue === true) {
                languageSelectionModal.value.open();
            }
            else {
                languageSelectionModal.value.close();
            }
        });
        const storeOptions = Object.keys(stores).map((store) => getStoreOption(store));
        const updateLanguage = (value) => {
            selectedLanguageCode.value = value;
        };
        watch(selectedStoreCode, (newValue, _) => {
            if (newValue) {
                updateLanguage(stores[newValue].defaultLanguage);
            }
        });
        const getLanguages = () => {
            var _a;
            return Object.keys((_a = stores[selectedStoreCode.value]) === null || _a === void 0 ? void 0 : _a.languages).map((language) => getLanguageOption(selectedStoreCode.value, language));
        };
        const confirmSelection = async () => {
            const storeCode = selectedStoreCode.value;
            const languageCode = selectedLanguageCode.value;
            const store = stores[storeCode];
            const locale = `${storeCode}/${languageCode}`;
            emit('loading', true);
            app.$vsf.$magento.config.state.setStore(storeCode);
            app.$vsf.$magento.config.state.setCurrency(store.defaultCurrency);
            app.$vsf.$magento.config.state.setLocale(locale);
            /* if store is changed then redirect to homepage */
            let newStoreUrl = decodeURIComponent(app.switchLocalePath(locale));
            if (selectedLanguageCode.value !== currentLanguageCode) {
                await updateUser({
                    languageCode: selectedLanguageCode.value,
                });
            }
            if (selectedStoreCode.value !== currentStoreCode) {
                clear({}); // clear cart
                clearSessionStorage();
                state.removeCartId();
                removeTokens();
                const areWeInGoose = cartType.value === QUEST_GOOSE_BUNDLE_SKU || pathParts.at(-1) === 'quest-3s';
                newStoreUrl = areWeInGoose ? `/${locale}/quest/products/quest-3s` : `/${locale}/quest/products/quest-3`;
            }
            emit('close');
            window.location.replace(newStoreUrl);
        };
        return {
            languageSelectionModal,
            storeOptions,
            selectedStoreCode,
            getLanguages,
            selectedLanguageCode,
            updateLanguage,
            confirmSelection,
        };
    },
});
