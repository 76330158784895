import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { saveBillingAddressCommand } from '~/modules/checkout/composables/useBilling/commands/saveBillingAddressCommand';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import useCart from '~/modules/checkout/composables/useCart';
/**
 * Allows loading and saving billing information
 * of the current cart.
 *
 * See the {@link UseBillingInterface} for a list of methods and values available in this composable.
 */
export function useBilling() {
    const context = useContext();
    const { load: loadShippingAddress, save: saveShippingAddress } = useShippingProvider();
    const { cart, load: loadCart } = useCart();
    const loading = ref(false);
    const error = ref({
        load: null,
        save: null,
    });
    const load = async ({ customQuery = null, customHeaders = {} } = {}) => {
        var _a, _b, _c;
        Logger.debug('useBilling.load');
        let billingInfo = null;
        try {
            loading.value = true;
            if (!((_a = cart === null || cart === void 0 ? void 0 : cart.value) === null || _a === void 0 ? void 0 : _a.billing_address)) {
                await loadCart({ customQuery, customHeaders });
            }
            billingInfo = (_c = (_b = cart === null || cart === void 0 ? void 0 : cart.value) === null || _b === void 0 ? void 0 : _b.billing_address) !== null && _c !== void 0 ? _c : null;
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useBilling/load', err);
        }
        finally {
            loading.value = false;
        }
        return billingInfo;
    };
    const save = async ({ billingDetails, customQuery = null, customHeaders = {} }) => {
        Logger.debug('useBilling.save');
        let billingInfo = null;
        try {
            loading.value = true;
            billingInfo = await saveBillingAddressCommand.execute(context, cart.value.id, billingDetails, customQuery, customHeaders);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            /**
             * This is a workaround needed due to Magento GraphQL API
             * cleaning the Shipping method after defining the billing address
             */
            /**
             * TODO: Remove this workaround when the following issue is fixed:
             * Beast express checkout fails when Promise all is used.
             * As for beast checkout is all in one action and then the cart is cleared
             * this workaround is commented out.
             */
            // const shippingMethod = await loadShippingAddress();
            // Logger.debug('[Magento]: Defining the shipping method as:', JSON.stringify(shippingMethod, null, 2));
            // await saveShippingAddress({
            //   shippingMethod: {
            //     carrier_code: shippingMethod?.carrier_code,
            //     method_code: shippingMethod?.method_code,
            //   },
            // });
            /**
             * End of GraphQL Workaround
             */
            error.value.save = null;
        }
        catch (err) {
            error.value.save = err;
            Logger.error('useBilling/save', err);
        }
        finally {
            loading.value = false;
        }
        return billingInfo;
    };
    return {
        load,
        save,
        error: readonly(error),
        loading: readonly(loading),
    };
}
export * from './useBilling';
export default useBilling;
