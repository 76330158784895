import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
/**
 * Allows fetching a list of countries or a single country by ID
 *
 * See the {@link UseCountrySearchInterface} for a list of methods and values available in this composable.
 */
export function useCountrySearch() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({
        search: null,
        load: null,
    });
    const search = async (params) => {
        var _a, _b, _c;
        let results = null;
        try {
            loading.value = true;
            Logger.debug('[Magento]: Search country information based on', { params });
            const { data } = await app.$vsf.$magento.api.country(params.id, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, (_b = params === null || params === void 0 ? void 0 : params.customHeaders) !== null && _b !== void 0 ? _b : null);
            Logger.debug('[Result]:', { data });
            results = (_c = data === null || data === void 0 ? void 0 : data.country) !== null && _c !== void 0 ? _c : null;
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            Logger.error('useCountrySearch/search', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    const load = async () => {
        var _a;
        let results = [];
        try {
            loading.value = true;
            Logger.debug('[Magento]: Load available countries on store');
            const { data } = await app.$vsf.$magento.api.countries();
            Logger.debug('[Result]:', { data });
            results = (_a = data === null || data === void 0 ? void 0 : data.countries) !== null && _a !== void 0 ? _a : [];
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            Logger.error('useCountrySearch/load', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    return {
        load,
        search,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export * from './useCountrySearch';
export default useCountrySearch;
