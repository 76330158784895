import formatCurrency from '~/helpers/formatCurrency';
const plugin = (context, inject) => {
    inject('fc', (value, locale, options = {}) => {
        var _a, _b, _c, _d;
        // eslint-disable-next-line no-param-reassign
        locale = (locale || ((_b = (_a = context.i18n) === null || _a === void 0 ? void 0 : _a.localeProperties) === null || _b === void 0 ? void 0 : _b.iso) || '').replace('_', '-');
        // eslint-disable-next-line no-param-reassign
        options = { currency: context.app.$vsf.$magento.config.state.getCurrency() || ((_d = (_c = context.i18n) === null || _c === void 0 ? void 0 : _c.localeProperties) === null || _d === void 0 ? void 0 : _d.defaultCurrency), ...options };
        return formatCurrency(value, locale, options);
    });
};
export default plugin;
