import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnButton',
    props: {
        classes: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        type: {
            type: String,
            required: false,
            default: 'primary',
        },
    },
    emits: ['click'],
});
