import { ZnRating } from '~/modules/b2b/components';
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useReviews } from '~/modules/b2b/composables';
export default defineComponent({
    name: 'ReviewCard',
    components: {
        ZnRating,
    },
    props: {
        review: {
            type: Object,
            required: true,
        },
        sku: {
            type: String,
            required: true,
            default: undefined,
        },
    },
    setup(props) {
        const { max } = useReviews(props.sku);
        const dateFormatted = computed(() => {
            const date = new Date(props.review.date);
            const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return `${monthAbbreviations[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
        });
        return {
            max,
            dateFormatted,
        };
    },
});
