/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import { reactive, ref, computed, useContext, } from '@nuxtjs/composition-api';
import braintree from 'braintree-web';
import { useCart, useMagentoConfiguration, useConfig } from '~/composables';
import { useCheckout } from '~/modules/b2b/checkout/composables';
import { PAYMENT_GATEWAYS_MAP } from '~/modules/b2b/constants.client';
import { stores } from '~/modules/b2b/localization';
const state = reactive({
    client: null,
    clientToken: null,
    hostedFieldsInstance: null,
    hostedFieldsErrors: {
        number: '',
        expirationDate: '',
        cvv: '',
    },
    paypalCheckoutInstance: null,
    googlePaymentInstance: null,
    googlePaymentClient: null,
    applePayInstance: null,
    nonce: null,
    paymentData: {},
    supportedCardTypes: [],
});
const tmpAddress = 'Tmp Address';
export const useBraintree = ({ emit }) => {
    const loading = ref(false);
    const error = ref(null);
    const nonce = computed(() => state.nonce);
    const paymentData = computed(() => state.paymentData);
    const clientToken = computed(() => state.clientToken);
    const hostedFieldsErrors = computed(() => state.hostedFieldsErrors);
    const { selectedStore, isRegion } = useMagentoConfiguration();
    const hostedSupportedCardTypes = computed(() => state.supportedCardTypes);
    const { shippingAddress, contactInfo, formatAddressForShipping, formatAddressForBilling, updateShippingAddress, updateShippingOptions, saveCurrentShippingMethod, clearShippingAndBillingAddress, clearShippingOptions, shippingOptions, selectedShippingMethod, orderTotalsSummary, getNewEstimationArrival, saveConfigToMagento, updateContactInfo, updateBillingAddress, } = useCheckout();
    const { cart } = useCart();
    const { config } = useConfig();
    const selectedCurrency = computed(() => { var _a; return (_a = config.value) === null || _a === void 0 ? void 0 : _a.default_display_currency_code; });
    const { $config } = useContext();
    const { googlePayEnvironment, googlePayMerchantId } = $config;
    const getClient = async () => {
        if (!state.clientToken) {
            const tokenFetch = await fetch('/get-braintree-token');
            const { clientToken: clientTokenReq } = await tokenFetch.json();
            state.clientToken = clientTokenReq;
        }
        if (!state.client) {
            try {
                state.client = await braintree.client.create({
                    authorization: state.clientToken,
                });
            }
            catch (e) {
                error.value = e;
            }
        }
        return state.client;
    };
    const setHostedSupportedCardTypes = (cardTypes) => {
        state.supportedCardTypes = cardTypes;
    };
    const stateFields = computed(() => state.hostedFieldsInstance.getState().fields);
    const checkHostedFieldError = (field) => {
        if (field === 'number' && state.hostedFieldsErrors.number === 'unsupported') {
            return;
        }
        if (stateFields.value[field].isEmpty) {
            state.hostedFieldsErrors[field] = 'required';
        }
        else if (stateFields.value[field].isValid) {
            state.hostedFieldsErrors[field] = '';
        }
        else {
            document.querySelector('#card-image').className = 'hidden';
            state.hostedFieldsErrors[field] = 'invalid';
        }
    };
    const resetHostedFieldErrors = () => {
        Object.keys(stateFields.value).forEach((key) => checkHostedFieldError(key));
    };
    const initHostedFieldErrors = () => {
        state.hostedFieldsErrors = {
            number: '',
            expirationDate: '',
            cvv: '',
        };
    };
    const renderHostedFields = async (options) => {
        loading.value = true;
        const { fields, styles } = options;
        try {
            initHostedFieldErrors();
            const client = await getClient();
            state.hostedFieldsInstance = await braintree.hostedFields.create({
                client,
                fields,
                styles,
            });
            state.hostedFieldsInstance.on('validityChange', (event) => {
                checkHostedFieldError(event.emittedBy);
                // number completed and expiration date is empty
                if (event.emittedBy === 'number' && event.fields.number.isValid && !event.fields.expirationDate.isValid) {
                    state.hostedFieldsInstance.focus('expirationDate');
                }
                // number completed and expiration date completed and cvv is empty
                if (event.emittedBy === 'number' && event.fields.number.isValid && event.fields.expirationDate.isValid && !event.fields.cvv.isValid) {
                    state.hostedFieldsInstance.focus('cvv');
                }
                // expiration date completed and cvv is empty
                if (event.emittedBy === 'expirationDate' && event.fields.number.isValid && event.fields.expirationDate.isValid && !event.fields.cvv.isValid) {
                    state.hostedFieldsInstance.focus('cvv');
                }
            });
            state.hostedFieldsInstance.on('cardTypeChange', (event) => {
                if (event.cards.length === 1) {
                    state.hostedFieldsErrors.number = !hostedSupportedCardTypes.value.includes(event.cards[0].type) ? 'unsupported' : '';
                    document.querySelector('#card-image').className = `${event.cards[0].type} absolute mb-4`;
                }
            });
            state.hostedFieldsInstance.on('empty', (event) => {
                document.querySelector('#card-image').className = 'hidden';
                checkHostedFieldError(event.emittedBy);
            });
            state.hostedFieldsInstance.on('blur', (event) => {
                checkHostedFieldError(event.emittedBy);
            });
        }
        catch (e) {
            error.value = e;
        }
        finally {
            loading.value = false;
        }
    };
    const isHostedValid = computed(() => {
        if (!state.hostedFieldsInstance) {
            return false;
        }
        try {
            const eachValid = Object.keys(stateFields.value).every((key) => stateFields.value[key].isValid && !stateFields.value[key].isEmpty);
            return eachValid && state.hostedFieldsErrors.number !== 'unsupported';
        }
        catch (e) {
            error.value = e;
        }
        return false;
    });
    const tokenizeHostedFields = async () => {
        // console.log(state.hostedFieldsInstance);
        if (!state.hostedFieldsInstance) {
            return;
        }
        try {
            // loading.value = true;
            const tokenize = await state.hostedFieldsInstance.tokenize();
            state.nonce = tokenize.nonce;
            state.paymentData = tokenize.details;
            emit('tokenize', tokenize.nonce);
        }
        catch (e) {
            error.value = e;
        }
        finally {
            // loading.value = false;
        }
    };
    const createPaypalCheckoutInstance = async () => {
        if (!state.paypalCheckoutInstance) {
            const paypalCheckoutInstance = await braintree.paypalCheckout.create({
                client: await getClient(),
            });
            state.paypalCheckoutInstance = await paypalCheckoutInstance.loadPayPalSDK({
                intent: 'authorize',
                // currency code will be retieved from magento?
                currency: selectedCurrency.value || 'USD',
                vault: true,
                'disable-funding': 'credit,card', // this is avoid showing the funding options and only show the paypal button
            });
        }
    };
    const createPaypalPayment = async ({ amount, cb, elRef, onlyPayment = false, }) => {
        if (!state.paypalCheckoutInstance) {
            await createPaypalCheckoutInstance();
        }
        if (!window.paypal) {
            throw new Error('Paypal SDK not loaded');
        }
        try {
            return window.paypal
                .Buttons({
                style: {
                    // @ts-ignore
                    color: 'gold',
                    tagline: false,
                    height: 40,
                    tagline: false,
                },
                createOrder: () => {
                    const order = {
                        flow: 'checkout',
                        amount,
                        // currency code will be retieved from magento?
                        currency: selectedCurrency.value || 'USD',
                        intent: 'authorize',
                    };
                    if (!onlyPayment) {
                        order.requestBillingAgreement = true; // This is for using Braintree Vault
                        order.enableShippingAddress = true;
                        order.shippingAddressEditable = true;
                    }
                    return state.paypalCheckoutInstance.createPayment(order);
                },
                onApprove: async (data) => {
                    const tokenize = await state.paypalCheckoutInstance.tokenizePayment(data);
                    state.paymentData = tokenize.details;
                    state.nonce = tokenize.nonce;
                    emit('tokenize', {
                        gateway: PAYMENT_GATEWAYS_MAP.paypal,
                        nativeResponse: data,
                        braintreeResponse: tokenize,
                    });
                    if (cb)
                        cb();
                    return tokenize;
                },
                onInit: (data) => { },
                onCancel: (data) => {
                    if (!onlyPayment) {
                        clearShippingAndBillingAddress();
                        emit('cancel');
                    }
                },
                onError: (err) => {
                    console.log('onError');
                    console.error(err);
                    throw new Error(err);
                },
                onShippingChange: async (data, actions) => {
                    var _a, _b, _c, _d, _e, _f;
                    if ((!isRegion.value && data.shipping_address.country_code.toUpperCase() !== selectedStore.value.toUpperCase())
                        || (isRegion.value && !stores[selectedStore.value].allowedCountries.includes(((_b = (_a = data === null || data === void 0 ? void 0 : data.shipping_address) === null || _a === void 0 ? void 0 : _a.country_code) === null || _b === void 0 ? void 0 : _b.toUpperCase()) || ''))) {
                        // We only support same country as store
                        return actions.reject();
                    }
                    try {
                        if (!selectedShippingMethod.value
                            || !cart.value.shipping_addresses || cart.value.shipping_addresses.length === 0
                            || cart.value.shipping_addresses[0].postcode !== data.shipping_address.postal_code) {
                            // Cart has no shipping address, we need to create one
                            updateShippingAddress({
                                country: data.shipping_address.country_code,
                                city: data.shipping_address.city,
                                zipCode: data.shipping_address.postal_code,
                                state: ((_c = data.shipping_address.state) === null || _c === void 0 ? void 0 : _c.length) > 0 ? data.shipping_address.state : data.shipping_address.city,
                                firstName: 'John',
                                lastName: 'Doe',
                                address: tmpAddress,
                            });
                            await saveConfigToMagento();
                        }
                        // If shipping method has changed, we need to update it
                        if ((!((_d = cart.value.shipping_addresses[0]) === null || _d === void 0 ? void 0 : _d.selected_shipping_method)
                            || (data.selected_shipping_option
                                && data.selected_shipping_option.id
                                && ((_f = (_e = cart.value.shipping_addresses[0]) === null || _e === void 0 ? void 0 : _e.selected_shipping_method) === null || _f === void 0 ? void 0 : _f.carrier_code) !== data.selected_shipping_option.id)) && data.selected_shipping_option)
                            await updateShippingOptions(data.selected_shipping_option.id);
                        // Patch the shipping amount
                        // eslint-disable-next-line no-param-reassign
                        data.amount.breakdown.item_total.value = orderTotalsSummary.value.subTotal.toFixed(2);
                        // eslint-disable-next-line no-param-reassign
                        data.amount.breakdown.shipping.value = selectedShippingMethod.value.amount.value.toFixed(2);
                        // eslint-disable-next-line no-param-reassign
                        data.amount.breakdown.tax_total.value = orderTotalsSummary.value.tax.toFixed(2);
                        // eslint-disable-next-line no-param-reassign
                        data.amount.value = orderTotalsSummary.value.total.toFixed(2);
                        return actions.order.patch([
                            {
                                op: 'replace',
                                path: '/purchase_units/@reference_id==\'default\'/amount',
                                value: data.amount,
                            },
                            {
                                op: data.selected_shipping_option ? 'replace' : 'add',
                                path: '/purchase_units/@reference_id==\'default\'/shipping/options',
                                value: shippingOptions.value.map((shippingMethod) => ({
                                    id: shippingMethod.carrier_code,
                                    type: 'SHIPPING',
                                    label: `${shippingMethod.method_title} (Estimated Arrival in ${getNewEstimationArrival({ shippingType: shippingMethod.carrier_title, store: selectedStore.value })} business days)`,
                                    selected: shippingMethod.carrier_code === selectedShippingMethod.value.carrier_code,
                                    amount: {
                                        value: shippingMethod.amount.value.toFixed(2),
                                        currency_code: shippingMethod.amount.currency,
                                    },
                                })),
                            },
                        ]);
                    }
                    catch (e) {
                        console.log(e);
                        return actions.reject();
                    }
                },
            })
                .render(elRef || '#render-pay');
        }
        catch (e) {
            error.value = e;
            console.log('CATCH');
            console.log(error.value);
        }
    };
    const paypalTeardown = async () => {
        if (state.paypalCheckoutInstance) {
            await state.paypalCheckoutInstance.teardown();
            state.paypalCheckoutInstance = null;
        }
    };
    async function onPaymentDataChanged(intermediatePaymentData) {
        var _a, _b, _c;
        const paymentDataRequestUpdate = {
            error: null,
            newShippingOptionParameters: null,
            newTransactionInfo: null,
        };
        if ((!isRegion.value && intermediatePaymentData.shippingAddress.countryCode.toUpperCase() !== selectedStore.value.toUpperCase())
            || (isRegion.value && !stores[selectedStore.value].allowedCountries.includes(((_b = (_a = intermediatePaymentData === null || intermediatePaymentData === void 0 ? void 0 : intermediatePaymentData.shippingAddress) === null || _a === void 0 ? void 0 : _a.countryCode) === null || _b === void 0 ? void 0 : _b.toUpperCase()) || ''))) {
            paymentDataRequestUpdate.error = {
                reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
                message: 'Cannot ship to the selected address',
                intent: 'SHIPPING_ADDRESS',
            };
            return new Promise((resolve, reject) => {
                resolve(paymentDataRequestUpdate);
            });
        }
        // first time render or shipping address changed
        if (intermediatePaymentData.callbackTrigger === 'INITIALIZE' || intermediatePaymentData.callbackTrigger === 'SHIPPING_ADDRESS') {
            updateShippingAddress({
                country: intermediatePaymentData.shippingAddress.countryCode,
                city: intermediatePaymentData.shippingAddress.locality,
                zipCode: intermediatePaymentData.shippingAddress.postalCode,
                state: ((_c = intermediatePaymentData.shippingAddress.administrativeArea) === null || _c === void 0 ? void 0 : _c.length) > 0
                    ? intermediatePaymentData.shippingAddress.administrativeArea
                    : intermediatePaymentData.shippingAddress.locality,
                firstName: 'John',
                lastName: 'Doe',
                address: tmpAddress,
            });
            await saveConfigToMagento();
        }
        // shipping option changed
        if (intermediatePaymentData.callbackTrigger === 'SHIPPING_OPTION') {
            await updateShippingOptions(intermediatePaymentData.shippingOptionData.id);
        }
        // update shipping options
        const availableShippingOptions = shippingOptions.value.map((shippingMethod) => ({
            id: shippingMethod.carrier_code,
            label: shippingMethod.method_title,
            description: `${shippingMethod.method_title} - $${shippingMethod.amount.value.toFixed(2)} (Estimated Arrival in ${getNewEstimationArrival({ shippingType: shippingMethod.carrier_title, store: selectedStore.value })} business days)`,
        }));
        if (!availableShippingOptions || availableShippingOptions.length === 0) {
            paymentDataRequestUpdate.error = {
                reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
                message: 'Cannot ship to the selected address',
                intent: 'SHIPPING_ADDRESS',
            };
        }
        paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: selectedShippingMethod.value.carrier_code,
            shippingOptions: availableShippingOptions,
        };
        // update transaction info
        paymentDataRequestUpdate.newTransactionInfo = {
            totalPrice: orderTotalsSummary.value.total.toFixed(2),
            totalPriceStatus: 'FINAL',
            currencyCode: selectedCurrency.value || 'USD',
            totalPriceLabel: 'Total',
            displayItems: Object.keys(orderTotalsSummary.value).map((key) => {
                switch (key) {
                    case 'subTotal':
                        return {
                            label: 'Subtotal',
                            type: 'SUBTOTAL',
                            price: orderTotalsSummary.value[key].toFixed(2),
                        };
                    case 'shippingPrice':
                        return {
                            label: 'Shipping',
                            type: 'SHIPPING_OPTION',
                            price: orderTotalsSummary.value[key].toFixed(2),
                            status: 'FINAL',
                        };
                    case 'tax':
                        return {
                            label: 'Estimated Tax',
                            type: 'TAX',
                            price: orderTotalsSummary.value[key].toFixed(2),
                        };
                    default:
                        return null;
                }
            }).filter(Boolean),
        };
        return new Promise((resolve, reject) => {
            resolve(paymentDataRequestUpdate);
        });
    }
    const createGooglePaymentInstance = async ({ onlyPayment = false }) => {
        if (!state.googlePaymentInstance) {
            state.googlePaymentInstance = await braintree.googlePayment.create({
                client: await getClient(),
                googlePayVersion: 2,
            });
        }
        if (!state.googlePaymentClient) {
            const clientConfig = {
                environment: googlePayEnvironment,
                paymentDataCallbacks: !onlyPayment ? { onPaymentDataChanged } : null,
            };
            state.googlePaymentClient = new window.google.payments.api.PaymentsClient(clientConfig);
        }
    };
    const createGooglePayment = async ({ amount, cb, elRef, onlyPayment = false, }) => {
        if (!state.googlePaymentInstance && !state.googlePaymentClient) {
            await createGooglePaymentInstance({ onlyPayment });
        }
        try {
            let paymentDataRequest = await state.googlePaymentInstance.createPaymentDataRequest({
                emailRequired: !onlyPayment,
                transactionInfo: {
                    totalPriceStatus: 'ESTIMATED',
                    totalPrice: typeof amount === 'string' ? amount : amount.toString(),
                    currencyCode: selectedCurrency.value || 'USD', // This should be dynamic from localizations
                },
            });
            if (googlePayEnvironment === 'PRODUCTION') {
                paymentDataRequest = {
                    ...paymentDataRequest,
                    merchantInfo: {
                        merchantId: googlePayMerchantId,
                        merchantName: 'ZenniOptical',
                    },
                };
            }
            if (!onlyPayment) {
                paymentDataRequest = {
                    ...paymentDataRequest,
                    shippingAddressRequired: true,
                    shippingOptionRequired: true,
                    shippingAddressParameters: {
                        phoneNumberRequired: true,
                        allowedCountryCodes: [],
                    },
                    transactionInfo: {
                        ...paymentDataRequest.transactionInfo,
                        totalPriceLabel: 'Total',
                        displayItems: [
                            {
                                label: 'Subtotal',
                                type: 'SUBTOTAL',
                                price: orderTotalsSummary.value.subTotal.toFixed(2),
                            },
                            {
                                label: 'Shipping',
                                type: 'SHIPPING_OPTION',
                                price: orderTotalsSummary.value.shippingPrice.toFixed(2) || '0.00',
                            },
                            {
                                label: 'Estimated Tax',
                                type: 'TAX',
                                price: orderTotalsSummary.value.tax.toFixed(2) || '0.00',
                            },
                        ],
                    },
                    callbackIntents: ['SHIPPING_ADDRESS', 'SHIPPING_OPTION'],
                };
            }
            const allowedCardMethod = paymentDataRequest.allowedPaymentMethods.findIndex((method) => method.type === 'CARD');
            paymentDataRequest.allowedPaymentMethods[allowedCardMethod].parameters = {
                ...paymentDataRequest.allowedPaymentMethods[allowedCardMethod].parameters,
                assuranceDetailsRequired: true,
                billingAddressRequired: true,
                billingAddressParameters: {
                    format: 'FULL',
                    phoneNumberRequired: true,
                },
            };
            const isReadyToPay = await state.googlePaymentClient.isReadyToPay({
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: paymentDataRequest.allowedPaymentMethods,
            });
            if (!isReadyToPay.result) {
                throw new Error('Google Pay is not ready');
            }
            const button = state.googlePaymentClient.createButton({
                buttonColor: 'black',
                buttonType: 'short',
                onClick: () => {
                    state.googlePaymentClient
                        .loadPaymentData(paymentDataRequest)
                        .then(async (paymentDataLoaded) => {
                        const response = await state.googlePaymentInstance.parseResponse(paymentDataLoaded);
                        state.paymentData = response.details;
                        state.nonce = response.nonce;
                        emit('tokenize', {
                            gateway: PAYMENT_GATEWAYS_MAP.gpay,
                            nativeResponse: paymentDataLoaded,
                            braintreeResponse: response,
                        });
                        // eslint-disable-next-line promise/no-callback-in-promise
                        if (cb)
                            cb();
                        return true;
                    })
                        .catch((err) => {
                        if (!onlyPayment) {
                            clearShippingAndBillingAddress();
                            emit('cancel');
                        }
                        console.log('err', err);
                        error.value = err;
                    });
                },
            });
            document.querySelector(elRef || '#render-pay').append(button);
        }
        catch (e) {
            if (!onlyPayment) {
                clearShippingAndBillingAddress();
                emit('cancel');
            }
            error.value = e;
        }
    };
    const processExpressCheckoutFromGoogle = async (googlePaymentData) => {
        var _a, _b;
        const { shippingAddress: gpayShippingAddress, email: gpayEmail } = googlePaymentData;
        const { billingAddress: gpayBillingAddress } = googlePaymentData.paymentMethodData.info;
        const { shippingOptionData: gpayShippingOptionData } = googlePaymentData;
        if (gpayShippingAddress.address1 === tmpAddress) {
            clearShippingAndBillingAddress();
            emit('cancel');
            throw new Error('Invalid address');
        }
        updateContactInfo({
            email: gpayEmail,
            phoneNumber: gpayShippingAddress.phoneNumber,
        });
        updateShippingAddress({
            city: gpayShippingAddress.locality,
            country: gpayShippingAddress.countryCode,
            firstName: gpayShippingAddress.name.split(' ')[0],
            lastName: gpayShippingAddress.name.split(' ')[1],
            zipCode: gpayShippingAddress.postalCode,
            state: ((_a = gpayShippingAddress.administrativeArea) === null || _a === void 0 ? void 0 : _a.length) > 0 ? gpayShippingAddress.administrativeArea : gpayShippingAddress.locality,
            address: gpayShippingAddress.address1,
            address2: gpayShippingAddress.address2,
            phoneNumber: gpayShippingAddress.phoneNumber,
            isBillingAddressSame: false,
        });
        updateBillingAddress({
            city: gpayBillingAddress.locality,
            country: gpayBillingAddress.countryCode,
            firstName: gpayBillingAddress.name.split(' ')[0],
            lastName: gpayBillingAddress.name.split(' ')[1],
            zipCode: gpayBillingAddress.postalCode,
            state: ((_b = gpayBillingAddress.administrativeArea) === null || _b === void 0 ? void 0 : _b.length) > 0 ? gpayBillingAddress.administrativeArea : gpayBillingAddress.locality,
            address: gpayBillingAddress.address1,
            address2: gpayBillingAddress.address2,
            phoneNumber: gpayBillingAddress.phoneNumber,
            isBillingAddressSame: false,
        });
        await updateShippingOptions(gpayShippingOptionData.id, true);
    };
    const formatPaymentDataFromGoogle = async (googlePaymentData) => {
        const { shippingAddress: gpayShippingAddress, email: gpayEmail } = googlePaymentData;
        const { billingAddress: gpayBillingAddress } = googlePaymentData.paymentMethodData.info;
        const { shippingOptionData: gpayShippingOptionData } = googlePaymentData;
        const shippingDetails = await formatAddressForShipping({
            city: gpayShippingAddress.locality,
            country: gpayShippingAddress.countryCode,
            firstName: gpayShippingAddress.name.split(' ')[0],
            lastName: gpayShippingAddress.name.split(' ')[1],
            zipCode: gpayShippingAddress.postalCode,
            state: gpayShippingAddress.administrativeArea,
            address: gpayShippingAddress.address1,
            phoneNumber: gpayShippingAddress.phoneNumber,
        });
        const billingDetails = await formatAddressForBilling({
            city: gpayBillingAddress.locality,
            country: gpayBillingAddress.countryCode,
            firstName: gpayBillingAddress.name.split(' ')[0],
            lastName: gpayBillingAddress.name.split(' ')[1],
            zipCode: gpayBillingAddress.postalCode,
            state: gpayBillingAddress.administrativeArea,
            address: gpayBillingAddress.address1,
            phoneNumber: gpayBillingAddress.phoneNumber,
            isBillingAddressSame: false,
        });
        const shippingMethod = shippingOptions.value.find((method) => method.carrier_code === gpayShippingOptionData.id);
        return {
            shippingDetails,
            billingDetails,
            email: gpayEmail,
            shippingMethod: {
                shippingMethod: {
                    carrier_code: shippingMethod.carrier_code,
                    method_code: shippingMethod.method_code,
                },
            },
        };
    };
    const processExpressCheckoutFromPaypal = async (braintreeTokenizePayload) => {
        var _a;
        const { shippingAddress: paypalShippingAddress, email: paypalEmail, billingAddress: paypalBillingAddress, phone, } = braintreeTokenizePayload.details;
        const { shippingOptionId: paypalShippingOptionId } = braintreeTokenizePayload;
        if (paypalShippingAddress.line1 === tmpAddress) {
            clearShippingAndBillingAddress();
            emit('cancel');
            throw new Error('Invalid address');
        }
        updateContactInfo({
            email: paypalEmail,
            phoneNumber: phone || '',
        });
        updateShippingAddress({
            city: paypalShippingAddress.city,
            country: paypalShippingAddress.countryCode,
            firstName: paypalShippingAddress.recipientName.split(' ')[0],
            lastName: paypalShippingAddress.recipientName.split(' ')[1],
            zipCode: paypalShippingAddress.postalCode,
            state: ((_a = paypalShippingAddress.state) === null || _a === void 0 ? void 0 : _a.length) > 0 ? paypalShippingAddress.state : paypalShippingAddress.city,
            address: paypalShippingAddress.line1,
            address2: paypalShippingAddress.line2,
            isBillingAddressSame: true,
        });
        await updateShippingOptions(paypalShippingOptionId, true);
    };
    const formatPaymentDataFromPaypal = async (braintreeTokenizePayload) => {
        const { shippingAddress: paypalShippingAddress, email: paypalEmail, billingAddress: paypalBillingAddress } = braintreeTokenizePayload.details;
        const { shippingOptionId: paypalShippingOptionId } = braintreeTokenizePayload;
        const shippingDetails = await formatAddressForShipping({
            city: paypalShippingAddress.city,
            country: paypalShippingAddress.countryCode,
            firstName: paypalShippingAddress.recipientName.split(' ')[0],
            lastName: paypalShippingAddress.recipientName.split(' ')[1],
            zipCode: paypalShippingAddress.postalCode,
            state: paypalShippingAddress.state,
            address: paypalShippingAddress.line1,
            appartment: paypalShippingAddress.line2,
        });
        const billingDetails = await formatAddressForBilling({
            city: paypalShippingAddress.city,
            country: paypalShippingAddress.countryCode,
            firstName: paypalShippingAddress.recipientName.split(' ')[0],
            lastName: paypalShippingAddress.recipientName.split(' ')[1],
            zipCode: paypalShippingAddress.postalCode,
            state: paypalShippingAddress.state,
            address: paypalShippingAddress.line1,
            isBillingAddressSame: true,
        });
        const shippingMethod = shippingOptions.value.find((method) => method.carrier_code === paypalShippingOptionId);
        return {
            shippingDetails,
            billingDetails,
            email: paypalEmail,
            shippingMethod: {
                shippingMethod: {
                    carrier_code: shippingMethod.carrier_code,
                    method_code: shippingMethod.method_code,
                },
            },
        };
    };
    const processExpressCheckoutFromApple = async (applePaymentData) => {
        var _a, _b, _c, _d, _e;
        const { payment } = applePaymentData;
        const { shippingContact: appleShippingAddress, billingContact: appleBillingAddress } = payment;
        const { shippingOptionData: appleShippingOptionData } = applePaymentData;
        // Fall back to shipping address if billing address is not available
        const billingInformation = ((_a = appleBillingAddress === null || appleBillingAddress === void 0 ? void 0 : appleBillingAddress.addressLines) === null || _a === void 0 ? void 0 : _a[0]) ? appleBillingAddress : appleShippingAddress;
        // Validate that the address is different that tmp
        if (appleShippingAddress.addressLines[0] === tmpAddress) {
            clearShippingAndBillingAddress();
            emit('cancel');
            throw new Error('Invalid address');
        }
        updateContactInfo({
            email: appleShippingAddress.emailAddress,
            phoneNumber: appleShippingAddress.phoneNumber,
        });
        updateShippingAddress({
            city: appleShippingAddress.locality,
            country: appleShippingAddress.countryCode,
            firstName: appleShippingAddress.givenName,
            lastName: appleShippingAddress.familyName,
            zipCode: appleShippingAddress.postalCode,
            state: ((_b = appleShippingAddress.administrativeArea) === null || _b === void 0 ? void 0 : _b.length) > 0 ? appleShippingAddress.administrativeArea : appleShippingAddress.locality,
            address: appleShippingAddress.addressLines[0],
            address2: (_c = appleShippingAddress.addressLines) === null || _c === void 0 ? void 0 : _c[1],
            phoneNumber: appleShippingAddress.phoneNumber,
            isBillingAddressSame: false,
        });
        updateBillingAddress({
            city: billingInformation.locality,
            country: billingInformation.countryCode,
            firstName: billingInformation.givenName,
            lastName: billingInformation.familyName,
            zipCode: billingInformation.postalCode,
            state: ((_d = billingInformation.administrativeArea) === null || _d === void 0 ? void 0 : _d.length) > 0 ? billingInformation.administrativeArea : billingInformation.locality,
            address: billingInformation.addressLines[0],
            address2: (_e = billingInformation.addressLines) === null || _e === void 0 ? void 0 : _e[1],
            phoneNumber: billingInformation.phoneNumber,
            isBillingAddressSame: false,
        });
        await updateShippingOptions(appleShippingOptionData, true);
    };
    const formatPaymentDataFromApple = async (applePaymentData) => {
        const { payment } = applePaymentData;
        const { shippingContact: appleShippingAddress, billingContact: appleBillingAddress } = payment;
        const { shippingOptionData: appleShippingOptionData } = applePaymentData;
        const shippingDetails = await formatAddressForShipping({
            city: appleShippingAddress.locality,
            country: appleShippingAddress.countryCode,
            firstName: appleShippingAddress.givenName,
            lastName: appleShippingAddress.familyName,
            zipCode: appleShippingAddress.postalCode,
            state: appleShippingAddress.administrativeArea,
            address: appleShippingAddress.addressLines[0],
            phoneNumber: appleShippingAddress.phoneNumber,
        });
        const billingDetails = await formatAddressForBilling({
            city: appleBillingAddress.locality,
            country: appleBillingAddress.countryCode,
            firstName: appleBillingAddress.givenName,
            lastName: appleBillingAddress.familyName,
            zipCode: appleBillingAddress.postalCode,
            state: appleBillingAddress.administrativeArea,
            address: appleBillingAddress.addressLines[0],
            phoneNumber: appleBillingAddress.phoneNumber,
            isBillingAddressSame: false,
        });
        const shippingMethod = shippingOptions.value.find((method) => method.carrier_code === appleShippingOptionData);
        return {
            shippingDetails,
            billingDetails,
            email: appleShippingAddress.emailAddress,
            shippingMethod: {
                shippingMethod: {
                    carrier_code: shippingMethod.carrier_code,
                    method_code: shippingMethod.method_code,
                },
            },
        };
    };
    const googleTeardown = () => {
        state.googlePaymentInstance = null;
        state.googlePaymentClient = null;
    };
    const createApplePayInstance = async () => {
        state.applePayInstance = await braintree.applePay.create({ client: await getClient() });
    };
    const createApplePayment = async ({ amount, cb, elRef, onlyPayment, }) => {
        if (!state.applePayInstance) {
            await createApplePayInstance();
        }
        if (!window.ApplePaySession) {
            throw new Error('This device does not support Apple Pay');
        }
        // Check if the browser supports Apple Pay
        const canMakePayments = await window.ApplePaySession.canMakePayments();
        if (canMakePayments) {
            const renderDiv = document.querySelector(elRef || '#render-pay');
            renderDiv.innerHTML = '<div class="applepay-button"><div class="apple-pay-button-with-text apple-pay-button-black-with-text"><span class="text"></span><span class="logo"></span></div></div>';
            let initPaymentRequestConfig = {
                total: {
                    label: 'Total',
                    amount: !onlyPayment ? orderTotalsSummary.value.total.toString() : amount.toString(),
                },
                lineItems: [
                    {
                        label: 'Subtotal',
                        type: 'final',
                        amount: orderTotalsSummary.value.subTotal.toFixed(2),
                    },
                    {
                        label: 'Shipping',
                        type: 'final',
                        amount: orderTotalsSummary.value.shippingPrice.toFixed(2),
                    },
                    {
                        label: 'Estimated Tax',
                        type: 'final',
                        amount: orderTotalsSummary.value.tax.toFixed(2),
                    },
                ],
                currencyCode: selectedCurrency.value || 'USD',
                // We recommend collecting billing address information, at minimum
                // billing postal code, and passing that billing postal code with
                // all Apple Pay transactions as a best practice.
            };
            if (!onlyPayment) {
                initPaymentRequestConfig = {
                    ...initPaymentRequestConfig,
                    shippingType: 'delivery',
                    requiredShippingContactFields: [
                        'postalAddress',
                        'name',
                        'phone',
                        'email',
                    ],
                    requiredBillingContactFields: ['postalAddress', 'phone', 'email'],
                };
            }
            // if (onlyPayment) {
            //   initPaymentRequestConfig.shippingContactEditingMode = 'storePickup';
            //   initPaymentRequestConfig.shippingContact = {
            //     addressLines: [shippingAddress.value.address, shippingAddress.value.address2],
            //     locality: shippingAddress.value.city,
            //     administrativeArea: shippingAddress.value.state,
            //     postalCode: shippingAddress.value.zipCode,
            //     countryCode: shippingAddress.value.country,
            //     familyName: shippingAddress.value.lastName,
            //     givenName: shippingAddress.value.firstName,
            //   };
            //   initPaymentRequestConfig.billingContact = {
            //     email: contactInfo.value.email,
            //     phone: contactInfo.value.phoneNumber,
            //   };
            // }
            renderDiv.firstChild.addEventListener('click', () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const paymentRequest = state.applePayInstance.createPaymentRequest(initPaymentRequestConfig);
                const session = new window.ApplePaySession(3, paymentRequest);
                session.addEventListener('cancel', () => {
                    if (!onlyPayment) {
                        clearShippingAndBillingAddress();
                        emit('cancel');
                    }
                });
                /* temporary value for saving selected shipping method because payment authorize doesn't return selected shipping method */
                session.onshippingmethodselected = async (event) => {
                    const { shippingMethod } = event;
                    await updateShippingOptions(shippingMethod.identifier);
                    session.completeShippingMethodSelection({
                        newTotal: {
                            label: 'checkout',
                            amount: orderTotalsSummary.value.total.toString(),
                        },
                        newLineItems: [
                            {
                                label: 'Subtotal',
                                type: 'final',
                                amount: orderTotalsSummary.value.subTotal.toFixed(2),
                            },
                            {
                                label: 'Shipping',
                                type: 'final',
                                amount: orderTotalsSummary.value.shippingPrice.toFixed(2),
                            },
                            {
                                label: 'Estimated Tax',
                                type: 'final',
                                amount: orderTotalsSummary.value.tax.toFixed(2),
                            },
                        ],
                    });
                };
                session.onshippingcontactselected = async (event) => {
                    var _a, _b;
                    const { shippingContact } = event;
                    if (shippingContact) {
                        updateShippingAddress({
                            firstName: 'John',
                            lastName: 'Doe',
                            address: tmpAddress,
                            country: shippingContact.countryCode,
                            city: shippingContact.locality,
                            zipCode: shippingContact.postalCode,
                            state: ((_a = shippingContact.administrativeArea) === null || _a === void 0 ? void 0 : _a.length) > 0 ? shippingContact.administrativeArea : shippingContact.locality,
                        });
                        try {
                            await saveConfigToMagento();
                        }
                        catch (err) {
                            console.log(err);
                            console.log('Country mismatch');
                        }
                        const result = {
                            newTotal: {
                                label: 'checkout',
                                amount: orderTotalsSummary.value.total.toString(),
                            },
                            newLineItems: [
                                {
                                    label: 'Subtotal',
                                    type: 'final',
                                    amount: orderTotalsSummary.value.subTotal.toFixed(2),
                                },
                                {
                                    label: 'Shipping',
                                    type: 'final',
                                    amount: orderTotalsSummary.value.shippingPrice.toFixed(2),
                                },
                                {
                                    label: 'Estimated Tax',
                                    type: 'final',
                                    amount: orderTotalsSummary.value.tax.toFixed(2),
                                },
                            ],
                        };
                        if (!shippingOptions.value
                            || shippingOptions.value.length === 0
                            || ((!isRegion.value && shippingContact.countryCode !== selectedStore.value.toUpperCase())
                                || (isRegion.value && !stores[selectedStore.value].allowedCountries.includes(((_b = shippingContact === null || shippingContact === void 0 ? void 0 : shippingContact.countryCode) === null || _b === void 0 ? void 0 : _b.toUpperCase()) || '')))) {
                            result.errors = [
                                new ApplePayError('addressUnserviceable', 'postalAddress', 'Cannot ship to the selected address'),
                            ];
                            result.newTotal = { label: 'error', amount: '1', type: 'pending' };
                            result.newShippingMethods = [];
                            clearShippingOptions();
                        }
                        else {
                            result.newShippingMethods = shippingOptions.value.map((shippingMethod) => ({
                                identifier: shippingMethod.carrier_code,
                                label: shippingMethod.method_title,
                                detail: `${shippingMethod.method_title} - $${shippingMethod.amount.value.toFixed(2)} (Estimated Arrival in ${getNewEstimationArrival({ shippingType: shippingMethod.carrier_title, store: selectedStore.value })} business days)`,
                                amount: shippingMethod.amount.value.toFixed(2),
                            }));
                        }
                        session.completeShippingContactSelection(result);
                    }
                };
                // Validate the merchant on session start
                session.onvalidatemerchant = (event) => {
                    state.applePayInstance.performValidation({
                        validationURL: event.validationURL,
                        displayName: 'Your Merchant Name',
                    }).then((validationData) => session.completeMerchantValidation(validationData)).catch((err) => {
                        console.log('err', err);
                    });
                };
                // Tokenize the payment on session completion
                session.onpaymentauthorized = (event) => {
                    state.applePayInstance.tokenize({
                        token: event.payment.token,
                    }).then((payload) => {
                        // Send the nonce to your server
                        session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                        state.paymentData = payload.details;
                        state.nonce = payload.nonce;
                        emit('tokenize', {
                            gateway: PAYMENT_GATEWAYS_MAP.applepay,
                            nativeResponse: { payment: event.payment, shippingOptionData: selectedShippingMethod.value.carrier_code },
                            braintreeResponse: payload,
                        });
                        if (cb)
                            cb();
                        return true;
                    }).catch((err) => {
                        console.log('err', err);
                    }).catch((error_) => {
                        console.error('Error tokenizing Apple Pay:', error_);
                        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                        return false;
                    });
                };
                session.begin();
            });
        }
    };
    const appleTeardown = () => {
        state.applePayInstance = null;
    };
    return {
        loading,
        nonce,
        error,
        isHostedValid,
        paymentData,
        clientToken,
        hostedFieldsErrors,
        hostedSupportedCardTypes,
        setHostedSupportedCardTypes,
        resetHostedFieldErrors,
        getClient,
        createPaypalCheckoutInstance,
        createGooglePaymentInstance,
        createApplePayInstance,
        renderHostedFields,
        tokenizeHostedFields,
        createPaypalPayment,
        paypalTeardown,
        createGooglePayment,
        processExpressCheckoutFromGoogle,
        formatPaymentDataFromGoogle,
        processExpressCheckoutFromPaypal,
        formatPaymentDataFromPaypal,
        processExpressCheckoutFromApple,
        formatPaymentDataFromApple,
        googleTeardown,
        createApplePayment,
        appleTeardown,
    };
};
