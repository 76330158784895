import { ref, watch } from '@nuxtjs/composition-api';
export const useCamera = ({ cameraElement, emit }) => {
    const deviceId = ref(null);
    const devices = ref([]);
    const onCameras = (cameras) => {
        devices.value = cameras;
    };
    const onCameraChange = (cameraId) => {
        deviceId.value = cameraId;
    };
    watch(devices, (val) => {
        if (val.length > 0) {
            deviceId.value = val[0].deviceId;
        }
    });
    const startCamera = () => {
        if (devices.value.length > 0) {
            deviceId.value = devices.value[0].deviceId;
        }
    };
    const stopCamera = () => {
        cameraElement.value.stop();
        deviceId.value = null;
    };
    const onCapture = () => {
        if (!cameraElement || !cameraElement.value)
            return;
        try {
            const image = cameraElement.value.capture();
            if (image) {
                emit('modal:data', image);
            }
        }
        catch (error) {
            emit('modal:error', error);
        }
    };
    return {
        deviceId,
        startCamera,
        stopCamera,
        onCameras,
        onCapture,
        onCameraChange,
    };
};
