import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { ZnModal } from '~/modules/b2b/components';
import { useCamera } from '~/modules/b2b/prescription';
export default defineComponent({
    name: 'OcrModal',
    components: {
        ZnModal,
    },
    setup(_, { emit }) {
        const loading = ref(true);
        const showCamera = ref(false);
        const cameraElement = ref(null);
        const modal = ref(null);
        const error = ref(null);
        const { deviceId, onCameras, onCameraChange, onCapture, startCamera, stopCamera, } = useCamera({ cameraElement, emit });
        const open = () => {
            loading.value = true;
            error.value = false;
            showCamera.value = true;
            startCamera();
            modal.value.open();
        };
        const stopAndClose = () => {
            if (!error.value) {
                stopCamera();
            }
            modal.value.close();
        };
        const captureAndCloseOrRetry = () => {
            if (error.value) {
                open();
            }
            else {
                onCapture();
                stopAndClose();
            }
        };
        const logError = () => {
            error.value = true;
            loading.value = false;
        };
        const errorClasses = computed(() => ({
            'max-w-[514px]': error.value,
        }));
        return {
            modal,
            deviceId,
            showCamera,
            loading,
            error,
            errorClasses,
            cameraElement,
            onCameras,
            logError,
            onCapture,
            onCameraChange,
            stopAndClose,
            captureAndCloseOrRetry,
            open,
        };
    },
});
