export const rangeFilters = ['price'];
export function createProductAttributeFilterInput(params) {
    var _a, _b;
    const attributeFilter = {};
    const inputFilters = (_a = params === null || params === void 0 ? void 0 : params.filters) !== null && _a !== void 0 ? _a : {};
    const categoryFilter = {
        category_uid: { in: [params.category_uid, ...(_b = inputFilters.category_uid) !== null && _b !== void 0 ? _b : []] },
    };
    Object.keys(inputFilters).forEach((key) => {
        if (rangeFilters.includes(key)) {
            const range = { from: 0, to: 0 };
            const flatValue = inputFilters[key]
                .flatMap((inputFilter) => inputFilter.split('_'))
                .map((str) => Number.parseFloat(str))
                .sort((a, b) => a - b);
            [range.from] = flatValue;
            range.to = flatValue[flatValue.length - 1];
            attributeFilter[key] = range;
        }
        else if (typeof inputFilters[key] === 'string') {
            attributeFilter[key] = { eq: inputFilters[key] };
        }
        else if (inputFilters[key].length === 1) {
            attributeFilter[key] = { eq: inputFilters[key][0] };
        }
        else {
            attributeFilter[key] = { in: inputFilters[key] };
        }
    });
    return { ...attributeFilter, ...categoryFilter };
}
