import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnLoading',
    props: {
        title: {
            type: String,
            default: 'Calculating your<br>prescription information',
        },
        fullHeight: {
            type: Boolean,
            default: true,
        },
    },
});
