import { defineComponent, ref, watch, useFetch, computed, useContext, } from '@nuxtjs/composition-api';
import { ZnCard, ZnLoading } from '~/modules/b2b/components';
import { Divider } from '~/modules/b2b/components/beast';
import { usePdpFlow } from '~/modules/b2b/prescription/composables';
import { useContentful } from '~/composables';
import { AddPrescriptionFormDefaults } from '~/composables/useContentful/defaults';
import { useGuestUser } from '~/modules/b2b/customer/composables/useGuestUser';
import { VOUCHER_FLOW_PARTNER_ID, VR_FLOW_PARTNER_ID, PARTNER_ID_MAPS, } from '~/modules/b2b/constants.client';
import { TakePictureCard, UploadImageCard } from './components';
export default defineComponent({
    name: 'AddPrescriptionForm',
    components: {
        ZnCard,
        ZnLoading,
        Divider,
        TakePictureCard,
        UploadImageCard,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            required: false,
            default: 'add-prescription',
        },
        contentfulId: {
            type: String,
            required: false,
            default: 'add-prescription-form',
        },
        isVoucher: {
            type: Boolean,
            required: false,
            default: false,
        },
        product: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const cards = ref([]);
        const { search, content } = useContentful('addPrescriptionForm');
        const { flowData } = usePdpFlow();
        const { $gtm } = useContext();
        const { userId } = useGuestUser();
        const syncFlowData = (data) => {
            const state = data.find((d) => d.id === props.id);
            cards.value = cards.value.map((c) => {
                var _a;
                return ({
                    ...c,
                    selected: c.id === ((_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.select),
                });
            });
        };
        watch(flowData, (data) => {
            if (data) {
                syncFlowData(data);
            }
        }, { deep: true });
        const addPrescriptionFormTitle = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || AddPrescriptionFormDefaults.title; });
        const handleClick = (card) => {
            var _a;
            cards.value = cards.value.map((c) => ({
                ...c,
                selected: c.id === card.id,
            }));
            const partnerId = PARTNER_ID_MAPS[(_a = props.product) === null || _a === void 0 ? void 0 : _a.sku];
            $gtm.push({
                event: card.id,
                userId,
                partner_id: props.isVoucher
                    ? (partnerId === null || partnerId === void 0 ? void 0 : partnerId.voucher) || VOUCHER_FLOW_PARTNER_ID
                    : (partnerId === null || partnerId === void 0 ? void 0 : partnerId.default) || VR_FLOW_PARTNER_ID,
            });
            emit('group:select', { select: card.id, id: props.id, nextEvent: card.nextEvent });
        };
        useFetch(async () => {
            var _a, _b, _c, _d, _e, _f, _g;
            emit('group:loading', { id: props.id, loading: true });
            await search({
                custom: {
                    type: 'pdpCardGroup',
                    field: 'name',
                    value: props.contentfulId,
                },
            });
            const data = content.value && ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.options) ? (_d = (_c = content.value[0]) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.options : AddPrescriptionFormDefaults.options;
            const mobileOrder = content.value && ((_g = (_f = (_e = content.value[0]) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.pdpGroupMobileOrder) === null || _g === void 0 ? void 0 : _g.map(Number));
            const cardsTmp = data.map((item, index) => ({
                id: item.fields.id,
                title: item.fields.title,
                description: item.fields.description,
                component: item.fields.component,
                selected: false,
                disabled: false,
                nextEvent: item.fields.nextEvent,
                order: Number(index) + 1,
                mobileOrder: undefined,
                tags: item.fields.tags
                    ? item.fields.tags.map((tag) => ({ label: tag.fields.label, color: tag.fields.color, textColor: tag.fields.textColor }))
                    : [],
            }));
            if (mobileOrder && mobileOrder.length === cardsTmp.length) {
                mobileOrder.forEach((order, index) => {
                    cardsTmp[order - 1].mobileOrder = index + 1;
                });
            }
            cards.value = cardsTmp;
            if (flowData.value) {
                syncFlowData(flowData.value);
            }
            emit('group:loading', { id: props.id, loading: false });
        });
        return {
            cards,
            loading,
            addPrescriptionFormTitle,
            handleClick,
        };
    },
});
