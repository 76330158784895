import { computed, defineComponent, ref, watch, } from '@nuxtjs/composition-api';
import vSelect from 'vue-select';
export default defineComponent({
    name: 'ZnSelect',
    components: {
        vSelect,
    },
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            type: String,
            required: false,
            default: undefined,
        },
        id: {
            type: String,
            required: false,
            default: undefined,
        },
        /**
         * An array with objects containing label and value property, or if different should change the labelField and valueField,
         * or can be a primitive array
         */
        options: {
            type: Array,
            required: false,
            default: undefined,
        },
        labelField: {
            type: String,
            required: false,
            default: 'label',
        },
        valueField: {
            type: String,
            required: false,
            default: 'value',
        },
        value: {
            type: [Object, Number, String],
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select',
        },
        dataTestId: {
            type: String,
            required: false,
            default: undefined,
        },
        searchable: {
            type: Boolean,
            required: false,
            default: true,
        },
        searchMethod: {
            type: String,
            required: false,
            default: 'contains',
        },
        reduce: {
            type: Function,
            required: false,
            default: null,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        requiredMessage: {
            type: String,
            required: false,
            default: undefined,
        },
        errors: {
            type: Array,
            required: false,
            default: undefined,
        },
        translate: {
            type: String,
            required: false,
            default: 'yes',
        },
        appendToBody: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['change'],
    setup(props, { emit }) {
        const errorsData = ref(props.errors || []);
        const selectedValue = computed(() => props.value);
        watch(() => props.value, (newValue) => {
            if (newValue) {
                errorsData.value = [];
            }
        });
        const validateRules = (val) => {
            errorsData.value = [];
            const value = val || props.value;
            if (props.required && !value) {
                let errorToAdd = 'This field is required';
                if (props.requiredMessage) {
                    errorToAdd = props.requiredMessage;
                }
                else if (props.title) {
                    errorToAdd = `${props.title} is required.`;
                }
                errorsData.value.push(errorToAdd);
                emit('onValidate', errorsData.value);
                return;
            }
            emit('onValidate', errorsData.value);
        };
        const valueChanged = (value) => {
            validateRules(value);
            emit('change', value);
        };
        const filterBy = (option, label, search) => {
            if (props.searchMethod === 'contains') {
                return (label || '')
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase());
            }
            return (label || '')
                .toLocaleLowerCase()
                .startsWith(search.toLocaleLowerCase(), 0);
        };
        return {
            selectedValue,
            valueChanged,
            validateRules,
            errorsData,
            filterBy,
        };
    },
});
