import Vue from 'vue';
import { extractComponents } from '../../utils/render-helper';
export default Vue.extend({
    name: 'RenderContent',
    props: {
        content: {
            type: [Array, Object],
        },
    },
    computed: {
        components() {
            return extractComponents(this.content);
        },
    },
});
