export const SimpleProduct = () => import('../../modules/catalog/product/components/product-types/simple/SimpleProduct.vue' /* webpackChunkName: "components/simple-product" */).then(c => wrapFunctional(c.default || c))
export const BundleProduct = () => import('../../modules/catalog/product/components/product-types/bundle/BundleProduct.vue' /* webpackChunkName: "components/bundle-product" */).then(c => wrapFunctional(c.default || c))
export const BundleProductOptionSkeleton = () => import('../../modules/catalog/product/components/product-types/bundle/BundleProductOptionSkeleton.vue' /* webpackChunkName: "components/bundle-product-option-skeleton" */).then(c => wrapFunctional(c.default || c))
export const BundleProductSelector = () => import('../../modules/catalog/product/components/product-types/bundle/BundleProductSelector.vue' /* webpackChunkName: "components/bundle-product-selector" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/cms/page/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const Alert = () => import('../../components/cms/page/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/cms/page/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BannerGrid = () => import('../../components/cms/page/BannerGrid.vue' /* webpackChunkName: "components/banner-grid" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/cms/page/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const Carousel = () => import('../../components/cms/page/Carousel.vue' /* webpackChunkName: "components/carousel" */).then(c => wrapFunctional(c.default || c))
export const Editorial = () => import('../../components/cms/page/Editorial.vue' /* webpackChunkName: "components/editorial" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/cms/page/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Gallery = () => import('../../components/cms/page/Gallery.vue' /* webpackChunkName: "components/gallery" */).then(c => wrapFunctional(c.default || c))
export const Grid = () => import('../../components/cms/page/Grid.vue' /* webpackChunkName: "components/grid" */).then(c => wrapFunctional(c.default || c))
export const Heading = () => import('../../components/cms/page/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/cms/page/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const Link = () => import('../../components/cms/page/Link.vue' /* webpackChunkName: "components/link" */).then(c => wrapFunctional(c.default || c))
export const List = () => import('../../components/cms/page/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const Navigation = () => import('../../components/cms/page/Navigation.vue' /* webpackChunkName: "components/navigation" */).then(c => wrapFunctional(c.default || c))
export const NavigationItem = () => import('../../components/cms/page/NavigationItem.vue' /* webpackChunkName: "components/navigation-item" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/cms/page/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/cms/page/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const RenderContent = () => import('../../components/cms/page/RenderContent.vue' /* webpackChunkName: "components/render-content" */).then(c => wrapFunctional(c.default || c))
export const Steps = () => import('../../components/cms/page/Steps.vue' /* webpackChunkName: "components/steps" */).then(c => wrapFunctional(c.default || c))
export const StyleGuide = () => import('../../components/cms/page/StyleGuide.vue' /* webpackChunkName: "components/style-guide" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/cms/page/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const WideBanner = () => import('../../components/cms/page/WideBanner.vue' /* webpackChunkName: "components/wide-banner" */).then(c => wrapFunctional(c.default || c))
export const Category = () => import('../../modules/catalog/pages/category.vue' /* webpackChunkName: "components/category" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../modules/catalog/pages/product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
