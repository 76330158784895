import { Logger } from '~/helpers/logger';
export const removeItemCommand = {
    execute: async (context, { currentCart, product, customQuery, customHeaders, }) => {
        Logger.debug('[Magento]: Remove item from cart', {
            product,
            currentCart,
        });
        const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);
        if (!item) {
            return;
        }
        const removeItemParams = {
            cart_id: currentCart.id,
            cart_item_uid: item.uid,
        };
        const { data } = await context.$magento.api.removeItemFromCart(removeItemParams, customQuery, customHeaders);
        Logger.debug('[Result]:', { data });
        // eslint-disable-next-line consistent-return
        return data
            .removeItemFromCart
            .cart;
    },
};
