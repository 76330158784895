import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
/**
 * Allows loading upsell products.
 *
 * See the {@link UseUpsellProductsInterface} for a list of methods and values available in this composable.
 */
export function useUpsellProducts() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({
        search: null,
    });
    const search = async (params) => {
        var _a, _b, _c;
        const { customQuery, ...searchParams } = params;
        let results = null;
        try {
            loading.value = true;
            Logger.debug('[Magento] Load upsell products based on ', { searchParams });
            const { data } = await app
                .$vsf
                .$magento
                .api
                .upsellProduct(searchParams, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            Logger.debug('[Result] Upsell products:', { data });
            results = (_c = (_b = data.products) === null || _b === void 0 ? void 0 : _b.items[0]) === null || _c === void 0 ? void 0 : _c.upsell_products;
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            Logger.error('useUpsellProducts/search', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    return {
        search,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export default useUpsellProducts;
export * from './useUpsellProducts';
