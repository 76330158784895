import { reactive, computed, useRouter, useRoute, } from '@nuxtjs/composition-api';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
const state = reactive({
    loading: false,
});
export const useB2BUiState = () => {
    const loading = computed(() => state.loading);
    const toggleLoading = () => {
        state.loading = !state.loading;
    };
    const route = useRoute();
    const { query: routeQuery } = route.value;
    const router = useRouter();
    const { localePath } = useUrlLanguageParser();
    const goTo = async (args) => {
        const { path, query, } = args;
        await router.push(decodeURIComponent(localePath(path, { ...routeQuery, ...query })));
    };
    return {
        loading,
        router,
        goTo,
        toggleLoading,
    };
};
