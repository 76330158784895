import { defineStore } from 'pinia';
import categoryListGql from './graphql/categoryList.gql';
export const useCategoryStore = defineStore('category', {
    state: () => ({
        rawCategories: null,
    }),
    actions: {
        async load() {
            var _a;
            const { data } = await this.$nuxt.app.$vsf.$magento.api.customQuery({ query: categoryListGql });
            this.rawCategories = (_a = data === null || data === void 0 ? void 0 : data.categories) !== null && _a !== void 0 ? _a : null;
        },
    },
    getters: {
        categories(state) {
            var _a;
            if (state.rawCategories === null) {
                return null;
            }
            return (_a = state.rawCategories) === null || _a === void 0 ? void 0 : _a.items[0];
        },
    },
});
