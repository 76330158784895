import { readonly, ref, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
/**
 * Allows to request a password reset email and to set a new password to a user.
 *
 * Se the {@link UseForgotPasswordInterface} for a list of methods and values available in this composable.
 */
export function useForgotPassword() {
    const { app } = useContext();
    const loading = ref(false);
    const result = ref({
        resetPasswordResult: null,
        setNewPasswordResult: null,
    });
    const error = ref({
        request: null,
        setNew: null,
    });
    // eslint-disable-next-line @typescript-eslint/require-await,consistent-return
    const resetPassword = async (resetPasswordParams) => {
        var _a, _b;
        Logger.debug('useForgotPassword/request', resetPasswordParams.email);
        try {
            loading.value = true;
            Logger.debug('[Magento]: Reset user password', resetPasswordParams);
            // eslint-disable-next-line max-len
            const { data } = await app.context.$vsf.$magento.api.requestPasswordResetEmail({ email: resetPasswordParams.email, recaptchaToken: resetPasswordParams.recaptchaToken }, (_a = resetPasswordParams === null || resetPasswordParams === void 0 ? void 0 : resetPasswordParams.customQuery) !== null && _a !== void 0 ? _a : null, resetPasswordParams === null || resetPasswordParams === void 0 ? void 0 : resetPasswordParams.customHeaders);
            Logger.debug('[Result]:', { data });
            error.value.request = data;
            result.value.resetPasswordResult = (_b = data === null || data === void 0 ? void 0 : data.requestPasswordResetEmail) !== null && _b !== void 0 ? _b : false;
        }
        catch (err) {
            error.value.request = err;
            Logger.error('useForgotPassword/request', err);
        }
        finally {
            loading.value = false;
        }
    };
    const setNewPassword = async (setNewPasswordParams) => {
        var _a, _b;
        Logger.debug('useForgotPassword/setNew', setNewPasswordParams);
        try {
            loading.value = true;
            const { data } = await app.context.$vsf.$magento.api.resetPassword({
                email: setNewPasswordParams.email,
                newPassword: setNewPasswordParams.newPassword,
                resetPasswordToken: setNewPasswordParams.tokenValue,
                recaptchaToken: setNewPasswordParams.recaptchaToken,
            }, (_a = setNewPasswordParams === null || setNewPasswordParams === void 0 ? void 0 : setNewPasswordParams.customQuery) !== null && _a !== void 0 ? _a : null, setNewPasswordParams === null || setNewPasswordParams === void 0 ? void 0 : setNewPasswordParams.customHeaders);
            Logger.debug('[Result]:', { data });
            result.value.setNewPasswordResult = (_b = data === null || data === void 0 ? void 0 : data.resetPassword) !== null && _b !== void 0 ? _b : false;
            error.value.setNew = null;
        }
        catch (err) {
            error.value.setNew = err;
            Logger.error('useForgotPassword/setNew', err);
        }
        finally {
            loading.value = false;
        }
    };
    return {
        request: resetPassword,
        setNew: setNewPassword,
        loading: readonly(loading),
        result: readonly(result),
        error: readonly(error),
    };
}
export * from './useForgotPassword';
export default useForgotPassword;
