import { Logger } from '~/helpers/logger';
export const applyCouponCommand = {
    execute: async (context, { currentCart, couponCode, customQuery = { applyCouponToCart: 'applyCouponToCart' }, customHeaders = {}, }) => {
        var _a;
        Logger.debug('[Magento]: Apply coupon on cart', {
            couponCode,
            currentCart,
        });
        const { data, errors } = await context.$magento.api.applyCouponToCart({
            cart_id: currentCart.id,
            coupon_code: couponCode,
        }, customQuery, customHeaders);
        Logger.debug('[Result]:', { data, errors });
        return {
            updatedCart: (_a = data.applyCouponToCart) === null || _a === void 0 ? void 0 : _a.cart,
            updatedCoupon: { code: couponCode },
            errors,
        };
    },
};
