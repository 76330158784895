import { ref, computed } from '@nuxtjs/composition-api';
import { useUrlLanguageParser } from '../../localization/composables/useUrlLanguageParser';
export const useOcr = () => {
    const loading = ref(false);
    const error = ref(null);
    const prescription = ref(null);
    const { storeCode, languageCode, } = useUrlLanguageParser();
    const ocrPrescription = async (file, isUpload) => {
        loading.value = true;
        error.value = null;
        prescription.value = null;
        const countryOfOrigin = computed(() => {
            switch (storeCode.toUpperCase()) {
                case 'GB':
                    return 'UK';
                case 'EU':
                    return `EU_${languageCode.toLowerCase()}`;
                default:
                    return storeCode.toUpperCase();
            }
        });
        try {
            const formData = new FormData();
            if (isUpload) {
                formData.append('image_file', file);
                formData.append('country_of_origin', countryOfOrigin.value);
            }
            else {
                const imageResponse = await fetch(file);
                const imageBlob = await imageResponse.blob();
                formData.append('image_file', new File([imageBlob], 'image.png', { type: 'image/png' }));
                formData.append('country_of_origin', countryOfOrigin.value);
            }
            const resp = await fetch('/aiml-ocr-prescription', {
                method: 'POST',
                body: formData,
            });
            const data = await resp.json();
            if (!data.prescription) {
                throw new Error('OCR failed');
            }
            prescription.value = data.prescription;
        }
        catch (err) {
            error.value = err;
        }
        finally {
            loading.value = false;
        }
    };
    const prescriptionHasPrism = computed(() => {
        if (!prescription.value) {
            return false;
        }
        return prescription.value.od.prismHor || prescription.value.od.prismVer || prescription.value.os.prismHor || prescription.value.os.prismVer;
    });
    return {
        loading,
        error,
        prescription,
        ocrPrescription,
        prescriptionHasPrism,
    };
};
