import { ref, computed, readonly, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useConfigStore } from '~/stores/config';
const cache = new Map();
/**
 * Allows loading and changing the currency.
 *
 * See the {@link UseCurrencyInterface} for a list of methods and values available in this composable.
 */
export function useCurrency() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({ load: null, change: null });
    const configStore = useConfigStore();
    const currency = computed(() => configStore.currency);
    const load = async (params) => {
        var _a, _b;
        error.value.load = null;
        loading.value = true;
        Logger.debug('useCurrency/load');
        let data = null;
        try {
            if (cache.has(`currency:${configStore.storeConfig.store_code}`)) {
                data = cache.get(`currency:${configStore.storeConfig.store_code}`);
            }
            else {
                const currencyQuery = await app.$vsf.$magento.api.currency((_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, (_b = params === null || params === void 0 ? void 0 : params.customHeaders) !== null && _b !== void 0 ? _b : null);
                data = currencyQuery === null || currencyQuery === void 0 ? void 0 : currencyQuery.data;
                cache.set(`currency:${configStore.storeConfig.store_code}`, data);
            }
            configStore.$patch((state) => {
                var _a;
                state.currency = (_a = data === null || data === void 0 ? void 0 : data.currency) !== null && _a !== void 0 ? _a : {};
            });
        }
        catch (err) {
            Logger.debug('[ERROR] useCurrency/load', err);
            error.value.load = err;
        }
        finally {
            loading.value = false;
        }
    };
    const change = (params) => {
        error.value.change = null;
        Logger.debug('useCurrency/change');
        try {
            app.$vsf.$magento.config.state.setCurrency(params.id);
            window.location.reload();
        }
        catch (err) {
            Logger.debug('[ERROR] useCurrency/change', err);
            error.value.change = err;
        }
    };
    return {
        load,
        change,
        currency,
        error: readonly(error),
        loading: readonly(loading),
    };
}
export * from './useCurrency';
export default useCurrency;
