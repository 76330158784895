import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnBadge',
    props: {
        count: {
            type: Number,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: 'primary',
        },
        textColor: {
            type: String,
            required: false,
            default: 'white',
        },
    },
});
