import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
/**
 * Allows searching for categories. It is
 * commonly used in subtrees navigation.
 *
 * See the {@link UseCategorySearchInterface} for a list of methods and values available in this composable.
 */
export function useCategorySearch() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({
        search: null,
    });
    const result = ref(null);
    const search = async (params) => {
        var _a;
        Logger.debug('useCategory/search', params);
        try {
            loading.value = true;
            const { filters } = params;
            const { data } = await app.context.$vsf.$magento.api.categorySearch({ filters }, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            Logger.debug('[Result]:', { data });
            result.value = data.categoryList;
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            result.value = null;
            Logger.error('useCategory/search', err);
        }
        finally {
            loading.value = false;
        }
    };
    return {
        search,
        loading: readonly(loading),
        error: readonly(error),
        result: readonly(result),
    };
}
export * from './useCategorySearch';
export default useCategorySearch;
