import { SfBottomNavigation, SfCircleIcon } from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
const MobileCategorySidebar = () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue');
export default defineComponent({
    name: 'BottomNavigation',
    components: {
        SfBottomNavigation,
        SfCircleIcon,
        MobileCategorySidebar,
        SvgImage,
    },
    setup() {
        const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleMobileMenu, isMobileMenuOpen, } = useUiState();
        const { isAuthenticated } = useUser();
        const router = useRouter();
        const { app } = useContext();
        const handleHomeClick = async () => {
            const homePath = app.localeRoute({ name: 'home' });
            await router.push(homePath);
            if (isMobileMenuOpen.value) {
                toggleMobileMenu();
            }
        };
        const handleAccountClick = async () => {
            if (isAuthenticated.value) {
                await router.push(app.localeRoute({ name: 'customer' }));
            }
            else {
                toggleLoginModal();
            }
        };
        const loadCategoryMenu = async () => {
            const categories = useCategoryStore();
            if (categories.categories === null) {
                await categories.load();
            }
            toggleMobileMenu();
        };
        return {
            isAuthenticated,
            isMobileMenuOpen,
            toggleWishlistSidebar,
            toggleCartSidebar,
            toggleMobileMenu,
            loadCategoryMenu,
            handleAccountClick,
            handleHomeClick,
        };
    },
});
