import { defineComponent, onMounted, ref, watchEffect, } from '@nuxtjs/composition-api';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { isMobile } from 'mobile-device-detect';
export default defineComponent({
    name: 'ZnTelInput',
    model: {
        prop: 'value',
        event: 'change',
    },
    props: {
        title: {
            type: String,
            default: undefined,
            required: false,
        },
        name: {
            type: String,
            default: undefined,
            required: false,
        },
        value: {
            type: [Number, String],
            required: false,
            default: undefined,
        },
        rules: {
            type: [Array, Function],
            required: false,
            default: undefined,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        initialCountry: {
            type: String,
            required: false,
            default: 'US',
        },
        requiredMessage: {
            type: String,
            required: false,
            default: undefined,
        },
        errors: {
            type: Array,
            required: false,
            default: undefined,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-tel-input',
        },
    },
    emits: ['change', 'onValidate', 'focus'],
    setup(props, { emit }) {
        const phoneInput = ref(null);
        const intlMobileDropdown = ref(null);
        const errorsData = ref(props.errors || []);
        const iti = ref();
        onMounted(() => {
            iti.value = intlTelInput(phoneInput.value, {
                separateDialCode: true,
                initialCountry: props.initialCountry.toUpperCase(),
                dropdownContainer: isMobile && intlMobileDropdown.value ? intlMobileDropdown.value : null,
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js',
            });
            if (props.value) {
                iti.value.setNumber(props.value);
            }
        });
        watchEffect(() => {
            var _a;
            const newValue = props.value;
            const number = (_a = iti.value) === null || _a === void 0 ? void 0 : _a.getNumber();
            if (!number && newValue && iti.value) {
                iti.value.setNumber(newValue);
            }
        });
        const countryMask = () => {
            var _a;
            if ((_a = phoneInput.value) === null || _a === void 0 ? void 0 : _a.placeholder) {
                return phoneInput.value.placeholder.replace(/\d/g, '#');
            }
            return '';
        };
        const validateRule = (rule, value) => {
            const ruleReturn = rule(value);
            if (!ruleReturn) {
                errorsData.value.push(ruleReturn);
            }
        };
        const validateRules = (val) => {
            errorsData.value = [];
            const value = val || props.value;
            if (props.required && !value) {
                let errorToAdd = 'This field is required';
                if (props.requiredMessage) {
                    errorToAdd = props.requiredMessage;
                }
                else if (props.title) {
                    errorToAdd = `${props.title} is required.`;
                }
                errorsData.value.push(errorToAdd);
                emit('onValidate', errorsData.value);
                return;
            }
            if (Array.isArray(props.rules)) {
                props.rules.forEach((rule) => validateRule(rule, value));
            }
            else {
                validateRule(props.rules, value);
            }
            emit('onValidate', errorsData.value);
        };
        const onCountryChange = () => {
            var _a;
            const number = (_a = iti.value) === null || _a === void 0 ? void 0 : _a.getNumber(0);
            validateRules(number);
            emit('change', number);
        };
        const onValueChanged = () => {
            var _a;
            const number = (_a = iti.value) === null || _a === void 0 ? void 0 : _a.getNumber(0);
            validateRules(number);
            emit('change', number);
        };
        const onFocusEvent = (event) => {
            errorsData.value = [];
            emit('focus', event);
        };
        const isNumber = (event) => {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                event.preventDefault();
            }
            else {
                return true;
            }
        };
        return {
            phoneInput,
            errorsData,
            countryMask,
            validateRules,
            onCountryChange,
            onValueChanged,
            onFocusEvent,
            isNumber,
            intlMobileDropdown,
        };
    },
});
