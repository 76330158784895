import { computed, defineComponent, ref, useRoute, } from '@nuxtjs/composition-api';
import { LanguageSelector, useUrlLanguageParser, stores, } from '~/modules/b2b/localization';
export default defineComponent({
    name: 'AppFooter',
    components: {
        LanguageSelector,
    },
    setup() {
        const { storeCode, } = useUrlLanguageParser();
        const route = useRoute();
        const store = stores[storeCode];
        const languageSelectorVisible = ref(false);
        /* need to change style of footer because cart page has CTA Sticky button. */
        const isCartPage = computed(() => route.value.path === '/cart');
        const toggleLanguageSelector = () => {
            languageSelectorVisible.value = !languageSelectorVisible.value;
        };
        const modalClosed = () => {
            languageSelectorVisible.value = false;
        };
        return {
            languageSelectorVisible,
            toggleLanguageSelector,
            modalClosed,
            store,
            isCartPage,
        };
    },
});
