import { defineComponent, ref, computed, watch, useFetch, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { ZnCard, ZnCurrency } from '~/modules/b2b/components';
import { usePdpFlow } from '~/modules/b2b/prescription/composables';
import { LensCoatingTypeDefaults } from '~/composables/useContentful/defaults';
import { sanitize } from 'isomorphic-dompurify';
export default defineComponent({
    name: 'LensCoatingTypeSelect',
    components: {
        ZnCard,
        ZnCurrency,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'select-lens-coating',
        },
        product: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup(props, { emit }) {
        const { search, content } = useContentful('selectLensCoating');
        const selectedItem = ref(null);
        const fetchContentfulData = async () => {
            var _a, _b;
            if (!((_a = props.product) === null || _a === void 0 ? void 0 : _a.sku))
                return;
            emit('group:loading', { id: props.id, loading: true });
            await search({
                custom: {
                    type: 'pdpCardGroup',
                    field: 'name',
                    value: `choose-lens-coating-${(_b = props.product) === null || _b === void 0 ? void 0 : _b.sku}`,
                },
            });
            emit('group:loading', { id: props.id, loading: false });
        };
        useFetch(async () => {
            await fetchContentfulData();
        });
        watch(() => props.product, async (newProduct) => {
            if (!(newProduct === null || newProduct === void 0 ? void 0 : newProduct.sku))
                return;
            await fetchContentfulData();
        }, { deep: true });
        const lensCoatingTypes = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.options) || LensCoatingTypeDefaults.options; });
        const lensCoatingTitle = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || LensCoatingTypeDefaults.title; });
        const { flowData } = usePdpFlow();
        watch(flowData, (data) => {
            if (data && data.length > 0) {
                const state = data.find((d) => d.id === props.id);
                if (state) {
                    selectedItem.value = {
                        id: state.data.select,
                    };
                }
            }
            else {
                selectedItem.value = null;
            }
        });
        const productItemMatched = (item) => {
            var _a, _b;
            const items = (_a = props.product) === null || _a === void 0 ? void 0 : _a.items;
            if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
                const hasItem = (_b = props.product) === null || _b === void 0 ? void 0 : _b.items[1].options.find((option) => { var _a; return option.product.sku === ((_a = item.fields) === null || _a === void 0 ? void 0 : _a.id); });
                if (hasItem)
                    return hasItem;
            }
            return null;
        };
        const regularItemPrice = (item) => { var _a; return Number((_a = productItemMatched(item)) === null || _a === void 0 ? void 0 : _a.product.price_range.maximum_price.regular_price.value); };
        const finalItemPrice = (item) => { var _a; return Number((_a = productItemMatched(item)) === null || _a === void 0 ? void 0 : _a.product.price_range.maximum_price.final_price.value); };
        const hasDiscountPrice = (item) => {
            if (regularItemPrice(item) !== finalItemPrice(item))
                return true;
            return false;
        };
        const getPriceFromFields = (item) => {
            var _a;
            if (item.fields.price) {
                return sanitize((_a = item === null || item === void 0 ? void 0 : item.fields) === null || _a === void 0 ? void 0 : _a.price);
            }
            return '';
        };
        const getCardItem = (item, index) => {
            var _a, _b, _c;
            return ({
                ...item === null || item === void 0 ? void 0 : item.fields,
                id: ((_a = item === null || item === void 0 ? void 0 : item.fields) === null || _a === void 0 ? void 0 : _a.id) || index,
                // subtitle: item?.fields?.price ? (item?.fields?.price === '0' ? 'Included' : `$${item?.fields?.price}`) : '',
                // subtitle: getCardPrice(item),
                selected: selectedItem.value
                    ? selectedItem.value.id === ((_b = item === null || item === void 0 ? void 0 : item.fields) === null || _b === void 0 ? void 0 : _b.id) || selectedItem.value.id === index
                    : (_c = item === null || item === void 0 ? void 0 : item.fields) === null || _c === void 0 ? void 0 : _c.selected,
                tags: item.fields.tags
                    ? item.fields.tags.map((tag) => ({ label: tag.fields.label, color: tag.fields.color, textColor: tag.fields.textColor }))
                    : [],
            });
        };
        const handleClick = (card) => {
            selectedItem.value = card;
            emit('group:select', { select: card.id, id: props.id, price: card.price });
        };
        return {
            lensCoatingTitle,
            lensCoatingTypes,
            handleClick,
            getCardItem,
            getPriceFromFields,
            productItemMatched,
            hasDiscountPrice,
            regularItemPrice,
            finalItemPrice,
        };
    },
});
