import { Logger } from '~/helpers/logger';
export const updateItemQtyCommand = {
    execute: async (context, { currentCart, product, quantity, customQuery = { updateCartItems: 'updateCartItems' }, customHeaders = {}, }) => {
        Logger.debug('[Magento]: Update product quantity on cart', {
            product,
            quantity,
            currentCart,
        });
        const updateCartParams = {
            cart_id: currentCart.id,
            cart_items: [
                {
                    cart_item_uid: product.uid,
                    quantity,
                },
            ],
        };
        const { data } = await context.$magento.api.updateCartItems(updateCartParams, customQuery, customHeaders);
        Logger.debug('[Result]:', { data });
        return data
            .updateCartItems
            .cart;
    },
};
