import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { loadContentCommand } from './commands/loadContentCommand';
import { loadBlocksCommand } from './commands/loadBlocksCommand';
/**
 * Allows loading CMS Pages or Blocks from Magento API.
 *
 * See the {@link UseContentInterface} for a list of methods and values available in this composable.
 */
export function useContent() {
    const loading = ref(false);
    const error = ref({
        page: null,
        blocks: null,
    });
    const { app } = useContext();
    const context = app.$vsf;
    async function loadPage(params) {
        Logger.debug('useContent/loadPage');
        loading.value = true;
        let result = null;
        try {
            error.value.page = null;
            result = await loadContentCommand.execute(context, params);
        }
        catch (err) {
            error.value.page = err;
        }
        finally {
            loading.value = false;
        }
        return result;
    }
    async function loadBlocks(params) {
        Logger.debug('useContent/loadBlocks');
        loading.value = true;
        let result = [];
        try {
            error.value.blocks = null;
            result = await loadBlocksCommand.execute(context, params);
        }
        catch (err) {
            error.value.blocks = err;
        }
        finally {
            loading.value = false;
        }
        return result;
    }
    return {
        error: readonly(error),
        loading: readonly(loading),
        loadPage,
        loadBlocks,
    };
}
export default useContent;
export * from './useContent';
