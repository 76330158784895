import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useApi from '~/composables/useApi';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import getFacetDataQuery from './getFacetData.gql';
/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet() {
    const { query } = useApi();
    const loading = ref(false);
    const result = ref({ data: null, input: null });
    const error = ref({
        search: null,
    });
    const defaultItemsPerPage = 20;
    const search = async (params) => {
        var _a, _b;
        Logger.debug('useFacet/search', params);
        result.value.input = params;
        try {
            loading.value = true;
            const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;
            const productSearchParams = {
                pageSize,
                search: params.term ? params.term : '',
                filter: createProductAttributeFilterInput(params),
                sort: createProductAttributeSortInput(params.sort || ''),
                currentPage: params.page,
            };
            const { data } = await query(getFacetDataQuery, productSearchParams);
            const products = (_a = data === null || data === void 0 ? void 0 : data.products) !== null && _a !== void 0 ? _a : null;
            Logger.debug('[Result]:', { products });
            result.value.data = {
                items: (_b = products === null || products === void 0 ? void 0 : products.items) !== null && _b !== void 0 ? _b : [],
                total: products === null || products === void 0 ? void 0 : products.total_count,
                availableSortingOptions: sortingOptions,
                perPageOptions,
                itemsPerPage: pageSize,
            };
            error.value.search = null;
        }
        catch (err) {
            error.value.search = err;
            Logger.error('useFacet/search', err);
        }
        finally {
            loading.value = false;
        }
    };
    return {
        search,
        result,
        error: readonly(error),
        loading: readonly(loading),
    };
}
export * from './useFacet';
export default useFacet;
