import Vue from 'vue';
import { SfFooter, SfList, SfMenuItem } from '@storefront-ui/vue';
export default Vue.extend({
    name: 'Footer',
    components: {
        SfFooter,
        SfList,
        SfMenuItem,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
});
