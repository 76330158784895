//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormConsumer } from '@formily/vue';
import { SfIcon } from '@storefront-ui/vue';

export default {
  name: 'ZnFormItemError',
  components: {
    SfIcon,
    FormConsumer,
  },
  props: {
    fields: {
      type: Array,
      default: () => ([]),
    },
    globalErrorMessages: {
      type: Array,
      default: () => ([]),
    },
    showWarnings: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const getErrorList = (errors) => {
      const res = props.fields.map((field) => {
        const allErrors = errors.flatMap(({ messages }) => messages);
        const fieldHasError = allErrors.find(
          ([messageType]) => messageType === field,
        );
        if (fieldHasError) {
          return fieldHasError.slice(1);
        }
        return null;
      });
      if (props.globalErrorMessages) {
        res.push(...props.globalErrorMessages);
      }
      return res.filter((error) => !!error).flat(1);
    };

    const formatErrorSectionId = (automationId) => {
      if (!automationId) return '';

      // format error id from pdError to error_pd BAX-44
      return 'error_'.concat(automationId.replace(/Error/g, '').replace(/([A-Z])/g, '_$1').toLowerCase());
    };

    return {
      getErrorList,
      formatErrorSectionId,
    };
  },
};
