/* eslint-disable @typescript-eslint/naming-convention */
import { groupBy } from 'lodash-es';
import { ref, computed, onMounted, watch, useContext, useRoute, } from '@nuxtjs/composition-api';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useCountrySearch, useShipping, useShippingProvider, useMagentoConfiguration, useConfig, } from '~/composables';
import { parsePhoneNumber } from 'libphonenumber-js';
import { useGuestUser } from '~/modules/b2b/customer/composables';
import { STORE_CURRENCY_MAPPER, PARTNER_ID_MAPS, VR_FLOW_PARTNER_ID, QUEST_GOOSE_BUNDLE_SKU, QUEST_3_BUNDLE_SKU, } from '~/modules/b2b/constants.client';
import { useUrlLanguageParser, useStoresHelpers } from '~/modules/b2b/localization/composables';
import { useProductPrices } from '../../composables';
import { isValidDiscount } from './utils';
const CHECKOUT_DATA = 'CHECKOUT_DATA';
const LAST_ORDER_PLACED = 'LAST_ORDER_PLACED';
const newMatrixDeliveryTimes = {
    us: {
        standard: '7 to 13',
        expedited: '4 to 6',
    },
    ca: {
        standard: '4 to 7',
        expedited: '4 to 7',
    },
    eu: {
        standard: '4 to 7',
        expedited: '4 to 7',
    },
    gb: {
        standard: '4 to 7',
        expedited: '4 to 7',
    },
    jp: {
        standard: '5 to 9',
        expedited: '5 to 9',
    },
    au: {
        standard: '5 to 7',
        expedited: '5 to 7',
    },
};
const gooseFilteringString = 'goose';
const iso2ToIso3 = {
    US: 'USA',
    CA: 'CAN',
    GB: 'GBR',
    JP: 'JPN',
    AU: 'AUS',
};
const initialState = {
    contactInfo: {
        email: '',
        phoneNumber: '',
        marketing: false,
        updatesByText: false,
    },
    shippingAddress: {
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        isBillingAddressSame: true,
    },
    latinShippingAddress: {
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
        isBillingAddressSame: true,
    },
    billingAddress: {
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        country: '',
    },
    checkoutFormErrors: {
        email: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
    },
    billingFormErrors: {
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        state: null,
        zipCode: null,
        country: null,
    },
    shippingOptions: null,
    selectedShippingMethod: null,
    lastOrderPlaced: null,
    discounts: null,
    discountsLoading: false,
    cartTotals: null,
    cartTotalsLoading: false, // To avoid multiples fetches an once
};
const state = ref({ ...initialState });
export const useCheckout = () => {
    const { search: searchCountry } = useCountrySearch(); // used to find region in magento
    const { save: saveShipping } = useShipping();
    const { save: saveShippingMethod } = useShippingProvider();
    const { selectedStore, isRegion } = useMagentoConfiguration();
    const { config } = useConfig();
    const selectedCurrency = computed(() => { var _a; return (_a = config.value) === null || _a === void 0 ? void 0 : _a.default_display_currency_code; });
    const { cart, load: loadCart, setCart, cartType, } = useCart();
    const { $gtm } = useContext();
    const { userId } = useGuestUser();
    const router = useRoute();
    const { query } = router.value;
    const { languageCode } = useUrlLanguageParser();
    const { getProductPriceBySku } = useProductPrices();
    const contactInfo = computed(() => state.value.contactInfo);
    const shippingAddress = computed(() => state.value.shippingAddress);
    const latinShippingAddress = computed(() => state.value.latinShippingAddress);
    const billingAddress = computed(() => state.value.billingAddress);
    const shippingOptions = computed(() => state.value.shippingOptions);
    const selectedShippingMethod = computed(() => state.value.selectedShippingMethod);
    const discounts = computed(() => state.value.discounts);
    const cartTotals = computed(() => state.value.cartTotals);
    const cartTotalsLoading = computed(() => state.value.cartTotalsLoading);
    const loadingShippingOptions = ref(false);
    const setCartsTotalsLoading = (loading) => {
        state.value.cartTotalsLoading = loading;
    };
    const isFormValidated = (formErrors) => {
        let validated = true;
        Object.values(formErrors).forEach((value) => {
            if (!!value && (value === null || value === void 0 ? void 0 : value.length) > 0) {
                validated = false;
                return true;
            }
        });
        return validated;
    };
    const isCheckoutFormValidated = computed(() => isFormValidated(state.value.checkoutFormErrors));
    const isBillingFormValidated = computed(() => isFormValidated(state.value.billingFormErrors));
    const loadDiscounts = async (store) => {
        if (!state.value.discounts && store && !state.value.discountsLoading) {
            state.value.discountsLoading = true;
            const req = await fetch(`/discounts?magentoStore=${store}`);
            if (req.ok) {
                state.value.discounts = await req.json();
            }
            state.value.discountsLoading = false;
        }
    };
    const getPrdMapFromCart = async () => {
        const productSkus = new Set(cart.value.items.map((item) => item.product.sku));
        const prdData = await Promise.all([...productSkus].map((sku) => getProductPriceBySku(sku)));
        const prdDataMap = prdData.reduce((acc, item) => {
            const { data } = item;
            const product = data.products.items[0];
            // @ts-ignore
            acc[product.id] = product;
            return acc;
        }, {});
        return prdDataMap;
    };
    const getItemsSkusFromCart = async () => {
        const prdDataMap = await getPrdMapFromCart();
        return cart.value.items.flatMap((item) => {
            var _a, _b, _c;
            const productId = Number(atob(item.product.uid));
            const prdData = prdDataMap[productId];
            return ((_c = (_b = (_a = item.bundle_options) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.values) === null || _c === void 0 ? void 0 : _c.map((bundleOptionValue) => {
                var _a, _b, _c;
                const selectOptionItem = (_c = (_b = (_a = prdData.items) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.options) === null || _c === void 0 ? void 0 : _c.find((option) => option.uid === bundleOptionValue.uid);
                if (!selectOptionItem)
                    return null;
                const selectOptionMaximumPrice = selectOptionItem.product.price_range.maximum_price;
                return {
                    sku: selectOptionItem.product.sku,
                    label: selectOptionItem.product.name,
                    amount: selectOptionMaximumPrice.final_price.value,
                    quantity: 1,
                };
            })) || [];
        });
    };
    const loadCartTotals = async () => {
        var _a, _b, _c, _d;
        if (!state.value.cartTotalsLoading && ((_a = cart.value) === null || _a === void 0 ? void 0 : _a.id)) {
            state.value.cartTotalsLoading = true;
            const items = await getItemsSkusFromCart();
            const address = (_b = cart.value.shipping_addresses) === null || _b === void 0 ? void 0 : _b[0];
            const req = await fetch(`/cart-totals/${cart.value.id}`, {
                method: 'POST',
                body: JSON.stringify({
                    items,
                    address: address ? {
                        line1: (_c = address.street) === null || _c === void 0 ? void 0 : _c[0],
                        city: address.city,
                        region: (_d = address.region) === null || _d === void 0 ? void 0 : _d.code,
                        postalCode: address.postcode,
                        country: address.country.code,
                    } : undefined,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (req.ok) {
                const cartTotalsRes = await req.json();
                // if user change from one store to another modifying the url, this totals could be from another store
                if ((cartTotalsRes === null || cartTotalsRes === void 0 ? void 0 : cartTotalsRes.base_currency_code) === STORE_CURRENCY_MAPPER[selectedStore.value]) {
                    state.value.cartTotals = cartTotalsRes;
                }
                else {
                    // we are in a different store, we must clear the cart cache and reload the cart
                    sessionStorage.clear();
                    await loadCart();
                }
            }
            state.value.cartTotalsLoading = false;
        }
    };
    const clearSessionStorage = () => {
        state.value = { ...initialState };
        sessionStorage.removeItem(CHECKOUT_DATA);
    };
    const isSessionStorageAvailable = () => {
        const checkoutData = sessionStorage.getItem(CHECKOUT_DATA);
        if (!checkoutData)
            return false;
        return true;
    };
    onMounted(async () => {
        var _a;
        if (!state.value.discounts && selectedStore.value)
            await loadDiscounts(selectedStore.value);
        if (!state.value.cartTotals && ((_a = cart.value) === null || _a === void 0 ? void 0 : _a.id))
            await loadCartTotals();
        const checkoutData = sessionStorage.getItem(CHECKOUT_DATA);
        if (!checkoutData)
            return;
        try {
            const { contactInfo: storageContactInfo, shippingAddress: storageShippingAddress, billingAddress: storageBillingAddress, shippingOptions: storageShippingOptions, selectedShippingMethod: storageSelectedShippingMethod, latinShippingAddress: storageLatinShippingAddress, } = JSON.parse(checkoutData);
            if (storageContactInfo)
                state.value.contactInfo = storageContactInfo;
            if (storageShippingAddress)
                state.value.shippingAddress = storageShippingAddress;
            if (storageShippingOptions)
                state.value.shippingOptions = storageShippingOptions;
            if (storageBillingAddress && !storageShippingAddress.isBillingAddressSame)
                state.value.billingAddress = storageBillingAddress;
            if (storageSelectedShippingMethod)
                state.value.selectedShippingMethod = storageSelectedShippingMethod;
            if (storageLatinShippingAddress)
                state.value.latinShippingAddress = storageLatinShippingAddress;
        }
        catch (e) {
            console.log(e);
        }
    });
    watch(selectedStore, async (newStore) => {
        if (newStore !== selectedStore.value) {
            await loadDiscounts(newStore);
        }
    });
    const saveStateToSessionStorage = () => {
        sessionStorage.setItem(CHECKOUT_DATA, JSON.stringify({
            contactInfo: state.value.contactInfo,
            shippingAddress: {
                ...state.value.shippingAddress,
                country: !isRegion.value ? selectedStore.value.toUpperCase() : state.value.shippingAddress.country,
            },
            billingAddress: state.value.billingAddress,
            shippingOptions: state.value.shippingOptions,
            selectedShippingMethod: state.value.selectedShippingMethod,
            latinShippingAddress: state.value.latinShippingAddress,
        }));
    };
    const clearShippingAndBillingAddress = () => {
        state.value.shippingAddress = { ...initialState.shippingAddress, country: !isRegion.value ? (selectedStore === null || selectedStore === void 0 ? void 0 : selectedStore.value.toUpperCase()) || '' : '' };
        state.value.billingAddress = { ...initialState.billingAddress };
        state.value.latinShippingAddress = null;
        saveStateToSessionStorage();
    };
    /**
     *
     * @param address
     * @returns Object to use for shipping save
     * @dev this function is used to format the address from google/apple pay to the format used by magento
     *      If the region is not set, it will be queried from magento
     *      If the region is not found, an error will be thrown
     */
    const formatAddress = async (address) => {
        var _a, _b;
        let localRegion = address.region;
        // if region is not set, query from magento
        if (!localRegion) {
            const country = await searchCountry({ id: address.country });
            if (!country.available_regions || country.available_regions.length === 0) {
                localRegion = {
                    code: address.state,
                    id: 0,
                };
            }
            else {
                const region = country.available_regions.find((availableRegion) => availableRegion.name === address.state || availableRegion.code === address.state || (availableRegion.code === `${address.country}-${address.state}`));
                if (!region) {
                    throw new Error('Region not found');
                }
                localRegion = region;
            }
        }
        let phoneNumberFormatted = '';
        if ((address === null || address === void 0 ? void 0 : address.phoneNumber) || ((_a = state.value.contactInfo) === null || _a === void 0 ? void 0 : _a.phoneNumber)) {
            const phoneNumber = parsePhoneNumber((address === null || address === void 0 ? void 0 : address.phoneNumber) || ((_b = state.value.contactInfo) === null || _b === void 0 ? void 0 : _b.phoneNumber), address.country);
            if (phoneNumber) {
                phoneNumberFormatted = phoneNumber.format('E.164');
            }
        }
        return {
            city: (address === null || address === void 0 ? void 0 : address.city) || '',
            country_code: (address === null || address === void 0 ? void 0 : address.country) || '',
            firstname: (address === null || address === void 0 ? void 0 : address.firstName) || '',
            lastname: (address === null || address === void 0 ? void 0 : address.lastName) || '',
            postcode: (address === null || address === void 0 ? void 0 : address.zipCode) || '',
            region: localRegion.code || '',
            region_id: localRegion.id || 0,
            street: (address === null || address === void 0 ? void 0 : address.address) || '',
            apartment: (address === null || address === void 0 ? void 0 : address.address2) || '',
            telephone: phoneNumberFormatted,
        };
    };
    const formatAddressForShipping = async (address) => {
        const result = await formatAddress(address);
        return {
            shippingDetails: result,
        };
    };
    const formatAddressForBilling = async (address, sameAsShipping = false) => {
        const result = await formatAddress(address);
        return {
            billingDetails: {
                ...result,
                sameAsShipping,
            },
        };
    };
    const mapShippingMethodsWithDiscounts = (shippingMethods) => {
        // if we have discounts, we have to check if the shipping method has a discount
        // at store rule level, we have to check in condition.conditions if there is a rule that match with the shipping method (carrier_code)
        if (!state.value.discounts || shippingMethods.length === 0)
            return shippingMethods;
        const isGoose = cartType.value === QUEST_GOOSE_BUNDLE_SKU;
        const shippingMethodsWithDiscounts = shippingMethods
            .filter((method) => (isGoose ? method.carrier_title.includes(gooseFilteringString) : !method.carrier_title.includes(gooseFilteringString)))
            .map((shippingMethod) => {
            const discount = state.value.discounts.storeDiscounts.find((discountItem) => {
                const condition = isGoose
                    ? discountItem.condition.conditions.find((conditionItem) => { var _a; return (_a = conditionItem.conditions) === null || _a === void 0 ? void 0 : _a.find((condItem) => condItem.attribute_name === 'object_id' && condItem.value === gooseFilteringString); })
                    : discountItem.condition.conditions.find((conditionItem) => conditionItem.attribute_name === 'shipping_method' && conditionItem.value === `${shippingMethod.carrier_code}_${shippingMethod.method_code}`);
                return condition;
            });
            let calculatedDiscount = 0;
            if (isValidDiscount(discount, isGoose ? QUEST_GOOSE_BUNDLE_SKU : QUEST_3_BUNDLE_SKU)) {
                calculatedDiscount = discount.simple_action.includes('percent') ? (shippingMethod.amount.value * discount.discount_amount) / 100 : discount.discount_amount;
            }
            return {
                ...shippingMethod,
                regular_price: {
                    ...shippingMethod.amount,
                    value: shippingMethod.amount.value,
                },
                amount: {
                    ...shippingMethod.amount,
                    value: shippingMethod.amount.value - calculatedDiscount,
                },
                discount: calculatedDiscount,
            };
        });
        return shippingMethodsWithDiscounts;
    };
    const getShippingOptionsWithMockData = async () => {
        if (loadingShippingOptions.value)
            return;
        loadingShippingOptions.value = true;
        const estimatedReq = await fetch(`/estimate-shipping-methods/${cart.value.id}`, {
            method: 'POST',
            body: JSON.stringify({ countryId: state.value.shippingAddress.country || selectedStore.value }),
            headers: { 'Content-Type': 'application/json' },
        });
        if (!estimatedReq.ok)
            throw new Error('Error fetching shipping methods');
        const estimatedJson = await estimatedReq.json();
        if ((estimatedJson === null || estimatedJson === void 0 ? void 0 : estimatedJson.length) === 0)
            throw new Error('Shipping country not available');
        // create a graphql like response to use the same component
        const estimated = (estimatedJson === null || estimatedJson === void 0 ? void 0 : estimatedJson.map((method) => ({
            amount: {
                currency: selectedCurrency.value,
                value: method.amount,
                __typename: 'Money',
            },
            available: method.available,
            carrier_code: method.carrier_code,
            carrier_title: method.carrier_title,
            error_message: method.error_message,
            method_code: method.method_code,
            method_title: method.method_title,
            price_excl_tax: {
                currency: selectedCurrency.value,
                value: method.price_excl_tax,
                __typename: 'Money',
            },
            price_incl_tax: {
                currency: selectedCurrency.value,
                value: method.price_incl_tax,
                __typename: 'Money',
            },
            __typename: 'AvailableShippingMethod',
        }))) || [];
        state.value.shippingOptions = mapShippingMethodsWithDiscounts(estimated);
        if (state.value.shippingOptions && state.value.shippingOptions.length > 0)
            [state.value.selectedShippingMethod] = state.value.shippingOptions;
        saveStateToSessionStorage();
        loadingShippingOptions.value = false;
    };
    const syncCartWithStorageData = async () => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39;
        try {
            await loadCart();
            const guestCartReq = await fetch(`/guest-carts/${cart.value.id}`, { method: 'POST' });
            const guestCart = await guestCartReq.json();
            // if we detect there is a shipping address in guest cart different than the one in the state, we first have to clear the state
            // to avoid conflicts with the shipping address in the state and the one in the guest cart
            if (((_e = (_d = (_c = (_b = (_a = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _a === void 0 ? void 0 : _a.shipping_assignments) === null || _b === void 0 ? void 0 : _b[0].shipping) === null || _c === void 0 ? void 0 : _c.address) === null || _d === void 0 ? void 0 : _d.street) === null || _e === void 0 ? void 0 : _e[0]) !== state.value.shippingAddress.address) {
                clearShippingAndBillingAddress();
            }
            // sync contact info with fetch data
            state.value.contactInfo = {
                ...state.value.contactInfo,
                email: state.value.contactInfo.email
                    || ((_j = (_h = (_g = (_f = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _f === void 0 ? void 0 : _f.shipping_assignments) === null || _g === void 0 ? void 0 : _g[0].shipping) === null || _h === void 0 ? void 0 : _h.address) === null || _j === void 0 ? void 0 : _j.email)
                    || ((_k = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _k === void 0 ? void 0 : _k.email),
                phoneNumber: state.value.contactInfo.phoneNumber
                    || ((_p = (_o = (_m = (_l = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _l === void 0 ? void 0 : _l.shipping_assignments) === null || _m === void 0 ? void 0 : _m[0].shipping) === null || _o === void 0 ? void 0 : _o.address) === null || _p === void 0 ? void 0 : _p.telephone)
                    || ((_q = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _q === void 0 ? void 0 : _q.telephone),
            };
            // sync shipping address with fetch data
            state.value.shippingAddress = {
                ...state.value.shippingAddress,
                firstName: state.value.shippingAddress.firstName || ((_u = (_t = (_s = (_r = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _r === void 0 ? void 0 : _r.shipping_assignments) === null || _s === void 0 ? void 0 : _s[0].shipping) === null || _t === void 0 ? void 0 : _t.address) === null || _u === void 0 ? void 0 : _u.firstname),
                lastName: state.value.shippingAddress.lastName || ((_y = (_x = (_w = (_v = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _v === void 0 ? void 0 : _v.shipping_assignments) === null || _w === void 0 ? void 0 : _w[0].shipping) === null || _x === void 0 ? void 0 : _x.address) === null || _y === void 0 ? void 0 : _y.lastname),
                address: state.value.shippingAddress.address || ((_3 = (_2 = (_1 = (_0 = (_z = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _z === void 0 ? void 0 : _z.shipping_assignments) === null || _0 === void 0 ? void 0 : _0[0].shipping) === null || _1 === void 0 ? void 0 : _1.address) === null || _2 === void 0 ? void 0 : _2.street) === null || _3 === void 0 ? void 0 : _3[0]),
                address2: state.value.shippingAddress.address2 || ((_8 = (_7 = (_6 = (_5 = (_4 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _4 === void 0 ? void 0 : _4.shipping_assignments) === null || _5 === void 0 ? void 0 : _5[0].shipping) === null || _6 === void 0 ? void 0 : _6.address) === null || _7 === void 0 ? void 0 : _7.street) === null || _8 === void 0 ? void 0 : _8[1]),
                city: state.value.shippingAddress.city || ((_12 = (_11 = (_10 = (_9 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _9 === void 0 ? void 0 : _9.shipping_assignments) === null || _10 === void 0 ? void 0 : _10[0].shipping) === null || _11 === void 0 ? void 0 : _11.address) === null || _12 === void 0 ? void 0 : _12.city),
                state: state.value.shippingAddress.state || ((_16 = (_15 = (_14 = (_13 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _13 === void 0 ? void 0 : _13.shipping_assignments) === null || _14 === void 0 ? void 0 : _14[0].shipping) === null || _15 === void 0 ? void 0 : _15.address) === null || _16 === void 0 ? void 0 : _16.region),
                zipCode: state.value.shippingAddress.zipCode || ((_20 = (_19 = (_18 = (_17 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _17 === void 0 ? void 0 : _17.shipping_assignments) === null || _18 === void 0 ? void 0 : _18[0].shipping) === null || _19 === void 0 ? void 0 : _19.address) === null || _20 === void 0 ? void 0 : _20.postcode),
                country: state.value.shippingAddress.country || ((_24 = (_23 = (_22 = (_21 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _21 === void 0 ? void 0 : _21.shipping_assignments) === null || _22 === void 0 ? void 0 : _22[0].shipping) === null || _23 === void 0 ? void 0 : _23.address) === null || _24 === void 0 ? void 0 : _24.country_id),
            };
            if (typeof ((_25 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _25 === void 0 ? void 0 : _25.same_as_billing) === 'number') {
                state.value.shippingAddress.isBillingAddressSame = ((_26 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _26 === void 0 ? void 0 : _26.same_as_billing) === 1;
            }
            // sync billing address with fetch data
            state.value.billingAddress = {
                ...state.value.billingAddress,
                firstName: state.value.billingAddress.firstName || ((_27 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _27 === void 0 ? void 0 : _27.firstname),
                lastName: state.value.billingAddress.lastName || ((_28 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _28 === void 0 ? void 0 : _28.lastname),
                address: state.value.billingAddress.address || ((_30 = (_29 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _29 === void 0 ? void 0 : _29.street) === null || _30 === void 0 ? void 0 : _30[0]),
                address2: state.value.billingAddress.address2 || ((_32 = (_31 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _31 === void 0 ? void 0 : _31.street) === null || _32 === void 0 ? void 0 : _32[1]),
                city: state.value.billingAddress.city || ((_33 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _33 === void 0 ? void 0 : _33.city),
                state: state.value.billingAddress.state || ((_34 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _34 === void 0 ? void 0 : _34.region),
                zipCode: state.value.billingAddress.zipCode || ((_35 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _35 === void 0 ? void 0 : _35.postcode),
                country: state.value.billingAddress.country || ((_36 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.billing_address) === null || _36 === void 0 ? void 0 : _36.country_id),
            };
            // sync selected shipping method with fetch data
            const guestSelectedMethod = (_39 = (_38 = (_37 = guestCart === null || guestCart === void 0 ? void 0 : guestCart.extension_attributes) === null || _37 === void 0 ? void 0 : _37.shipping_assignments) === null || _38 === void 0 ? void 0 : _38[0].shipping) === null || _39 === void 0 ? void 0 : _39.method;
            if (guestSelectedMethod) {
                if (!state.value.shippingOptions)
                    await getShippingOptionsWithMockData();
                state.value.selectedShippingMethod = state.value.shippingOptions.find((item) => `${item.carrier_code}_${item.method_code}` === guestSelectedMethod) || state.value.selectedShippingMethod;
            }
            saveStateToSessionStorage();
        }
        catch (e) {
            console.log(e);
        }
    };
    const saveCurrentShippingMethod = async () => {
        if (state.value.selectedShippingMethod) {
            await saveShippingMethod({
                shippingMethod: {
                    method_code: state.value.selectedShippingMethod.method_code,
                    carrier_code: state.value.selectedShippingMethod.carrier_code,
                },
            });
            // setting the shipping method will update taxes and costs so we have to reload the cart totals
            await loadCartTotals();
        }
    };
    const formatTelephone = (countryCode, telephone) => {
        if (!telephone)
            return '';
        const parsedNumber = parsePhoneNumber(telephone, countryCode);
        return parsedNumber ? parsedNumber.format('E.164') : '';
    };
    const updateLatinShippingAddress = (newShippingAddress) => {
        state.value.latinShippingAddress = {
            ...state.value.latinShippingAddress,
            ...newShippingAddress,
        };
        saveStateToSessionStorage();
    };
    const formatAddressForConfig = async (newCheckout = false, useLatinAddress = false) => {
        var _a, _b, _c;
        // latinShippingAddress is always same as shippingAddress except for Japanese store
        // We should always use latinized address to configure cart, but we should use the original address to show in the UI
        // useLatinAddress parameter is used to identifies when we are last step of checkout and we need to use the latinized address
        const formattedAddress = useLatinAddress && ((_b = (_a = state.value.latinShippingAddress) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.length) > 0
            ? state.value.latinShippingAddress
            : state.value.shippingAddress;
        const country = await searchCountry({ id: formattedAddress.country || selectedStore.value.toUpperCase() });
        const region = (_c = country.available_regions) === null || _c === void 0 ? void 0 : _c.find((item) => item.name === formattedAddress.state || item.code === formattedAddress.state || item.code === `${formattedAddress.country}-${formattedAddress.state}`);
        return {
            city: formattedAddress.city,
            country_id: formattedAddress.country,
            email: state.value.contactInfo.email || 'john@doe.com',
            firstname: formattedAddress.firstName,
            lastname: formattedAddress.lastName,
            postcode: formattedAddress.zipCode,
            region: newCheckout
                ? (region
                    ? { code: region === null || region === void 0 ? void 0 : region.code, label: region === null || region === void 0 ? void 0 : region.name, region_id: region === null || region === void 0 ? void 0 : region.id }
                    : { code: formattedAddress.state, label: formattedAddress.state, id: 0 })
                : (region === null || region === void 0 ? void 0 : region.name) || formattedAddress.state,
            region_code: region === null || region === void 0 ? void 0 : region.code,
            region_id: region === null || region === void 0 ? void 0 : region.id,
            street: [formattedAddress.address, formattedAddress.address2].filter((item) => !!item),
            telephone: formatTelephone(formattedAddress.country, state.value.contactInfo.phoneNumber),
            country: newCheckout
                ? { code: country.id, label: country.id }
                : undefined,
        };
    };
    const isAddressEmpty = (address) => {
        if (!address)
            return true;
        const fieldsToValidate = ['firstName', 'lastName', 'address', 'city', 'state', 'zipCode', 'country'];
        const emptyFields = fieldsToValidate.filter((field) => !address[field]);
        if (emptyFields.length > 0) {
            state.value.shippingAddress.isBillingAddressSame = true;
            return true;
        }
        return false;
    };
    const formatBillingForConfig = async (newCheckout = false) => {
        var _a;
        const billingData = state.value.shippingAddress.isBillingAddressSame || isAddressEmpty(state.value.billingAddress)
            ? state.value.shippingAddress
            : state.value.billingAddress;
        const country = await searchCountry({ id: billingData.country || selectedStore.value.toUpperCase() });
        const region = (_a = country.available_regions) === null || _a === void 0 ? void 0 : _a.find((item) => item.name === billingData.state || item.code === billingData.state || item.code === `${billingData.country}-${billingData.state}`);
        return {
            city: billingData.city,
            country_id: billingData.country,
            email: state.value.contactInfo.email || 'john@doe.com',
            firstname: billingData.firstName,
            lastname: billingData.lastName,
            postcode: billingData.zipCode,
            region: newCheckout
                ? (region
                    ? { code: region === null || region === void 0 ? void 0 : region.code, label: region === null || region === void 0 ? void 0 : region.name, region_id: region === null || region === void 0 ? void 0 : region.id }
                    : { code: billingData.state, label: billingData.state, id: 0 })
                : (region === null || region === void 0 ? void 0 : region.name) || billingData.state,
            region_code: region === null || region === void 0 ? void 0 : region.code,
            region_id: region === null || region === void 0 ? void 0 : region.id,
            street: [billingData.address, billingData.address2].filter((item) => !!item),
            telephone: formatTelephone(billingData.country, state.value.contactInfo.phoneNumber),
            country: newCheckout
                ? { code: country.id, label: country.id }
                : undefined,
        };
    };
    const saveConfigToMagento = async (useLatinAddress = false) => {
        // if we don't have the shipping methods we need to query them first. This could happen if session storage is cleared
        if (!state.value.selectedShippingMethod || !state.value.selectedShippingMethod.method_code)
            await getShippingOptionsWithMockData();
        const shipping_address = await formatAddressForConfig(false, useLatinAddress);
        let billing_address = shipping_address;
        billing_address = await formatBillingForConfig();
        const magentoConfigReq = await fetch(`/shipping-information/${cart.value.id}`, {
            method: 'POST',
            body: JSON.stringify({
                addressInformation: {
                    shipping_address,
                    billing_address,
                    shipping_method_code: state.value.selectedShippingMethod.method_code,
                    shipping_carrier_code: state.value.selectedShippingMethod.carrier_code,
                },
                items: await getItemsSkusFromCart(),
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const magentoConfig = await magentoConfigReq.json();
        // Here we should update the cart object as if we were queried from graphql
        // This is because in new cart configuration we are not using the cart object from magento
        const totalSegmentsTax = magentoConfig.totals.total_segments.find((item) => item.code === 'tax');
        const totalSegmentsGrandTotal = magentoConfig.totals.total_segments.find((item) => item.code === 'grand_total');
        const appliedTaxesAux = totalSegmentsTax === null || totalSegmentsTax === void 0 ? void 0 : totalSegmentsTax.extension_attributes.tax_grandtotal_details.map((item) => ({
            amount: {
                value: item.amount,
                __typename: 'Money',
            },
            label: item.rates[0].title,
            __typename: 'CartTaxItem',
        }));
        const appliedTaxes = [];
        const newTaxes = groupBy(appliedTaxesAux, 'label');
        Object.keys(newTaxes).forEach((key) => {
            appliedTaxes.push({
                amount: {
                    value: Number.parseFloat(newTaxes[key].reduce((acc, item) => acc + Number(item.amount.value), 0).toFixed(2)),
                },
                label: key,
                __typename: 'CartTaxItem',
            });
        });
        const newCart = {
            ...cart.value,
            prices: {
                ...cart.value.prices,
                subtotal_excluding_tax: {
                    value: magentoConfig.totals.subtotal,
                    __typename: 'Money',
                },
                subtotal_including_tax: {
                    value: magentoConfig.totals.subtotal_incl_tax,
                    __typename: 'Money',
                },
                applied_taxes: appliedTaxes,
                grand_total: {
                    value: totalSegmentsGrandTotal.value,
                    __typename: 'Money',
                },
            },
            items: cart.value.items.map((item) => {
                const newCartItem = magentoConfig.totals.items.find((magentoItem) => magentoItem.item_id === Number(atob(item.uid)));
                return {
                    ...item,
                    prices: {
                        ...item.prices,
                        row_total: {
                            value: newCartItem.row_total,
                            __typename: 'Money',
                        },
                        row_total_including_tax: {
                            value: newCartItem.row_total_incl_tax,
                            __typename: 'Money',
                        },
                    },
                };
            }),
        };
        saveStateToSessionStorage();
        setCart(newCart);
        state.value.cartTotals = magentoConfig.totals;
    };
    const saveShippingAddressToMagento = async () => {
        const formattedAddress = await formatAddressForShipping(state.value.shippingAddress);
        const shippingCartAddress = await saveShipping(formattedAddress);
        if (shippingCartAddress && shippingCartAddress.available_shipping_methods) {
            state.value.shippingOptions = mapShippingMethodsWithDiscounts(shippingCartAddress.available_shipping_methods);
            saveStateToSessionStorage();
            // set first method as default, if available
            if (shippingCartAddress.available_shipping_methods.length > 0) {
                [state.value.selectedShippingMethod] = state.value.shippingOptions;
                saveStateToSessionStorage();
                await saveCurrentShippingMethod();
            }
        }
    };
    const updateContactInfo = (newContactInfo) => {
        state.value.contactInfo = {
            ...state.value.contactInfo,
            ...newContactInfo,
        };
        saveStateToSessionStorage();
    };
    const updateShippingOptions = async (shippingMethodCode, useLatinAddress = false) => {
        const shippingOption = state.value.shippingOptions.find((options) => options.carrier_code === shippingMethodCode || options.method_code === shippingMethodCode);
        state.value.selectedShippingMethod = shippingOption;
        // await saveCurrentShippingMethod();
        saveStateToSessionStorage();
        await saveConfigToMagento(useLatinAddress);
    };
    // Don't update the session storage as this is for showing in the UI
    const selectDefaultCountry = (country) => {
        state.value.shippingAddress.country = country;
        state.value.billingAddress.country = country;
    };
    const updateShippingAddress = (newShippingAddress) => {
        state.value.shippingAddress = {
            ...state.value.shippingAddress,
            ...newShippingAddress,
        };
        const address2IncludedInAddress = state.value.shippingAddress.address.toLowerCase().includes(state.value.shippingAddress.address2);
        state.value.shippingAddress.address2 = address2IncludedInAddress ? '' : state.value.shippingAddress.address2;
        saveStateToSessionStorage();
    };
    const clearShippingOptions = () => {
        state.value.shippingOptions = null;
        state.value.selectedShippingMethod = null;
        saveStateToSessionStorage();
    };
    const updateBillingAddress = (newBillingAddress) => {
        state.value.billingAddress = {
            ...state.value.billingAddress,
            ...newBillingAddress,
        };
        saveStateToSessionStorage();
    };
    const orderTotalsSummary = computed(() => {
        var _a, _b, _c, _d, _e, _f;
        const total = ((_a = state.value.cartTotals) === null || _a === void 0 ? void 0 : _a.base_grand_total) || 0;
        const subTotal = ((_b = state.value.cartTotals) === null || _b === void 0 ? void 0 : _b.base_subtotal) || 0;
        const auxDiscounts = ((_c = state.value.cartTotals) === null || _c === void 0 ? void 0 : _c.base_discount_amount) || 0; // cart total discounts
        const shippingPrice = (((_d = state.value.cartTotals) === null || _d === void 0 ? void 0 : _d.base_shipping_amount) || 0) - (((_e = state.value.cartTotals) === null || _e === void 0 ? void 0 : _e.base_shipping_discount_amount) || 0);
        const tax = ((_f = state.value.cartTotals) === null || _f === void 0 ? void 0 : _f.base_tax_amount) || 0; // this includes shipping taxes
        const currency = STORE_CURRENCY_MAPPER[selectedStore.value];
        return {
            subTotal,
            shippingPrice,
            tax,
            total,
            discounts: auxDiscounts,
            currency,
        };
    });
    const newCheckoutReq = async (paymentNonce) => {
        var _a, _b;
        const prdDataMap = await getPrdMapFromCart();
        // cart.value.items.forEach((bundleItem: BundleCartItem & { productId: number }) => {
        cart.value.items.forEach((bundleItem) => {
            // eslint-disable-next-line no-param-reassign
            bundleItem.productId = Number(atob(bundleItem.product.uid));
            const productPrices = prdDataMap[bundleItem.productId];
            const cartBundleSelectOptionsMerge = bundleItem.bundle_options[0].values.map((bundleOptionValue) => {
                const selectOptionItem = productPrices.items[0].options.find((option) => option.uid === bundleOptionValue.uid);
                const selectOptionMaximumPrice = selectOptionItem.product.price_range.maximum_price;
                return {
                    ...bundleOptionValue,
                    skuId: selectOptionItem.product.sku,
                    productId: Number(atob(selectOptionItem.product.uid)),
                    discount: Number(selectOptionMaximumPrice.regular_price.value - selectOptionMaximumPrice.final_price.value),
                    price: selectOptionMaximumPrice.final_price.value,
                };
            });
            const cartBundleCheckboxOptionsMerge = bundleItem.bundle_options[1].values.map((bundleOptionValue) => {
                const checkboxOptionItem = productPrices.items[1].options.find((option) => option.product.name === bundleOptionValue.label);
                const checkboxOptionMaximumPrice = checkboxOptionItem.product.price_range.maximum_price;
                return {
                    ...bundleOptionValue,
                    skuId: checkboxOptionItem.product.sku,
                    productId: Number(atob(checkboxOptionItem.product.uid)),
                    discount: Number(checkboxOptionMaximumPrice.regular_price.value - checkboxOptionMaximumPrice.final_price.value),
                    price: checkboxOptionMaximumPrice.final_price.value,
                };
            });
            // eslint-disable-next-line no-param-reassign
            bundleItem.bundle_options[0].values = cartBundleSelectOptionsMerge;
            // eslint-disable-next-line no-param-reassign
            bundleItem.bundle_options[1].values = cartBundleCheckboxOptionsMerge;
        });
        const shippingAddressAux = await formatAddressForConfig(true, true);
        const selected_shipping_method = {
            amount: {
                currency: state.value.selectedShippingMethod.regular_price.currency,
                value: state.value.selectedShippingMethod.regular_price.value,
            },
            carrier_code: state.value.selectedShippingMethod.carrier_code,
            carrier_title: state.value.selectedShippingMethod.carrier_title,
            method_code: state.value.selectedShippingMethod.method_code,
            method_title: state.value.selectedShippingMethod.method_title,
            discount: state.value.selectedShippingMethod.discount,
            shipping_tax: state.value.cartTotals.shipping_tax_amount,
        };
        const cartObject = {
            ...cart.value,
            email: state.value.contactInfo.email,
            prices: cart.value.prices,
            items: cart.value.items,
            shipping_addresses: [{
                    ...shippingAddressAux,
                    selected_shipping_method,
                }],
            billing_address: await formatBillingForConfig(true),
            display_shipping_addresses: [{
                    ...await formatAddressForConfig(true),
                    selected_shipping_method,
                }],
        };
        const checkoutReq = await fetch('/commerce-pay', {
            method: 'POST',
            body: JSON.stringify({
                cart: cartObject,
                disableSmsNotification: !state.value.contactInfo.updatesByText,
                allowMarketingEmails: state.value.contactInfo.marketing,
                paymentNonce,
                languageCode,
                partnerId: ((_a = PARTNER_ID_MAPS[cartType.value]) === null || _a === void 0 ? void 0 : _a.default) || VR_FLOW_PARTNER_ID,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const checkout = await checkoutReq.json();
        if (checkout.globalOrderId) {
            state.value.lastOrderPlaced = checkout.globalOrderId;
            // push event to gtm
            const { subTotal, tax, shippingPrice, total, currency, } = orderTotalsSummary.value;
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { utm_source, utm_medium, utm_campaign } = query;
            await $gtm.push({
                event: 'place_order',
                customer_id: userId,
                partner_id: ((_b = PARTNER_ID_MAPS[cartType.value]) === null || _b === void 0 ? void 0 : _b.default) || VR_FLOW_PARTNER_ID,
                transaction_id: checkout.orderId || checkout.globalOrderId,
                number_of_items: cart.value.total_quantity,
                items: cart.value.items.map((item) => ({
                    price: item.prices.row_total_including_tax.value,
                    sku: item.product.sku,
                    quantity: item.quantity,
                    discount: item.prices.total_item_discount.value,
                })),
                subTotal,
                tax,
                shippingPrice,
                total,
                currency,
                utm_source,
                utm_medium,
                utm_campaign,
            });
            clearSessionStorage(); // clean all data after the order is placed
            sessionStorage.setItem(LAST_ORDER_PLACED, checkout.globalOrderId.toString());
            return checkout;
        }
        // if we have an error we should check if is needed to restore the cart
        if (checkout && checkout.restoreCart) {
            console.log('call restore cart with cancelledOrderId');
        }
        return checkout;
    };
    const postCheckout = async (paymentNonce) => {
        try {
            return await newCheckoutReq(paymentNonce);
        }
        catch (e) {
            console.log(e);
            return e;
        }
    };
    const updateCheckoutFormErrors = (field, value) => {
        state.value.checkoutFormErrors[field] = value;
    };
    const updateBillingFormErrors = (field, value) => {
        state.value.billingFormErrors[field] = value;
    };
    const getNewEstimationArrival = ({ shippingType, store, globalCurrencyCode }) => {
        if (!shippingType)
            return null;
        const storeCode = store
            ? store.toLowerCase()
            : (globalCurrencyCode ? useStoresHelpers().getStoreFromCurrencyCode(globalCurrencyCode) : selectedStore.value);
        const method = shippingType.toLowerCase().includes('expedited') ? 'expedited' : 'standard';
        return newMatrixDeliveryTimes[storeCode][method];
    };
    const setLasOrderPlacedInSession = (globalOrderId) => {
        sessionStorage.setItem(LAST_ORDER_PLACED, globalOrderId);
    };
    return {
        iso2ToIso3,
        billingAddress,
        contactInfo,
        shippingAddress,
        latinShippingAddress,
        shippingOptions,
        selectedShippingMethod,
        getNewEstimationArrival,
        orderTotalsSummary,
        discounts,
        cartTotals,
        cartTotalsLoading,
        formatAddressForShipping,
        formatAddressForBilling,
        updateContactInfo,
        updateShippingAddress,
        updateLatinShippingAddress,
        updateBillingAddress,
        saveShippingAddressToMagento,
        saveCurrentShippingMethod,
        updateShippingOptions,
        updateCheckoutFormErrors,
        updateBillingFormErrors,
        clearSessionStorage,
        clearShippingAndBillingAddress,
        setLasOrderPlacedInSession,
        clearShippingOptions,
        isCheckoutFormValidated,
        isBillingFormValidated,
        postCheckout,
        loadCartTotals,
        selectDefaultCountry,
        saveConfigToMagento,
        getShippingOptionsWithMockData,
        setCartsTotalsLoading,
        syncCartWithStorageData,
        isSessionStorageAvailable,
    };
};
