import { defineComponent, computed, useFetch } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
export default defineComponent({
    name: 'ProductSpecifications',
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    setup(props) {
        const image = computed(() => { var _a; return ((_a = props.product) === null || _a === void 0 ? void 0 : _a.image_thumbnail) || ''; });
        const { search, content } = useContentful('product-detail');
        useFetch(async () => {
            await search({
                custom: {
                    type: 'beastComponent',
                    field: 'id',
                    value: `product-detail-${props.product.sku}`,
                },
            });
        });
        const productDetailDefaults = [
            {
                title: 'Compatible with',
                value: 'Meta Quest 3',
            },
            {
                title: 'Lens type',
                value: 'Single vision',
            },
            {
                title: 'SPH prescription range',
                value: '-9.00 to +6.00',
            },
            {
                title: 'CYL prescription range',
                value: '-4.00 to +4.00',
            },
            {
                title: 'Total power',
                value: '-9.00 to +6.00',
            },
            {
                title: 'Lens details (prescription)',
                value: '1.67 Hi-Index lens with anti-scratch and anti-reflective coating',
            },
            {
                title: 'Lens details (non-prescription)',
                value: '1.50 standard index lens with anti-scratch and anti-reflective coating',
            },
            {
                title: 'Frame details',
                value: 'Hypoallergenic, sweat-resistant plastic with matte finish and L/R engraving',
            },
        ];
        const productDetail = computed(() => { var _a, _b, _c; return ((_c = (_b = (_a = content.value) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.content) || productDetailDefaults; });
        return {
            image,
            productDetail,
        };
    },
});
