import { defineComponent, } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
export default defineComponent({
    name: 'PreOrderBanner',
    setup() {
        const { showPreOrderBanner, closePreOrderBanner } = useUiState();
        return {
            closePreOrderBanner,
            showPreOrderBanner,
        };
    },
});
