import { computed, readonly, ref, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useConfigStore } from '~/stores/config';
/**
 * Allows loading and changing currently active store.
 *
 * See the {@link UseStoreInterface} for a list of methods and values available in this composable.
 */
export function useStore() {
    const loading = ref(false);
    const error = ref({
        load: null,
        change: null,
    });
    const configStore = useConfigStore();
    const { app } = useContext();
    const load = async (customQuery = { availableStores: 'availableStores' }, customHeaders = {}) => {
        Logger.debug('useStoreFactory.load');
        error.value.load = null;
        try {
            loading.value = true;
            const { data } = await app.$vsf.$magento.api.availableStores(customQuery, customHeaders);
            configStore.$patch((state) => {
                var _a;
                state.stores = (_a = data === null || data === void 0 ? void 0 : data.availableStores) !== null && _a !== void 0 ? _a : [];
            });
        }
        catch (err) {
            error.value.load = err;
        }
        finally {
            loading.value = false;
        }
    };
    const change = (store) => {
        Logger.debug('useStoreFactory.change');
        error.value.change = null;
        try {
            loading.value = true;
            app.$vsf.$magento.config.state.setStore(store.store_code);
            app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code);
            app.$vsf.$magento.config.state.setLocale(store.store_code);
            const newStoreUrl = app.switchLocalePath(store.store_code);
            window.location.replace(newStoreUrl);
        }
        catch (err) {
            error.value.change = err;
        }
        loading.value = false;
    };
    return {
        stores: computed(() => configStore.stores),
        load,
        change,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export default useStore;
export * from './useStore';
