/* eslint-disable */
function installGladly(c, n, r, t) {
    if (!c[r]) {
        var i, d, p = [];
        d = "PROD" !== t && t ? "STAGING" === t ? "https://cdn.gladly.qa/gladly/chat-sdk/widget.js" : t : "https://cdn.gladly.com/chat-sdk/widget.js", c[r] = {
            init: function () {
                i = arguments;
                var e = {
                    then: function (t) {
                        return p.push({
                            type: "t",
                            next: t
                        }), e;
                    },
                    catch: function (t) {
                        return p.push({
                            type: "c",
                            next: t
                        }), e;
                    }
                };
                return e;
            }
        }, c.__onHelpAppHostReady__ = function (t) {
            if (delete c.__onHelpAppHostReady__, (c[r] = t).loaderCdn = d, i)
                for (var e = t.init.apply(t, i), n = 0; n < p.length; n++) {
                    var a = p[n];
                    e = "t" === a.type ? e.then(a.next) : e.catch(a.next);
                }
        },
            function () {
                try {
                    var t = n.getElementsByTagName("script")[0], e = n.createElement("script");
                    e.async = !0, e.src = d + "?q=" + (new Date).getTime(), t.parentNode.insertBefore(e, t);
                }
                catch (t) { }
            }();
    }
}
const initializeGladly = (appId, env) => {
    if (!window.Gladly) {
        installGladly(window, document, 'Gladly', env);
    }
};
const gladlyPlugin = ({ $config }, inject) => {
    const { gladlyAppId } = $config;
    initializeGladly(gladlyAppId, 'PROD');
    inject('gladlyInit', () => {
        return window.Gladly.init({
            appId: gladlyAppId,
            autoShowButton: true,
            autoShowWidget: true,
        });
    });
    inject('gladlyShow', () => {
        if (!window.Gladly) {
            installGladly(window, document, 'Gladly', 'PROD');
        }
        else {
            // only show the icon when status is AVAILABLE
            // possible statuses are:
            //  AVAILABLE: Chat agents are ready to receive new chat messages
            //  UNAVAILABLE_OFFICE_CLOSED: Chat is unavailable due to office hours
            //  UNAVAILABLE_BUSY: Chat is unavailable due to high demand
            const availablility = window.Gladly.getAvailability();
            if (availablility === 'AVAILABLE') {
                window.Gladly.show();
                // try to add notranslate class to 'gladlyChat_container' element
                const chatContainer = document.querySelector('#gladlyChat_container');
                if (chatContainer) {
                    chatContainer.classList.add('notranslate');
                }
            }
            ;
        }
        ;
    });
    inject('gladlyHide', () => {
        if (window.Gladly) {
            window.Gladly.close();
        }
        ;
    });
    inject('gladlyIsAvailable', () => {
        var _a;
        if ((_a = window === null || window === void 0 ? void 0 : window.Gladly) === null || _a === void 0 ? void 0 : _a.getAvailability) {
            const availablility = window.Gladly.getAvailability();
            if (availablility === 'AVAILABLE') {
                return true;
            }
            ;
        }
        ;
        return false;
    });
};
export default gladlyPlugin;
