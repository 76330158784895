import { Logger } from '~/helpers/logger';
export const addReviewCommand = {
    execute: async (context, params) => {
        var _a, _b, _c;
        Logger.debug('[Magento] add review params input:', JSON.stringify(params, null, 2));
        const { customQuery, ...input } = params;
        const { data } = await context.$magento.api.createProductReview(input, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
        Logger.debug('[Result]:', { data });
        return (_c = (_b = data === null || data === void 0 ? void 0 : data.createProductReview) === null || _b === void 0 ? void 0 : _b.review) !== null && _c !== void 0 ? _c : {};
    },
};
