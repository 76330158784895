import { useCustomerStore } from '~/modules/customer/stores/customer';
export const hasGraphqlAuthorizationError = (res) => {
    var _a, _b;
    return (_b = (_a = res === null || res === void 0 ? void 0 : res.errors) === null || _a === void 0 ? void 0 : _a.some((error) => { var _a; return ((_a = error === null || error === void 0 ? void 0 : error.extensions) === null || _a === void 0 ? void 0 : _a.category) === 'graphql-authorization'; })) !== null && _b !== void 0 ? _b : false;
};
const plugin = ({ $pinia, app }) => {
    const customerStore = useCustomerStore($pinia);
    if (app.$vsf.$magento.config.state.getCustomerToken()) {
        customerStore.setIsLoggedIn(true);
    }
    app.$vsf.$magento.client.interceptors.response.use((res) => {
        if (!hasGraphqlAuthorizationError(res.data)) {
            return res;
        }
        customerStore.setIsLoggedIn(false);
        app.$vsf.$magento.config.state.removeCustomerToken();
        app.$vsf.$magento.config.state.removeCartId();
        app.$vsf.$magento.config.state.setMessage({
            id: Symbol(''),
            message: app.i18n.t('You are not authorized, please log in.'),
            type: 'warning',
            icon: null,
            persist: true,
            title: null,
        });
        app.router.push(app.localePath('/'));
        return res;
    });
};
export default plugin;
