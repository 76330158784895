import { defineComponent, ref, computed, watch, useFetch, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { ZnCard } from '~/modules/b2b/components';
import { UpgradeLensDefaults } from '~/composables/useContentful/defaults';
import { usePdpFlow } from '~/modules/b2b/prescription/composables';
export default defineComponent({
    name: 'UpgradeLens',
    components: {
        ZnCard,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'upgrade-lens',
        },
    },
    setup(props, { emit }) {
        const { search, content } = useContentful('upgradeLens');
        const selectedItem = ref(null);
        useFetch(async () => {
            await search({
                custom: {
                    type: 'pdpCardGroup',
                    field: 'name',
                    value: 'upgrade-your-lens',
                },
            });
        });
        const { flowData } = usePdpFlow();
        watch(flowData, (data) => {
            if (data && data.length > 0) {
                const state = data.find((d) => d.id === props.id);
                if (state) {
                    selectedItem.value = {
                        id: state.data.select,
                    };
                }
            }
            else {
                selectedItem.value = null;
            }
        });
        const cardItems = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.options) || UpgradeLensDefaults.options; });
        const upgradeLensTitle = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || UpgradeLensDefaults.title; });
        const getCardItem = (item, index) => {
            var _a, _b;
            return ({
                ...item === null || item === void 0 ? void 0 : item.fields,
                id: index,
                subtitle: ((_a = item === null || item === void 0 ? void 0 : item.fields) === null || _a === void 0 ? void 0 : _a.price) || '',
                selected: selectedItem.value ? selectedItem.value.id === index : (_b = item === null || item === void 0 ? void 0 : item.fields) === null || _b === void 0 ? void 0 : _b.selected,
            });
        };
        const handleClick = (card) => {
            selectedItem.value = card;
            emit('group:select', { select: card.id, id: props.id });
        };
        return {
            cardItems,
            upgradeLensTitle,
            handleClick,
            getCardItem,
        };
    },
});
