import { ref, onMounted } from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import checkStoresAndCurrencyQuery from './checkStoresAndCurrency.gql';
export const useTopBar = () => {
    const { query } = useApi();
    const hasStoresToSelect = ref(null);
    const hasCurrencyToSelect = ref(null);
    onMounted(() => {
        query(checkStoresAndCurrencyQuery)
            .then((response) => {
            var _a, _b, _c, _d;
            // eslint-disable-next-line promise/always-return
            hasStoresToSelect.value = (_b = ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.availableStores.length) > 1) !== null && _b !== void 0 ? _b : false;
            // eslint-disable-next-line promise/always-return
            hasCurrencyToSelect.value = (_d = ((_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.currency.available_currency_codes.length) > 1) !== null && _d !== void 0 ? _d : false;
        })
            .catch(() => {
            hasStoresToSelect.value = false;
            hasCurrencyToSelect.value = false;
        });
    });
    return {
        hasStoresToSelect,
        hasCurrencyToSelect,
    };
};
export default useTopBar;
