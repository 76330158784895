export const defaultStore = 'us';
export const stores = {
    ca: {
        path: '/ca',
        name: 'Canada',
        flag: 'icons/langs/ca.png',
        defaultLanguage: 'en',
        defaultCurrency: 'CAD',
        multiItemCart: true,
        languages: {
            en: {
                path: '/en',
                name: 'English',
            },
            fr: {
                path: '/fr',
                name: 'Français',
            },
        },
    },
    jp: {
        path: '/jp',
        name: '日本/Japan',
        flag: 'icons/langs/jp.png',
        defaultLanguage: 'ja',
        defaultCurrency: 'JPY',
        multiItemCart: false,
        languages: {
            ja: {
                path: '/ja',
                name: '日本語',
            },
            en: {
                path: '/en',
                name: 'English',
            },
        },
    },
    gb: {
        path: '/gb',
        name: 'United Kingdom',
        flag: 'icons/langs/uk.png',
        defaultLanguage: 'gb',
        defaultCurrency: 'GBP',
        multiItemCart: true,
        languages: {
            gb: {
                path: '/gb',
                name: 'English',
            },
        },
    },
    us: {
        path: '/us',
        name: 'United States',
        flag: 'icons/langs/us.png',
        defaultLanguage: 'en',
        defaultCurrency: 'USD',
        multiItemCart: true,
        languages: {
            en: {
                path: '/en',
                name: 'English',
            },
        },
    },
    eu: {
        path: '/eu',
        name: 'Europe',
        flag: 'icons/langs/eu.png',
        defaultLanguage: 'en',
        defaultCurrency: 'EUR',
        multiItemCart: true,
        languages: {
            en: {
                path: '/en',
                name: 'English',
            },
            fr: {
                path: '/fr',
                name: 'Français',
            },
            de: {
                path: '/de',
                name: 'Deutsch',
            },
        },
        allowedCountries: [
            'AT',
            'BE',
            // 'BG', // Bulgaria
            // 'HR', // Croatia
            // 'CY', // Cyprus
            // 'CZ', // Czech Republic
            // 'DK', // Denmark
            // 'EE', // Estonia
            'FI',
            'FR',
            'DE',
            // 'GR', // Greece
            // 'HU', // Hungary
            'IE',
            // 'IT', // Italy
            // 'LV', // Latvia
            // 'LT', // Lithuania
            // 'LU', // Luxembourg
            // 'MT', // Malta
            'NL',
            'PL', // Poland
            // 'PT', // Portugal
            // 'RO', // Romania
            // 'SK', // Slovakia
            // 'SI', // Slovenia
            // 'ES', // Spain
            // 'SE', // Sweden
        ],
        isRegion: true,
    },
    au: {
        path: '/au',
        name: 'Australia',
        flag: 'icons/langs/au.png',
        defaultLanguage: 'au',
        defaultCurrency: 'AUD',
        multiItemCart: true,
        languages: {
            au: {
                path: '/au',
                name: 'English',
            },
        },
    },
};
