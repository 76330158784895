import { validateCyl, validateCylAndSph, validateLeftPd, validateOdAdd, validateOdCylAndAxis, validateOsAdd, validateOsCylAndAxis, validatePd, validateRightPd, validatePrismLensType, validatePrismOdNoHorDir, validatePrismOdNoVerDir, validatePrismOsNoHorDir, validatePrismOsNoVerDir, validatePrismOdNoHorValue, validatePrismOdNoVerValue, validatePrismOsNoHorValue, validatePrismOsNoVerValue, validatePrismSphCyl, validatePrismOdValueRange, validatePrismOsValueRange, isPrismEnabled, validateBifocalAndPalWithOutAdd, validateSphCylRanges, } from '~/modules/b2b/libs/validators/prescriptionValidators';
import { validateBifocal, validateProgressive, validateSingleVision } from '~/modules/b2b/libs/validators/lensValidators';
const prescriptionFormRules = (form, oneNumber, range) => {
    const validateFnWrapper = (fn, validateType) => {
        const res = fn(form.values, range);
        let type = 'success';
        let message = [];
        if (!res.success) {
            type = 'error';
            message = [validateType, ...res.errorMessages];
        }
        else if (res.warning) {
            // ignore warning
            type = 'warning';
            message = [validateType, ...res.warningMessages];
        }
        return {
            type,
            message,
        };
    };
    const pdValidator = [
        {
            // triggerType: "onSubmit",
            validator: () => {
                if (oneNumber.value !== '1') {
                    return true;
                }
                return validateFnWrapper(validatePd, 'pdError');
            },
        },
    ];
    const pdLeftValidator = [
        {
            validator: () => {
                if (oneNumber.value === '1') {
                    return true;
                }
                return validateFnWrapper(validateLeftPd, 'pdError');
            },
        },
        {
            validator: () => {
                if (!form.values.od.pd || oneNumber.value === '1') {
                    return true;
                }
                return validateFnWrapper(validatePd, 'pdError');
            },
        },
    ];
    const pdRightValidator = [
        {
            validator: () => {
                if (oneNumber.value === '1') {
                    return true;
                }
                return validateFnWrapper(validateRightPd, 'pdError');
            },
        },
        {
            validator: (val, error, { form }) => {
                if (!form.values.os.pd) {
                    return true;
                }
                return validateFnWrapper(validatePd, 'pdError');
            },
        },
    ];
    const cylAndSphRange = {
        triggerType: 'onSubmit',
        validator: () => validateFnWrapper(validateSphCylRanges, 'cylAndSphRanges'),
    };
    const cylAndSph = {
        triggerType: 'onSubmit',
        validator: () => validateFnWrapper(validateCylAndSph, 'cylAndSph'),
    };
    const prismCylAndSph = {
        triggerType: 'onSubmit',
        validator: () => validateFnWrapper(validatePrismSphCyl, 'cylAndSph'),
    };
    const cyl = {
        triggerType: 'onSubmit',
        validator: () => validateFnWrapper(validateCyl, 'cyl'),
    };
    const odCylAndAxis = {
        validator: () => validateFnWrapper(validateOdCylAndAxis, 'odCylAndOdSphAndOdAxis'),
    };
    const osCylAndAxis = {
        validator: () => validateFnWrapper(validateOsCylAndAxis, 'osCylAndOsSphAndOsAxis'),
    };
    const odAdd = {
        validator: () => validateFnWrapper(validateOdAdd, 'add'),
    };
    const osAdd = {
        validator: () => validateFnWrapper(validateOsAdd, 'add'),
    };
    const bifocalAndPal = {
        triggerType: 'onSubmit',
        validator: () => validateFnWrapper(validateBifocalAndPalWithOutAdd, 'osCylAndOsSphAndOsAxis'),
    };
    const prismOdNoHorDir = {
        validator: () => validateFnWrapper(validatePrismOdNoHorDir, 'odHorAndBaseDirection'),
    };
    const prismOdNoVerDir = {
        validator: () => validateFnWrapper(validatePrismOdNoVerDir, 'odVerAndBaseDirection'),
    };
    const prismOsNoHorDir = {
        validator: () => validateFnWrapper(validatePrismOsNoHorDir, 'osHorAndBaseDirection'),
    };
    const prismOsNoVerDir = {
        validator: () => validateFnWrapper(validatePrismOsNoVerDir, 'osVerAndBaseDirection'),
    };
    const prismOdNoHorValue = {
        validator: () => validateFnWrapper(validatePrismOdNoHorValue, 'odHorAndBaseDirection'),
    };
    const prismOdNoVerValue = {
        validator: () => validateFnWrapper(validatePrismOdNoVerValue, 'odVerAndBaseDirection'),
    };
    const prismOsNoHorValue = {
        validator: () => validateFnWrapper(validatePrismOsNoHorValue, 'osHorAndBaseDirection'),
    };
    const prismOsNoVerValue = {
        validator: () => validateFnWrapper(validatePrismOsNoVerValue, 'osVerAndBaseDirection'),
    };
    const prismOdValueRange = {
        validator: () => validateFnWrapper(validatePrismOdValueRange, 'odHorAndVerAndBaseDirection'),
    };
    const prismOsValueRange = {
        validator: () => validateFnWrapper(validatePrismOsValueRange, 'osHorAndVerAndBaseDirection'),
    };
    const odSphValidator = [cylAndSph, prismCylAndSph, bifocalAndPal];
    const odSphValidatorVoucher = [cylAndSphRange, cylAndSph, prismCylAndSph, bifocalAndPal];
    const osSphValidator = [cylAndSph, prismCylAndSph, bifocalAndPal];
    const osSphValidatorVoucher = [cylAndSphRange, cylAndSph, prismCylAndSph, bifocalAndPal];
    const odCylValidator = [cylAndSph, prismCylAndSph, cyl, bifocalAndPal];
    const odCylValidatorVoucher = [cylAndSphRange, cylAndSph, prismCylAndSph, cyl, bifocalAndPal];
    const osCylValidator = [cylAndSph, prismCylAndSph, cyl, bifocalAndPal];
    const osCylValidatorVoucher = [cylAndSphRange, cylAndSph, prismCylAndSph, cyl, bifocalAndPal];
    const odAxisValidator = [odCylAndAxis];
    const osAxisValidator = [osCylAndAxis];
    const odAddValidator = [odAdd];
    const osAddValidator = [osAdd];
    const odHorValidator = [prismOdNoHorValue, prismOdValueRange];
    const odHorDirValidator = [prismOdNoHorDir];
    const odVerValidator = [prismOdNoVerValue, prismOdValueRange];
    const odVerDirValidator = [prismOdNoVerDir];
    const osHorValidator = [prismOsNoHorValue, prismOsValueRange];
    const osHorDirValidator = [prismOsNoHorDir];
    const osVerValidator = [prismOsNoVerValue, prismOsValueRange];
    const osVerDirValidator = [prismOsNoVerDir];
    const globalValidator = (prescription) => {
        const lensType = prescription.lensType.toLowerCase();
        const lensValidatorMap = {
            SingleVision: validateSingleVision,
            Bifocal: validateBifocal,
            Progressive: validateProgressive,
        };
        const res = [];
        const validateFn = lensValidatorMap[lensType];
        if (validateFn) {
            const lensTypeRes = validateFn(prescription);
            res.push(lensTypeRes.errorMessage);
        }
        const prismLensTypeRes = validatePrismLensType(prescription);
        if (!prismLensTypeRes.success) {
            res.push(...prismLensTypeRes.errorMessages);
        }
        return res;
    };
    const validValue = (value) => Number(value) !== 0;
    const hasError = (form, field) => form.errors.find(({ address }) => address === field);
    const dualPdReactions = () => {
        if (validValue(form.values.os.pd)) {
            form.validate('os.pd');
        }
        if (validValue(form.values.od.pd)) {
            form.validate('od.pd');
        }
    };
    const sphReactions = () => {
        if (validValue(form.values.os.cylinders) || hasError(form, 'os.cylinders') || isPrismEnabled(form.values)) {
            form.validate('os.cylinders');
        }
        if (validValue(form.values.od.cylinders) || hasError(form, 'od.cylinders') || isPrismEnabled(form.values)) {
            form.validate('od.cylinders');
        }
        if (validValue(form.values.os.sphere) || hasError(form, 'os.sphere') || isPrismEnabled(form.values)) {
            form.validate('os.sphere');
        }
        if (validValue(form.values.od.sphere) || hasError(form, 'od.sphere') || isPrismEnabled(form.values)) {
            form.validate('od.sphere');
        }
    };
    const odCylReactions = async () => {
        if (validValue(form.values.od.cylinders) || hasError(form, 'od.cylinders') || isPrismEnabled(form.values)) {
            form.validate('od.cylinders');
        }
        if (validValue(form.values.os.cylinders) || hasError(form, 'os.cylinders') || isPrismEnabled(form.values)) {
            form.validate('os.cylinders');
        }
        if (validValue(form.values.os.sphere) || hasError(form, 'os.sphere') || isPrismEnabled(form.values)) {
            form.validate('os.sphere');
        }
        if (validValue(form.values.od.sphere) || hasError(form, 'od.sphere') || isPrismEnabled(form.values)) {
            form.validate('od.sphere');
        }
        setTimeout(() => {
            // waiting disable to be false
            form.validate('od.axis');
        });
    };
    const osCylReactions = () => {
        if (validValue(form.values.os.cylinders) || hasError(form, 'os.cylinders') || isPrismEnabled(form.values)) {
            form.validate('os.cylinders');
        }
        if (validValue(form.values.od.cylinders) || hasError(form, 'od.cylinders') || isPrismEnabled(form.values)) {
            form.validate('od.cylinders');
        }
        if (validValue(form.values.os.sphere) || hasError(form, 'os.sphere') || isPrismEnabled(form.values)) {
            form.validate('os.sphere');
        }
        if (validValue(form.values.od.sphere) || hasError(form, 'od.sphere') || isPrismEnabled(form.values)) {
            form.validate('od.sphere');
        }
        setTimeout(() => {
            // waiting disable to be false
            form.validate('os.axis');
        });
    };
    const osAxisReactions = () => {
        if (validValue(form.values.os.axis) || hasError(form, 'os.axis')) {
            form.validate('os.axis');
        }
    };
    const odAxisReactions = () => {
        if (validValue(form.values.od.axis) || hasError(form, 'od.axis')) {
            form.validate('od.axis');
        }
    };
    const odHorReactions = () => {
        setTimeout(() => {
            form.validate('od.baseHor');
        });
        if (validValue(form.values.od.prismVer) || hasError(form, 'od.prismVer')) {
            form.validate('od.prismVer');
        }
        form.validate('os.cylinders');
        form.validate('od.cylinders');
        form.validate('os.sphere');
        form.validate('od.sphere');
    };
    const odVerReactions = () => {
        setTimeout(() => {
            form.validate('od.baseVer');
        });
        if (validValue(form.values.od.prismHor) || hasError(form, 'od.prismHor')) {
            form.validate('od.prismHor');
        }
        form.validate('os.cylinders');
        form.validate('od.cylinders');
        form.validate('os.sphere');
        form.validate('od.sphere');
    };
    const osHorReactions = () => {
        setTimeout(() => {
            form.validate('os.baseHor');
        });
        if (validValue(form.values.os.prismVer) || hasError(form, 'os.prismVer')) {
            form.validate('os.prismVer');
        }
        form.validate('os.cylinders');
        form.validate('od.cylinders');
        form.validate('os.sphere');
        form.validate('od.sphere');
    };
    const osVerReactions = () => {
        setTimeout(() => {
            form.validate('os.baseVer');
        });
        if (validValue(form.values.os.prismHor) || hasError(form, 'os.prismHor')) {
            form.validate('os.prismHor');
        }
        form.validate('os.cylinders');
        form.validate('od.cylinders');
        form.validate('os.sphere');
        form.validate('od.sphere');
    };
    return {
        validator: {
            pdValidator,
            pdLeftValidator,
            pdRightValidator,
            odSphValidator,
            osSphValidator,
            odCylValidator,
            osCylValidator,
            odAxisValidator,
            osAxisValidator,
            odAddValidator,
            osAddValidator,
            odHorValidator,
            odHorDirValidator,
            odVerValidator,
            odVerDirValidator,
            osHorValidator,
            osHorDirValidator,
            osVerValidator,
            osVerDirValidator,
            odSphValidatorVoucher,
            osSphValidatorVoucher,
            odCylValidatorVoucher,
            osCylValidatorVoucher,
            globalValidator,
        },
        reactions: {
            dualPdReactions,
            sphReactions,
            odCylReactions,
            osCylReactions,
            odHorReactions,
            odVerReactions,
            osHorReactions,
            osVerReactions,
            osAxisReactions,
            odAxisReactions,
        },
    };
};
export default prescriptionFormRules;
