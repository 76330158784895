import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useUser } from '~/modules/customer/composables/useUser';
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '~/modules/customer/helpers/userAddressManipulator';
import mask from '~/composables/utils/mask';
import { createCustomerAddressCommand } from './commands/createCustomerAddressCommand';
import { deleteCustomerAddressCommand } from './commands/deleteCustomerAddressCommand';
import { updateCustomerAddressCommand } from './commands/updateCustomerAddressCommand';
/**
 * Allows loading and manipulating addresses of the current user.
 *
 * See the {@link UseUserAddressInterface} for a list of methods and values available in this composable.
 */
export function useUserAddress() {
    const loading = ref(false);
    const shipping = ref({});
    const error = ref({
        addAddress: null,
        deleteAddress: null,
        updateAddress: null,
        load: null,
        setDefaultAddress: null,
    });
    const { user, load: loadUser } = useUser();
    const context = useContext();
    const addAddress = async ({ address, customQuery, customHeaders }) => {
        Logger.debug('useUserAddress.addAddress', mask(address));
        let result = {};
        try {
            loading.value = true;
            const customerAddressInput = transformUserCreateAddressInput({
                address,
                shipping: shipping.value,
            });
            result = await createCustomerAddressCommand.execute(context, customerAddressInput, customQuery, customHeaders);
            error.value.addAddress = null;
        }
        catch (err) {
            error.value.addAddress = err;
            Logger.error('useUserAddress/addAddress', err);
        }
        finally {
            loading.value = false;
        }
        Logger.debug('[Result]:', { result: mask(result) });
        return result;
    };
    const deleteAddress = async (address, customQuery, customHeaders) => {
        Logger.debug('useUserAddress.deleteAddress', address);
        let result = {};
        try {
            loading.value = true;
            result = await deleteCustomerAddressCommand.execute(context, address, customQuery, customHeaders);
            error.value.deleteAddress = null;
        }
        catch (err) {
            error.value.deleteAddress = err;
            Logger.error('useUserAddress/deleteAddress', err);
        }
        finally {
            loading.value = false;
        }
        Logger.debug('[Result]:', { result: mask(result) });
        return result;
    };
    const updateAddress = async ({ address, customQuery, customHeaders }) => {
        Logger.debug('useUserAddress.updateAddress', mask(address));
        let result = {};
        try {
            loading.value = true;
            const customerAddressInput = transformUserUpdateAddressInput({
                address,
                shipping: shipping.value,
            });
            result = await updateCustomerAddressCommand.execute(context, customerAddressInput, customQuery, customHeaders);
            error.value.updateAddress = null;
        }
        catch (err) {
            error.value.updateAddress = err;
            Logger.error('useUserAddress/updateAddress', err);
        }
        finally {
            loading.value = false;
        }
        Logger.debug('[Result]:', { result: mask(result) });
        return result;
    };
    const load = async (force = false) => {
        var _a;
        Logger.debug('useUserAddress.load');
        try {
            loading.value = true;
            if (force || !((_a = user === null || user === void 0 ? void 0 : user.value) === null || _a === void 0 ? void 0 : _a.id)) {
                await loadUser();
            }
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useUserAddress/load', err);
        }
        finally {
            loading.value = false;
        }
        return user === null || user === void 0 ? void 0 : user.value;
    };
    const setDefaultAddress = async ({ address, customQuery, customHeaders }) => {
        Logger.debug('useUserAddress.setDefaultAddress', mask(address));
        let result = {};
        try {
            loading.value = true;
            const updateAddressInput = transformUserUpdateAddressInput({
                address,
                shipping: shipping.value,
                customQuery,
                customHeaders,
            });
            result = await updateCustomerAddressCommand.execute(context, updateAddressInput, customQuery, customHeaders);
            error.value.setDefaultAddress = null;
        }
        catch (err) {
            error.value.setDefaultAddress = err;
            Logger.error('useUserAddress/setDefaultAddress', err);
        }
        finally {
            loading.value = false;
        }
        Logger.debug('[Result]:', { result: mask(result) });
        return result;
    };
    return {
        addAddress,
        deleteAddress,
        updateAddress,
        load,
        setDefaultAddress,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export default useUserAddress;
export * from './useUserAddress';
