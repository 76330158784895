import { getStyledConsolePrefix } from './style';
const partial = (fn, ...args) => (...laterArgs) => fn(...args, ...laterArgs);
export const getLoggerWithVerbosity = (verbosity) => {
    const noOperation = () => { };
    const matches = (verbosities) => verbosities.includes(verbosity);
    return {
        debug: matches(['debug'])
            ? partial(console.debug, ...getStyledConsolePrefix('debug'))
            : noOperation,
        info: matches(['info', 'debug'])
            ? partial(console.info, ...getStyledConsolePrefix('info'))
            : noOperation,
        warn: matches(['info', 'warn', 'error'])
            ? partial(console.warn, ...getStyledConsolePrefix('warn'))
            : noOperation,
        error: matches(['info', 'warn', 'debug', 'error'])
            ? partial(console.error, ...getStyledConsolePrefix('error'))
            : noOperation,
    };
};
export const getVerbosity = (nodeEnv) => {
    var _a;
    const defaultModes = {
        // Test
        test: 'none',
        // Development
        dev: 'debug',
        development: 'debug',
        // Production
        prod: 'error',
        production: 'error',
    };
    return (_a = defaultModes[nodeEnv]) !== null && _a !== void 0 ? _a : 'warn';
};
