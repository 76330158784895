import _ from 'lodash';
import { combineSphAndCyl, hasValue } from './helpers/basicPrescription';
import { checkPrism } from './helpers/prism';
/*
 * Library description:
 * The libaray contains a bunch of functions to check if the client
 * provided prescription is valid or not.
 * The input type should be Prescrition and the output should be Result.
 * The output contains the following:
 * 1. success: bool to indicate if the check is valid or not.
 * 2. warning: some validation will have success as true, but will give
 *     warning to client to double confirm the result before they submit
 *     the final result.
 * 3. errorMessages: contains the detailed error messages that should presented.
 * 4. warningMessages: contains the detailed warning messages that should be noticed.
 */
// ERROR MESSAGES
export const ERROR_NO_PRESCRIPTION = 'Your prescription is required to continue';
const ERROR_DIFFERENT_SIGNS = 'The CYL values should be either both positive (+) or both negative (-).';
const ERROR_CYL_OUT_OF_RANGE = 'We support CYL values between -4.00 and +4.00';
export const ERROR_NO_OD_AXIS = 'A CYL value always needs and Axis value. Please add an Axis value or change CYL to 0.';
const ERROR_NO_OD_CYL = 'We recognized an Axis value but no CYL value. Please check your prescription and fill in a CYL value or change Axis to 0.';
export const ERROR_NO_OS_AXIS = 'A CYL value always needs and Axis value. Please add an Axis value or change CYL to 0.';
const ERROR_NO_OS_CYL = 'We recognized an Axis value but no CYL value. Please check your prescription and fill in a CYL value or change Axis to 0.';
const ERROR_PRISM_ONLY_FOR_SINGLE_VISION = 'Only single vision is allowed to have prism';
const ERROR_OD_PRISM_NO_HOR_DIR = 'OD Prism has horizontal value but no base direction';
export const ERROR_OD_PRISM_NO_VER_DIR = 'OD Prism has vertical value but no base direction';
const ERROR_OS_PRISM_NO_VER_DIR = 'OS Prism has vertical value but no base direction';
const ERROR_OS_PRISM_NO_HOR_DIR = 'OS Prism has horizontal value but no base direction';
const ERROR_OD_PRISM_NO_HOR_VAL = 'OD Prism has horizontal direction but no value';
const ERROR_OD_PRISM_NO_VER_VAL = 'OD Prism has vertical direction but no value';
const ERROR_OS_PRISM_NO_HOR_VAL = 'OS Prism has horizontal direction but no value';
const ERROR_OS_PRISM_NO_VER_VAL = 'OS Prism has vertical direction but no value';
const ERROR_PRISM_OUT_OF_RANGE = 'The prism value should between 0.00 and 5.00';
export const ERROR_P1_P2_OUT_OF_RANGE = 'Prism value should satisfy the following: P1^2 + P2^2 <= 25.0, where P1 is Horizontal value and P2 is Vertical value';
const ERROR_SPH_CYL_OUT_OF_RANGE = 'Your prescription is too strong for this frame.';
const ERROR_JOINT_VALUE_OUT_OF_RANGE = 'The combination of SPH and CYL must between -12.00~6:00 to have prism';
export const ERROR_PD_REQUIRED = 'PD Value is required';
export const ERROR_SINGLE_PD_OUT_OF_RANGE_FOR_BIO_AND_PAL = 'Bifocal and Progressive lens type are not supported for Single PD when PD is lower than 50mm';
export const ERROR_DOUBLE_PD_OUT_OF_RANGE_FOR_BIO_AND_PAL = 'One of the PD values is too small. The PD value should be at least 25 for Bifocal or Progressive lens';
const ERROR_PD_DIFFERENCE_TOO_BIG_NO_RESULT = 'Your OD and OS PD difference is too big. No lens type is supported for the PD value';
const ERROR_PD_DIFFERENCE_TOO_BIG_ONLY_SINGLE_VISION = 'Your OD and OS PD difference is too big. Bifocal and Progressive lens type does not support such PD values, please select Single Vision as the lens type';
const ERROR_NV_ADD_REQUIRED = 'The NV Add value is required for Bifocal or Progressive lens';
export const ERROR_SHARED_DIFFERENCE_TOO_BIG = 'Please confirm your prescription. If the prescription is correct, your prescription cannot be manufactured in a Bifocal or Progressive. '
    + 'Please choose Single vision as this is the only option available';
export const ERROR_NV_ADD_NOT_ALLOWED_FOR_SINGLE_VISION = 'The NV Add value should be 0 for single vision';
// WARNING MESSAGES
const WARNING_FOR_BOTH_CYL_SPH = 'OD CYL and OS CYL are both +/-2.5 or +/-5.0. If the values are correct, you can confirm to save the prescription';
const WARNING_FOR_SINGLE_CYL_OR_SPH = 'OD CYL and OS CYL are significantly different. If the values are correct, you can confirm to save the prescription';
// MaxPd MESSAGES
const MAX_PD_MESSAGE = 'Your entered PD is too large. You will be shown XL frame sizes.';
// CONST VALUES IN USE
const CYLINDERS_RANGE = {
    MIN: -4,
    MAX: 4,
};
const COMBINATION_RANGE = {
    MIN: -9,
    MAX: 6,
};
const PRISM_DIRECTION = {
    IN: 'IN',
    OUT: 'OUT',
    UP: 'UP',
    DOWN: 'DOWN',
};
const PRISM_VALUE_RANGE = {
    MIN: 0,
    MAX: 5,
};
const PD_RANGE = {
    NOT_SINGLE_PD: 0,
    MIN: 0,
    MAX: 50,
    ABS_SINGLE_ONLY: 3,
    ABS_NO_RESULT: 5,
};
const MAX_PD = 77;
const MAX_SPH_DIFFERENCE = 5;
const MAX_CYL_DIFFERENCE = 5;
const MAX_JOINT_DIFFERENCE = 5;
// This need to satisfy the following:
// P1^2 + P2^2 <= 25.0
// P1: Prism Horizontal Value
// P2: Prism Vertical Value
const PRISM_DIRECTION_VALUE_MAX = 25;
// lens types
const SINGLE_VISION = new Set(['singlevision', 'single vision']);
const BIFOCAL = new Set(['bifocal', 'bifocals']);
const PAL = new Set(['progressive']);
const FRAMELESS = new Set(['frameless']);
export function validateSphCylRanges(prescription, range) {
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // od and os cyl
    const odCyl = Number(prescription.od.cylinders);
    const osCyl = Number(prescription.os.cylinders);
    const odSph = Number(prescription.od.sphere);
    const osSph = Number(prescription.os.sphere);
    const ranges = range || {
        COMBINATION_RANGE,
        CYLINDERS_RANGE,
    };
    // -- check if sph values out of range --
    if ((odSph > ranges.COMBINATION_RANGE.MAX || osSph > ranges.COMBINATION_RANGE.MAX)
        || (odSph < ranges.COMBINATION_RANGE.MIN || osSph < ranges.COMBINATION_RANGE.MIN)
        || (odCyl > ranges.CYLINDERS_RANGE.MAX || osCyl > ranges.CYLINDERS_RANGE.MAX)
        || (odCyl < ranges.CYLINDERS_RANGE.MIN || osCyl < ranges.CYLINDERS_RANGE.MIN)) {
        result.success = false;
        result.errorMessages.push('Range error');
    }
    return result;
}
/*
 * CYL and SPH Rules:
 * at least one side of prescription value is required.
 */
export function validateCylAndSph(prescription, range) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // od and os cyl
    const odCyl = Number(prescription.od.cylinders);
    const osCyl = Number(prescription.os.cylinders);
    const odSph = Number(prescription.od.sphere);
    const osSph = Number(prescription.os.sphere);
    if (odCyl === 0 && osCyl === 0 && odSph === 0 && osSph === 0) {
        result.success = false;
        result.errorMessages.push(ERROR_NO_PRESCRIPTION);
    }
    // -- check if sph values out of range --
    const ranges = range || {
        COMBINATION_RANGE,
        CYLINDERS_RANGE,
    };
    if ((odSph > ranges.COMBINATION_RANGE.MAX || osSph > ranges.COMBINATION_RANGE.MAX)
        || (odSph < ranges.COMBINATION_RANGE.MIN || osSph < ranges.COMBINATION_RANGE.MIN)) {
        result.success = false;
        result.errorMessages.push(ERROR_SPH_CYL_OUT_OF_RANGE);
    }
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const maxJoint = Math.max(jointOd, jointOs);
    const minJoint = Math.min(jointOd, jointOs);
    if (maxJoint > ranges.COMBINATION_RANGE.MAX || minJoint < ranges.COMBINATION_RANGE.MIN) {
        result.success = false;
        result.errorMessages.push(ERROR_SPH_CYL_OUT_OF_RANGE);
    }
    return result;
}
/*
 * CYL Rules:
 * 1. OD CYL and OS CYL should not have different signs.
 * 2. CYL range is -6.00～+6.00. Refer to Each Lens Type
 * ----- Rule 3 was changed ---------
 * 3. When abs(OD CYL) and abs(OS CYL) are both one of the follows（2.50  5.00）,
 *    or one abs value is one of the follows（2.50 5.00）, and the other abs value is less than half,
 *    system should pop up the prompt to let customer confirm the prescription.
 * ---- This is the new updated rule 3 ----
 * 3. When the absolute (OD CYL) or absolute (OS CYL) is more than 2.50D,
 *    AND one of the absolute CYL values is less than half of the other,
 *    THEN the system should pop up a prompt to let the customer confirm the prescription.
 */
export function validateCyl(prescription, range) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // od and os cyl
    const odCyl = Number(prescription.od.cylinders);
    const osCyl = Number(prescription.os.cylinders);
    const absOdCyl = Math.abs(odCyl);
    const absOsCyl = Math.abs(osCyl);
    // -- check #1 --
    if (odCyl === 0 && osCyl === 0) {
        return result;
    }
    if ((osCyl > 0 && odCyl < 0) || (osCyl < 0 && odCyl > 0)) {
        result.success = false;
        result.errorMessages.push(ERROR_DIFFERENT_SIGNS);
    }
    const ranges = range || {
        COMBINATION_RANGE,
        CYLINDERS_RANGE,
    };
    // -- check #2 --
    if (osCyl < ranges.CYLINDERS_RANGE.MIN
        || osCyl > ranges.CYLINDERS_RANGE.MAX
        || odCyl < ranges.CYLINDERS_RANGE.MIN
        || odCyl > ranges.CYLINDERS_RANGE.MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_CYL_OUT_OF_RANGE);
    }
    // -- check #3 --
    // if ((absOdCyl === 2.5 || absOdCyl === 5) && (absOsCyl === 2.5 || absOsCyl === 5)) {
    //   result.warning = true;
    //   result.warningMessages.push(WARNING_FOR_BOTH_CYL_SPH);
    // } else if (absOdCyl % 2.5 === 0 && absOdCyl !== 0) {
    //   if (absOdCyl / 2 > absOsCyl) {
    //     result.warning = true;
    //     result.warningMessages.push(WARNING_FOR_SINGLE_CYL_OR_SPH);
    //   }
    // } else if (absOsCyl % 2.5 === 0 && absOsCyl !== 0 && absOsCyl / 2 > absOdCyl) {
    //   result.warning = true;
    //   result.warningMessages.push(WARNING_FOR_SINGLE_CYL_OR_SPH);
    // }
    if ((absOdCyl >= 2.5 || absOsCyl >= 2.5) && (absOsCyl < absOdCyl / 2 || absOdCyl < absOsCyl / 2)) {
        result.warning = true;
        result.warningMessages.push(WARNING_FOR_SINGLE_CYL_OR_SPH);
    }
    return result;
}
/*
 * CYL and AXIS Rules:
 * CYL and AXIS should be filled all together.
 * When CYL does not equal to 0 and null, relevant AXIS should not equal to 0 and null either.
 * When AXIS does not equal to 0 and null, relevant CYL should not equal to 0 and null either.
 */
export function validateOdCylAndAxis(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // od cyl and axis
    const odCyl = Number(prescription.od.cylinders);
    const odAxis = Number(prescription.od.axis);
    // -- check OD --
    if (odCyl !== 0 && odAxis === 0) {
        result.success = false;
        result.errorMessages.push(ERROR_NO_OD_AXIS);
    }
    else if (odCyl === 0 && odAxis !== 0) {
        result.success = false;
        result.errorMessages.push(ERROR_NO_OD_CYL);
    }
    return result;
}
export function validateOsCylAndAxis(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // os cyl and axis
    const osCyl = Number(prescription.os.cylinders);
    const osAxis = Number(prescription.os.axis);
    // -- check OS --
    if (osCyl !== 0 && osAxis === 0) {
        result.success = false;
        result.errorMessages.push(ERROR_NO_OS_AXIS);
    }
    else if (osCyl === 0 && osAxis !== 0) {
        result.success = false;
        result.errorMessages.push(ERROR_NO_OS_CYL);
    }
    return result;
}
export function validateCylAndAxis(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const odRes = validateOdCylAndAxis(prescription);
    const osRes = validateOsCylAndAxis(prescription);
    result.success = odRes.success && osRes.success;
    result.errorMessages = [...odRes.errorMessages, ...osRes.errorMessages];
    return result;
}
// --------------------------------------------------------------------------------------------------------------
export const isPrismEnabled = (prescription) => {
    // check if prism is enabled
    // od values
    const odPrismValueHorizontal = Number(prescription.od.prismHor);
    const odPrismValueVertical = Number(prescription.od.prismVer);
    // os values
    const osPrismValueHorizontal = Number(prescription.os.prismHor);
    const osPrismValueVertical = Number(prescription.os.prismVer);
    const { prismEnabled } = checkPrism(odPrismValueHorizontal, odPrismValueVertical, osPrismValueHorizontal, osPrismValueVertical);
    return prismEnabled;
};
export function validatePrismLensType(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    const lensType = prescription.lensType.toLowerCase();
    if (!SINGLE_VISION.has(lensType)) {
        result.success = false;
        result.errorMessages.push(`${ERROR_PRISM_ONLY_FOR_SINGLE_VISION}, you have ${lensType}`);
        return result;
    }
    return result;
}
export function validatePrismOdNoHorDir(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const odPrismValueHorizontal = Number(prescription.od.prismHor);
    const odPrismDirectionHorizontal = prescription.od.baseHor;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    if (odPrismValueHorizontal > 0 && odPrismDirectionHorizontal !== PRISM_DIRECTION.IN && odPrismDirectionHorizontal !== PRISM_DIRECTION.OUT) {
        result.success = false;
        result.errorMessages.push(ERROR_OD_PRISM_NO_HOR_DIR);
    }
    return result;
}
export function validatePrismOdNoVerDir(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const odPrismValueVertical = Number(prescription.od.prismVer);
    const odPrismDirectionVertical = prescription.od.baseVer;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    if (odPrismValueVertical > 0 && odPrismDirectionVertical != PRISM_DIRECTION.UP && odPrismDirectionVertical != PRISM_DIRECTION.DOWN) {
        result.success = false;
        result.errorMessages.push(ERROR_OD_PRISM_NO_VER_DIR);
    }
    return result;
}
export function validatePrismOsNoHorDir(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const osPrismValueHorizontal = Number(prescription.os.prismHor);
    const osPrismDirectionHorizontal = prescription.os.baseHor;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    if (osPrismValueHorizontal > 0 && osPrismDirectionHorizontal != PRISM_DIRECTION.IN && osPrismDirectionHorizontal != PRISM_DIRECTION.OUT) {
        result.success = false;
        result.errorMessages.push(ERROR_OS_PRISM_NO_HOR_DIR);
    }
    return result;
}
export function validatePrismOsNoVerDir(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const osPrismValueVertical = Number(prescription.os.prismVer);
    const osPrismDirectionVertical = prescription.os.baseVer;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    if (osPrismValueVertical > 0 && osPrismDirectionVertical != PRISM_DIRECTION.UP && osPrismDirectionVertical != PRISM_DIRECTION.DOWN) {
        result.success = false;
        result.errorMessages.push(ERROR_OS_PRISM_NO_VER_DIR);
    }
    return result;
}
export function validatePrismOdNoHorValue(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const odPrismValueHorizontal = Number(prescription.od.prismHor);
    const odPrismDirectionHorizontal = prescription.od.baseHor;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    if (odPrismValueHorizontal < PRISM_VALUE_RANGE.MIN || odPrismValueHorizontal > PRISM_VALUE_RANGE.MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_PRISM_OUT_OF_RANGE);
    }
    if (odPrismValueHorizontal === 0 && hasValue(odPrismDirectionHorizontal)) {
        result.success = false;
        result.errorMessages.push(ERROR_OD_PRISM_NO_HOR_VAL);
    }
    return result;
}
export function validatePrismOdNoVerValue(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const odPrismValueVertical = Number(prescription.od.prismVer);
    const odPrismDirectionVertical = prescription.od.baseVer;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    if (odPrismValueVertical < PRISM_VALUE_RANGE.MIN || odPrismValueVertical > PRISM_VALUE_RANGE.MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_PRISM_OUT_OF_RANGE);
    }
    if (odPrismValueVertical === 0 && hasValue(odPrismDirectionVertical)) {
        result.success = false;
        result.errorMessages.push(ERROR_OD_PRISM_NO_VER_VAL);
    }
    return result;
}
export function validatePrismOsNoHorValue(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const osPrismValueHorizontal = Number(prescription.os.prismHor);
    const osPrismDirectionHorizontal = prescription.os.baseHor;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    if (osPrismValueHorizontal < PRISM_VALUE_RANGE.MIN || osPrismValueHorizontal > PRISM_VALUE_RANGE.MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_PRISM_OUT_OF_RANGE);
    }
    if (osPrismValueHorizontal === 0 && hasValue(osPrismDirectionHorizontal)) {
        result.success = false;
        result.errorMessages.push(ERROR_OS_PRISM_NO_HOR_VAL);
    }
    return result;
}
export function validatePrismOsNoVerValue(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const osPrismValueVertical = Number(prescription.os.prismVer);
    const osPrismDirectionVertical = prescription.os.baseVer;
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    if (osPrismValueVertical < PRISM_VALUE_RANGE.MIN || osPrismValueVertical > PRISM_VALUE_RANGE.MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_PRISM_OUT_OF_RANGE);
    }
    if (osPrismValueVertical === 0 && hasValue(osPrismDirectionVertical)) {
        result.success = false;
        result.errorMessages.push(ERROR_OS_PRISM_NO_VER_VAL);
    }
    return result;
}
export function validatePrismSphCyl(prescription, range) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    const ranges = range || {
        COMBINATION_RANGE,
        CYLINDERS_RANGE,
    };
    // -- check #5 --
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const maxJoint = Math.max(jointOd, jointOs);
    const minJoint = Math.min(jointOd, jointOs);
    if (maxJoint > ranges.COMBINATION_RANGE.MAX || minJoint < ranges.COMBINATION_RANGE.MIN) {
        result.success = false;
        result.errorMessages.push(ERROR_JOINT_VALUE_OUT_OF_RANGE);
    }
    return result;
}
export function validatePrismOdValueRange(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const odPrismValueHorizontal = Number(prescription.od.prismHor);
    const odPrismValueVertical = Number(prescription.od.prismVer);
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    // -- check #6 --
    if (odPrismValueHorizontal ** 2 + odPrismValueVertical ** 2 > PRISM_DIRECTION_VALUE_MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_P1_P2_OUT_OF_RANGE);
    }
    return result;
}
export function validatePrismOsValueRange(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    const osPrismValueHorizontal = Number(prescription.os.prismHor);
    const osPrismValueVertical = Number(prescription.os.prismVer);
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #3 --
    if (osPrismValueHorizontal ** 2 + osPrismValueVertical ** 2 > PRISM_DIRECTION_VALUE_MAX) {
        result.success = false;
        result.errorMessages.push(ERROR_P1_P2_OUT_OF_RANGE);
    }
    return result;
}
/*
 * Prism Rules:
 * 1. Prism can only be done for Single Vision. Prism cannot be done for Plano, Bifocal & PAL.
 * 2. Base Direction of Prism has four options, in, out, up & down.
 * 3. The range of Prism Value should be [0.00, 5.00].
 * 4. Prism value and base direction should be filled all together.
 * 5. Range within -12.00～+6.00, prism can be made. Out of that range, prism cannot be made.
 *     -12.00 and +6.00 means combination RX of SPH and CYL.
 * 6. For prism, people can choose horizontal and vertical direction for both eyes,
 *    and when they have both  horizontal and vertical  prism value, the prism values
 *    should be based on the formula   P1²+P2²≤25, among which, P1 represents horizontal
 *    prism value, and P2 represents vertical prism value.
 */
export function validatePrism(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if prism is enabled
    const prismEnabled = isPrismEnabled(prescription);
    // if prism is not enabled, then return true directly
    if (!prismEnabled) {
        return result;
    }
    // -- check #1 --
    const res = validatePrismLensType(prescription);
    if (!res.success) {
        return res;
    }
    // -- check #2&4 --
    const res1 = validatePrismOdNoHorDir(prescription);
    if (!res1.success) {
        result.success = false;
        result.errorMessages.push(...res1.errorMessages);
    }
    const res2 = validatePrismOdNoVerDir(prescription);
    if (!res2.success) {
        result.success = false;
        result.errorMessages.push(...res2.errorMessages);
    }
    const res3 = validatePrismOsNoHorDir(prescription);
    if (!res3.success) {
        result.success = false;
        result.errorMessages.push(...res3.errorMessages);
    }
    const res4 = validatePrismOsNoVerDir(prescription);
    if (!res4.success) {
        result.success = false;
        result.errorMessages.push(...res4.errorMessages);
    }
    const res5 = validatePrismOdNoHorValue(prescription);
    if (!res5.success) {
        result.success = false;
        result.errorMessages.push(...res5.errorMessages);
    }
    const res6 = validatePrismOdNoVerValue(prescription);
    if (!res6.success) {
        result.success = false;
        result.errorMessages.push(...res6.errorMessages);
    }
    const res7 = validatePrismOsNoHorValue(prescription);
    if (!res7.success) {
        result.success = false;
        result.errorMessages.push(...res7.errorMessages);
    }
    const res8 = validatePrismOsNoVerValue(prescription);
    if (!res8.success) {
        result.success = false;
        result.errorMessages.push(...res8.errorMessages);
    }
    // -- check #5 --
    const res9 = validatePrismSphCyl(prescription);
    if (!res9.success) {
        result.success = false;
        result.errorMessages.push(...res9.errorMessages);
    }
    // -- check #6 --
    const res10 = validatePrismOdValueRange(prescription);
    if (!res10.success) {
        result.success = false;
        result.errorMessages.push(...res10.errorMessages);
    }
    const res11 = validatePrismOsValueRange(prescription);
    if (!res11.success) {
        result.success = false;
        result.errorMessages.push(...res11.errorMessages);
    }
    result.errorMessages = _.uniq(result.errorMessages);
    return result;
}
/*
PD Rules:
* 1. PD should not be empty.
* 2. When customer chooses Single PD, PD < 50mm, Bifocal and PAL should be not available.
* 3. When customer chooses Dual PD and lower PD * 2  < 50mm, Bifocal and PAL should be not available.
* 4. When abs(Right PD - Left PD)>3mm, and vice versa, Bifocal and PAL should be not available.
* 5. When abs(Right PD - Left PD)>5mm, and vice versa, Single Vision should be not available.
*/
export function validatePd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    const pdSingle = prescription.pd;
    const pdRight = prescription.od.pd;
    const pdLeft = prescription.os.pd;
    const minPd = Math.min(pdRight, pdLeft);
    const absPd = Math.abs(pdRight - pdLeft);
    // check if lens type needs pd
    if (FRAMELESS.has(lensType))
        return result;
    // If there is no PD, fail directly
    if (!hasValue(pdSingle) && !hasValue(pdRight) && !hasValue(pdLeft)) {
        result.success = false;
        result.errorMessages.push(ERROR_PD_REQUIRED);
        return result;
    }
    // -- check #1 --
    if (!hasValue(pdSingle) && (!hasValue(pdRight) || !hasValue(pdLeft))) {
        result.success = false;
        result.errorMessages.push(ERROR_PD_REQUIRED);
        return result;
    }
    // -- check #2 --
    // if pd single is selected, pd single is not 0
    if (pdSingle > PD_RANGE.MIN && pdSingle < PD_RANGE.MAX && (BIFOCAL.has(lensType) || PAL.has(lensType))) {
        result.success = false;
        result.errorMessages.push(ERROR_SINGLE_PD_OUT_OF_RANGE_FOR_BIO_AND_PAL);
    }
    // if pd single is not selected, pd single is not 0
    if (pdSingle === PD_RANGE.NOT_SINGLE_PD) {
        // -- check #3 --
        if (minPd * 2 < PD_RANGE.MAX && (BIFOCAL.has(lensType) || PAL.has(lensType))) {
            result.success = false;
            result.errorMessages.push(ERROR_DOUBLE_PD_OUT_OF_RANGE_FOR_BIO_AND_PAL);
            // If this does not pass, return the result directly, no need to check further
            return result;
        }
        // -- check #4&5 --
        if (absPd > PD_RANGE.ABS_SINGLE_ONLY && absPd <= PD_RANGE.ABS_NO_RESULT) {
            if (BIFOCAL.has(lensType) || PAL.has(lensType)) {
                result.success = false;
                result.errorMessages.push(ERROR_PD_DIFFERENCE_TOO_BIG_ONLY_SINGLE_VISION);
            }
        }
        else if (absPd > PD_RANGE.ABS_NO_RESULT) {
            result.success = false;
            result.errorMessages.push(ERROR_PD_DIFFERENCE_TOO_BIG_NO_RESULT);
        }
    }
    return result;
}
/**
 * Check the max values of PD
 * @param prescription
 * @returns
 */
export function validateMaxPd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const pdSingle = prescription.pd;
    const pdRight = prescription.od.pd;
    const pdLeft = prescription.os.pd;
    if (pdRight && pdLeft) {
        if (Number(pdLeft) + Number(pdRight) > MAX_PD) {
            result.success = false;
            result.errorMessages.push(MAX_PD_MESSAGE);
        }
    }
    else if (pdSingle > MAX_PD) {
        result.success = false;
        result.errorMessages.push(MAX_PD_MESSAGE);
    }
    return result;
}
export function validateLeftPd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if lens type needs pd
    const lensType = prescription.lensType.toLowerCase();
    if (FRAMELESS.has(lensType))
        return result;
    const pdLeft = prescription.os.pd;
    // If there is no PD, fail directly
    if (!hasValue(pdLeft)) {
        result.success = false;
        result.errorMessages.push(ERROR_PD_REQUIRED);
        return result;
    }
    return result;
}
export function validateRightPd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    // check if lens type needs pd
    const lensType = prescription.lensType.toLowerCase();
    if (FRAMELESS.has(lensType))
        return result;
    const pdRight = prescription.od.pd;
    // If there is no PD, fail directly
    if (!hasValue(pdRight)) {
        result.success = false;
        result.errorMessages.push(ERROR_PD_REQUIRED);
        return result;
    }
    return result;
}
export function validateOdAdd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    // if using single vision, no need to check the rest of the conditions
    if (SINGLE_VISION.has(lensType)) {
        return result;
    }
    const odAdd = prescription.od.add;
    // If there is no Add and is one of PAL or BIFOCAL, fail directly
    if (!hasValue(odAdd) && (PAL.has(lensType) || BIFOCAL.has(lensType))) {
        result.success = false;
        result.errorMessages.push(ERROR_NV_ADD_REQUIRED);
        return result;
    }
    return result;
}
export function validateOsAdd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    // if using single vision, no need to check the rest of the conditions
    if (SINGLE_VISION.has(lensType)) {
        return result;
    }
    const osAdd = prescription.os.add;
    // If there is no PD, fail directly
    if (!hasValue(osAdd) && (PAL.has(lensType) || BIFOCAL.has(lensType))) {
        result.success = false;
        result.errorMessages.push(ERROR_NV_ADD_REQUIRED);
        return result;
    }
    return result;
}
export function validateBifocalAndPalWithOutAdd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    // if using single vision, no need to check the rest of the conditions
    if (SINGLE_VISION.has(lensType)) {
        return result;
    }
    // od sph and cyl
    const odSph = Number(prescription.od.sphere);
    const odCyl = Number(prescription.od.cylinders);
    // os sph and cyl
    const osSph = Number(prescription.os.sphere);
    const osCyl = Number(prescription.os.cylinders);
    // get combination strength
    const { jointOd, jointOs } = combineSphAndCyl(odSph, odCyl, osSph, osCyl);
    const sphDiff = odSph - osSph;
    const cylDiff = odCyl - osCyl;
    const jointDiff = jointOd - jointOs;
    let sphDiffError = false;
    let cylDiffError = false;
    let jointDiffError = false;
    let tempMessage = '';
    if (Math.abs(sphDiff) > MAX_SPH_DIFFERENCE) {
        sphDiffError = true;
        tempMessage += 'SPH values';
    }
    if (Math.abs(cylDiff) > MAX_CYL_DIFFERENCE) {
        cylDiffError = true;
        tempMessage += sphDiffError ? ', CYL values' : 'CYL values';
    }
    if (Math.abs(jointDiff) > MAX_JOINT_DIFFERENCE) {
        jointDiffError = true;
        tempMessage
            += sphDiffError || cylDiffError ? ', Combination values (the joint value of SPH and CYL)' : 'Combination values (the joint value of SPH and CYL)';
    }
    if (sphDiffError || cylDiffError || jointDiffError) {
        result.success = false;
        result.errorMessages.push(`There is a large difference in the following values between your left and right eyes: ${tempMessage}. ${ERROR_SHARED_DIFFERENCE_TOO_BIG}`);
    }
    result.errorMessages = _.uniq(result.errorMessages);
    return result;
}
/*
 * Bifocal and PAL Rules:
 * When the difference of SPH values or CYL values or Combination strength values of two eyes is
 * more than 5.00, Bifocal and PAL are not available.
 */
export function validateBifocalAndPal(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    // if using single vision, no need to check the rest of the conditions
    if (SINGLE_VISION.has(lensType)) {
        return result;
    }
    const odRes = validateOdAdd(prescription);
    const osRes = validateOsAdd(prescription);
    const bifocalAndPalRes = validateBifocalAndPalWithOutAdd(prescription);
    result.success = odRes.success && osRes.success && bifocalAndPalRes.success;
    result.errorMessages = [...odRes.errorMessages, ...osRes.errorMessages, ...bifocalAndPalRes.errorMessages];
    result.errorMessages = _.uniq(result.errorMessages);
    return result;
}
/*
 * NV Add Rules:
 * Single Vision does not allow Add value
 */
export function validateAdd(prescription) {
    // initialize the result object
    const result = {
        success: true,
        warning: false,
        errorMessages: [],
        warningMessages: [],
    };
    const lensType = prescription.lensType.toLowerCase();
    // od and os add
    const odAdd = prescription.od.add;
    const osAdd = prescription.os.add;
    // if using single vision, then no add value should be allowed
    if (SINGLE_VISION.has(lensType) && (odAdd || osAdd)) {
        result.success = false;
        result.errorMessages.push(ERROR_NV_ADD_NOT_ALLOWED_FOR_SINGLE_VISION);
    }
    return result;
}
// Export all the functions
export const prescriptionValidators = {
    validateCylAndSph,
    validateCyl,
    validateCylAndAxis,
    validatePrism,
    validatePd,
    validateMaxPd,
    validateBifocalAndPal,
    validateAdd,
};
