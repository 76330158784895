import { defineStore } from 'pinia';
export const useCustomerStore = defineStore('customer', {
    state: () => ({
        user: null,
        isLoggedIn: false,
    }),
    actions: {
        setIsLoggedIn(isLoggedIn) {
            this.isLoggedIn = isLoggedIn;
        },
    },
});
