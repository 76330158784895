import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { findItemOnWishlist } from '~/modules/wishlist/helpers/findItemOnWishlist';
import { Logger } from '~/helpers/logger';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { useUiNotification } from '~/composables';
/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} for a list of methods and values available in this composable.
 */
export function useWishlist() {
    const wishlistStore = useWishlistStore();
    const { app } = useContext();
    const { send: sendNotification } = useUiNotification();
    const loading = ref(false);
    const calculateWishlistTotal = (wishlists) => wishlists.reduce((acc, current) => { var _a; return acc + ((_a = current === null || current === void 0 ? void 0 : current.items_count) !== null && _a !== void 0 ? _a : 0); }, 0);
    const error = ref({
        addItem: null,
        removeItem: null,
        load: null,
        clear: null,
        loadItemsCount: null,
        afterAddingWishlistItemToCart: null,
    });
    const load = async (params) => {
        var _a, _b, _c;
        Logger.debug('useWishlist/load');
        try {
            loading.value = true;
            Logger.debug('[Magento Storefront]: useWishlist.load params->', params);
            const apiState = app.$vsf.$magento.config.state;
            if (apiState.getCustomerToken()) {
                const { data } = await app.$vsf.$magento.api.wishlist(params === null || params === void 0 ? void 0 : params.searchParams, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
                Logger.debug('[Result]:', { data });
                const loadedWishlist = (_c = (_b = data === null || data === void 0 ? void 0 : data.customer) === null || _b === void 0 ? void 0 : _b.wishlists) !== null && _c !== void 0 ? _c : [];
                if (loadedWishlist[0]) {
                    // @ts-expect-error M2-579
                    [wishlistStore.wishlist] = loadedWishlist;
                }
            }
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useWishlist/load', err);
        }
        finally {
            loading.value = false;
        }
        return wishlistStore.wishlist;
    };
    const isInWishlist = ({ product }) => {
        Logger.debug('useWishlist/isInWishlist', product);
        const wishlistProduct = findItemOnWishlist(wishlistStore.wishlist, product);
        return !!((wishlistProduct === null || wishlistProduct === void 0 ? void 0 : wishlistProduct.id) && (wishlistProduct === null || wishlistProduct === void 0 ? void 0 : wishlistProduct.quantity));
    };
    const setWishlist = (newWishlist) => {
        wishlistStore.wishlist = newWishlist;
        Logger.debug('useWishlist/setWishlist', newWishlist);
    };
    const removeItem = async ({ product, customQuery, customHeaders }) => {
        Logger.debug('useWishlist/removeItem', product);
        try {
            loading.value = true;
            Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
                currentWishlist: wishlistStore.wishlist,
                product,
                customQuery,
                customHeaders,
            });
            const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);
            const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
                id: '0',
                items: [itemOnWishlist.id],
            }, customQuery, customHeaders);
            Logger.debug('[Result]:', { data });
            error.value.removeItem = null;
            wishlistStore.$patch((state) => {
                var _a, _b;
                state.wishlist = (_b = (_a = data === null || data === void 0 ? void 0 : data.removeProductsFromWishlist) === null || _a === void 0 ? void 0 : _a.wishlist) !== null && _b !== void 0 ? _b : {};
            });
        }
        catch (err) {
            error.value.removeItem = err;
            Logger.error('useWishlist/removeItem', err);
        }
        finally {
            loading.value = false;
        }
    };
    const loadItemsCount = async () => {
        var _a, _b;
        Logger.debug('useWishlist/wishlistItemsCount');
        const apiState = app.context.$vsf.$magento.config.state;
        let itemsCount = null;
        try {
            loading.value = true;
            error.value.loadItemsCount = null;
            if (apiState.getCustomerToken()) {
                const { data } = await app.context.$vsf.$magento.api.wishlistItemsCount();
                Logger.debug('[Result]:', { data });
                const loadedWishlist = (_b = (_a = data === null || data === void 0 ? void 0 : data.customer) === null || _a === void 0 ? void 0 : _a.wishlists) !== null && _b !== void 0 ? _b : [];
                itemsCount = calculateWishlistTotal(loadedWishlist);
                wishlistStore.$patch((state) => {
                    state.wishlist.items_count = itemsCount;
                });
            }
        }
        catch (err) {
            error.value.loadItemsCount = err;
            Logger.error('useWishlist/wishlistItemsCount', err);
        }
        finally {
            loading.value = false;
        }
        return itemsCount;
    };
    // eslint-disable-next-line consistent-return
    const addItem = async ({ product, customQuery, customHeaders }) => {
        var _a, _b;
        Logger.debug('useWishlist/addItem', product);
        try {
            loading.value = true;
            Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
                currentWishlist: wishlistStore.wishlist,
                product,
                customQuery,
                customHeaders,
            });
            if (!wishlistStore.wishlist) {
                await load({});
            }
            const itemOnWishlist = findItemOnWishlist(wishlistStore.wishlist, product);
            if (itemOnWishlist) {
                return;
            }
            if (!app.$vsf.$magento.config.state.getCustomerToken()) {
                Logger.error('Need to be authenticated to add a product to wishlist');
            }
            // @ts-ignore
            // eslint-disable-next-line no-underscore-dangle
            switch (product.__typename) {
                case 'VirtualProduct':
                case 'DownloadableProduct':
                case 'GroupedProduct':
                case 'GiftCard':
                case 'SimpleProduct': {
                    const { data } = await app.context.$vsf.$magento.api.addProductToWishList({
                        id: '0',
                        items: [{
                                sku: product.sku,
                                quantity: 1,
                            }],
                    }, customQuery, customHeaders);
                    Logger.debug('[Result]:', { data });
                    wishlistStore.$patch((state) => {
                        var _a, _b;
                        state.wishlist = (_b = (_a = data === null || data === void 0 ? void 0 : data.addProductsToWishlist) === null || _a === void 0 ? void 0 : _a.wishlist) !== null && _b !== void 0 ? _b : {};
                    });
                    break;
                }
                case 'ConfigurableProduct': {
                    const { data: configurableProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
                        id: '0',
                        items: [{
                                sku: ((_b = (_a = product.configurable_product_options_selection) === null || _a === void 0 ? void 0 : _a.variant) === null || _b === void 0 ? void 0 : _b.sku) || product.sku,
                                quantity: 1,
                                parent_sku: product.sku,
                            }],
                    }, customQuery, customHeaders);
                    Logger.debug('[Result]:', { data: configurableProductData });
                    wishlistStore.$patch((state) => {
                        var _a, _b;
                        state.wishlist = (_b = (_a = configurableProductData === null || configurableProductData === void 0 ? void 0 : configurableProductData.addProductsToWishlist) === null || _a === void 0 ? void 0 : _a.wishlist) !== null && _b !== void 0 ? _b : {};
                    });
                    break;
                }
                case 'BundleProduct': {
                    const { data: bundleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
                        id: '0',
                        items: [{
                                sku: product.sku,
                                quantity: 1,
                                entered_options: [],
                            }],
                    }, customQuery, customHeaders);
                    Logger.debug('[Result]:', { data: bundleProductData });
                    wishlistStore.$patch((state) => {
                        var _a, _b;
                        state.wishlist = (_b = (_a = bundleProductData === null || bundleProductData === void 0 ? void 0 : bundleProductData.addProductsToWishlist) === null || _a === void 0 ? void 0 : _a.wishlist) !== null && _b !== void 0 ? _b : {};
                    });
                    break;
                }
                default:
                    // @ts-ignore
                    // eslint-disable-next-line no-underscore-dangle
                    Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
            }
        }
        catch (err) {
            error.value.addItem = err;
            Logger.error('useWishlist/addItem', err);
        }
        finally {
            loading.value = false;
        }
    };
    // eslint-disable-next-line @typescript-eslint/require-await
    const clear = async () => {
        Logger.debug('useWishlist/clear');
        try {
            loading.value = true;
            error.value.clear = null;
            wishlistStore.$patch((state) => {
                state.wishlist = {};
            });
        }
        catch (err) {
            error.value.clear = err;
            Logger.error('useWishlist/clear', err);
        }
        finally {
            loading.value = false;
        }
    };
    const afterAddingWishlistItemToCart = ({ product, cartError }) => {
        Logger.debug('useWishlist/afterAddingItemToCart', product);
        if (!isInWishlist({ product }))
            return;
        try {
            if (cartError === null || cartError === void 0 ? void 0 : cartError.message) {
                sendNotification({
                    id: Symbol('product_added_to_cart_from_wishlist_error'),
                    message: cartError.message,
                    type: 'danger',
                    icon: 'cross',
                    persist: false,
                    title: 'Wishlist error',
                });
            }
            else {
                // eslint-disable-next-line promise/catch-or-return
                removeItem({ product })
                    // eslint-disable-next-line promise/always-return
                    .then(() => {
                    sendNotification({
                        id: Symbol('product_added_to_cart_from_wishlist'),
                        message: app.i18n.t('You added {product} to your shopping cart.', { product: product.name }),
                        type: 'success',
                        icon: 'check',
                        persist: false,
                        title: 'Wishlist',
                    });
                });
            }
        }
        catch (err) {
            error.value.afterAddingWishlistItemToCart = err;
            Logger.error('useWishlist/afterAddingWishlistItemToCart', err);
        }
    };
    const addOrRemoveItem = async ({ product, customQuery, customHeaders }) => {
        await (isInWishlist({ product }) ? removeItem({ product, customQuery, customHeaders }) : addItem({ product, customQuery, customHeaders }));
    };
    return {
        loadItemsCount,
        isInWishlist,
        addItem,
        load,
        removeItem,
        clear,
        setWishlist,
        afterAddingWishlistItemToCart,
        addOrRemoveItem,
        loading: readonly(loading),
        error: readonly(error),
    };
}
export default useWishlist;
export * from './useWishlist';
