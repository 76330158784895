import { Logger } from '~/helpers/logger';
export const loadCartCommand = {
    execute: async (context, params) => {
        const apiState = context.$magento.config.state;
        Logger.debug('[Magento Storefront]: Loading Cart');
        const customerToken = apiState.getCustomerToken();
        const virtual = !params.realCart;
        const createVirtualCart = () => null;
        const createRealCart = async () => {
            Logger.debug('[Magento Storefront]: useCart.load.createNewCart');
            apiState.setCartId();
            const { data } = await context.$magento.api.createEmptyCart();
            Logger.debug('[Result]:', { data });
            sessionStorage.clear();
            Logger.debug('[Result]:', 'Cart created and session storage cleared');
            apiState.setCartId(data.createEmptyCart);
            return data.createEmptyCart;
        };
        const getCartData = async (id) => {
            Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);
            const { data, errors } = await context.$magento.api.cart(id);
            Logger.debug('[Result]:', { data });
            if (!(data === null || data === void 0 ? void 0 : data.cart) && (errors === null || errors === void 0 ? void 0 : errors.length)) {
                throw errors[0];
            }
            data.cart.items = data.cart.items.filter(Boolean);
            return data.cart;
        };
        const getCart = async (virtualCart, cartId) => {
            if (!cartId) {
                if (virtualCart) {
                    return createVirtualCart();
                }
                // eslint-disable-next-line no-param-reassign
                cartId = await createRealCart();
                apiState.setCartId(cartId);
            }
            return getCartData(cartId);
        };
        // Try to load cart for existing customer, clean customer token if not possible
        if (customerToken) {
            try {
                const { data, errors } = await context.$magento.api.customerCart();
                Logger.debug('[Result]:', { data, errors });
                if (!(data === null || data === void 0 ? void 0 : data.customerCart) && (errors === null || errors === void 0 ? void 0 : errors.length)) {
                    throw errors[0];
                }
                apiState.setCartId(data.customerCart.id);
                data.customerCart.items = data.customerCart.items.filter(Boolean);
                return data.customerCart;
            }
            catch {
                apiState.setCustomerToken();
            }
        }
        try {
            // If it's not existing customer check if cart id is set and try to load it
            const cartId = apiState.getCartId();
            return await getCart(virtual, cartId);
        }
        catch {
            apiState.setCartId();
            return await getCart(virtual);
        }
    },
};
