const middleware = {}

middleware['avoid-empty-with-items'] = require('../middleware/avoid-empty-with-items.ts')
middleware['avoid-empty-with-items'] = middleware['avoid-empty-with-items'].default || middleware['avoid-empty-with-items']

middleware['cf-ipconfig'] = require('../middleware/cf-ipconfig.ts')
middleware['cf-ipconfig'] = middleware['cf-ipconfig'].default || middleware['cf-ipconfig']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
