import { Logger } from '~/helpers/logger';
export const loadBlocksCommand = {
    execute: async (context, params) => {
        var _a;
        Logger.debug('[Magento]: Load CMS Blocks content', { params });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const { data } = await context.$magento.api.cmsBlocks(params.identifiers, (_a = params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
        Logger.debug('[Result]:', { data });
        return data.cmsBlocks.items;
    },
};
