import useCmsLayout from '~/composables/useCmsLayout';
import { onSSR } from '@vue-storefront/core';
import { defineComponent, computed } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'CleanLayout',
    setup() {
        const { getLayout, styleGuide, favicon, } = useCmsLayout();
        const styleGuideProps = computed(() => { var _a; return ((_a = styleGuide.value[0]) === null || _a === void 0 ? void 0 : _a.fields) || {}; });
        onSSR(async () => {
            await getLayout();
        });
        return {
            favicon,
            styleGuideProps,
        };
    },
    head() {
        return {
            link: [
                { rel: 'stylesheet', href: '/_nuxt/fonts.css' },
                { rel: 'shortcut icon', type: 'image/x-icon', href: this.favicon },
            ],
        };
    },
});
