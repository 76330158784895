import Vue from 'vue';
import SimpleProduct from '~/modules/b2b/prescription/components/pdp-prescription/SimpleProduct.vue';
import BundleProduct from '~/modules/b2b/prescription/components/pdp-prescription/BundleProduct.vue';
import PRODUCT from '~/modules/b2b/pages/product.vue';
import AppHeader from '~/components/AppHeader.vue';
import BeastAppHeader from '~/modules/b2b/components/beast/AppHeader.vue';
import AppFooter from '~/components/AppFooter.vue';
import BeastAppFooter from '~/modules/b2b/components/beast/AppFooter.vue';

    Vue.component('SimpleProduct', SimpleProduct);

    Vue.component('BundleProduct', BundleProduct);

    Vue.component('PRODUCT', PRODUCT);

    Vue.component('AppHeader', BeastAppHeader);

    Vue.component('AppFooter', BeastAppFooter);
