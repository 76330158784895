export function checkPrism(odPrismValueHorizontal, odPrismValueVertical, osPrismValueHorizontal, osPrismValueVertical) {
    const result = {
        prismEnabled: false,
        odPrismEnabled: false,
        osPrismEnabled: false,
    };
    // determine if prism is enabled, if any value is not 0, the it's enabled
    if (odPrismValueHorizontal + odPrismValueVertical + osPrismValueHorizontal + osPrismValueVertical != 0) {
        result.prismEnabled = true;
        if (odPrismValueHorizontal + odPrismValueVertical != 0) {
            result.odPrismEnabled = true;
        }
        if (osPrismValueHorizontal + osPrismValueVertical != 0) {
            result.osPrismEnabled = true;
        }
    }
    else {
        result.prismEnabled = false;
    }
    return result;
}
