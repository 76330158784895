export default {
    name: 'ZnFormDecorator',
    props: {
        form: {
            type: Object,
            default: () => ({}),
        },
        field: {
            type: Object,
            default: () => ({}),
        },
        validateStatus: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
            default: false,
        },
        className: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showWarnings: {
            type: Boolean,
            default: false,
        },
    },
};
