import { Logger } from '~/helpers/logger';
export const removeCouponCommand = {
    execute: async (context, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' }, customHeaders = {} }) => {
        var _a;
        Logger.debug('[Magento]: Remove coupon from cart', { currentCart });
        const { data, errors } = await context.$magento.api.removeCouponFromCart({
            cart_id: currentCart.id,
        }, customQuery, customHeaders);
        Logger.debug('[Result]:', { data });
        return {
            updatedCart: (_a = data.removeCouponFromCart) === null || _a === void 0 ? void 0 : _a.cart,
            updatedCoupon: { code: '' },
            errors,
        };
    },
};
