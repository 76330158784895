import { PRE_ORDER_DATES } from '../../constants.client';
export const mapProductItemToGTMProduct = (itemId, products) => {
    var _a, _b, _c, _d;
    const itemProduct = products.find((item) => item.uid === itemId);
    if (!itemProduct)
        return null;
    return {
        item_id: itemProduct.uid,
        item_name: ((_a = itemProduct.product) === null || _a === void 0 ? void 0 : _a.name) || '',
        item_options: ((_d = (_c = (_b = itemProduct.bundle_options) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.values) === null || _d === void 0 ? void 0 : _d.map((option) => ({ id: option.id, name: option.label }))) || [],
    };
};
export const isValidDiscount = (discount, sku) => {
    if (!(discount === null || discount === void 0 ? void 0 : discount.is_active))
        return false;
    // to_date comes as date only, Default store has PDT timezone configured
    // so we will add til the end of the day to have it included
    const endDiscountDate = new Date(`${discount.to_date}T23:59:59-07:00`).getTime();
    const preOrderEndDate = new Date(PRE_ORDER_DATES[sku]()).getTime();
    return endDiscountDate < preOrderEndDate;
};
