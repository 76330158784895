import { onSSR } from '@vue-storefront/core';
import { defineComponent, computed, onMounted } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import Accordion from './Accordion.vue';
export default defineComponent({
    name: 'Faq',
    components: {
        Accordion,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const { search, content } = useContentful('pdpFaqComponent');
        const getContentfulData = async () => {
            await search({
                custom: {
                    type: 'pdpFaqComponent',
                    field: 'sku',
                    value: props.product.sku,
                },
            });
        };
        onSSR(async () => {
            await Promise.all([getContentfulData()]);
        });
        onMounted(async () => {
            if (content.value.length === 0) {
                await getContentfulData();
            }
        });
        const faqData = computed(() => { var _a; return (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields; });
        return {
            faqData,
        };
    },
});
