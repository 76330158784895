import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, computed, onBeforeMount, } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
// import AppHeader from '~/components/AppHeader.vue'; // -> global component managed by b2b module
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
// import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import useCmsLayout from '~/composables/useCmsLayout';
import { onSSR } from '@vue-storefront/core';
import { useGuestUser } from '~/modules/b2b/customer/composables';
import PreOrderBanner from '~/modules/b2b/components/beast/PreOrderBanner.vue';
export default defineComponent({
    name: 'DefaultLayout',
    components: {
        // LoadWhenVisible,
        LazyHydrate,
        // AppHeader, // -> global component managed by b2b module
        BottomNavigation,
        IconSprite,
        TopBar,
        PreOrderBanner,
        // AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
        CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
        WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
        LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
        Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    },
    setup() {
        const { validateUser } = useGuestUser();
        const route = useRoute();
        const { isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, showPreOrderBanner, } = useUiState();
        const { getLayout, styleGuide, layout, favicon, } = useCmsLayout();
        onSSR(async () => {
            await getLayout();
        });
        onBeforeMount(async () => {
            await validateUser();
        });
        const hasTopBar = computed(() => layout.value && layout.value[0].fields.topBar);
        const hasBottomNavigation = computed(() => layout.value && layout.value[0].fields.bottomNavigation);
        return {
            isCartSidebarOpen,
            hasTopBar,
            hasBottomNavigation,
            isWishlistSidebarOpen,
            isLoginModalOpen,
            toggleLoginModal,
            showPreOrderBanner,
            route,
            styleGuide,
            favicon,
        };
    },
    head() {
        return {
            link: [
                { rel: 'stylesheet', href: '/_nuxt/fonts.css' },
                { rel: 'shortcut icon', type: 'image/x-icon', href: this.favicon },
            ],
            script: [
                {
                    src: 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js',
                    body: true,
                },
                {
                    src: 'https://pay.google.com/gp/p/js/pay.js',
                    body: true,
                },
            ],
        };
    },
});
