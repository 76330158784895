export default function dynamicMask (maskit, masks, tokens) {
  masks = masks.sort((a, b) => a.length - b.length);
  return function (value, mask, masked) {
    var i = 0;
    while (i < masks.length) {
      var currentMask = masks[i];
      i++;
      var nextMask = masks[i];
      if (! (nextMask && maskit(value, nextMask, masked, tokens).length > currentMask.length) ) {
        return maskit(value, currentMask, masked, tokens);
      }
    }
    return ''; // empty masks
  };
}
