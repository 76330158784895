//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SfRadio } from '@storefront-ui/vue';
import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'ZnFormRadio',
  components: {
    SfRadio,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const proxyChecked = computed({
      get: () => props.value,
      set: (val) => {
        emit('change', val);
      },
    });

    return {
      proxyChecked,
    };
  },
};
