const logger = {
    /* eslint-disable no-console */
    error: (message, ...info) => console.error(message, ...info),
    warn: (message, ...info) => console.warn(message, ...info),
    help: (message, ...info) => console.info('help', message, ...info),
    data: (message, ...info) => console.info('data', message, ...info),
    info: (message, ...info) => console.info(message, ...info),
    debug: (message, ...info) => console.debug(message, ...info),
    prompt: (message, ...info) => console.info('prompt', message, ...info),
    http: (message, ...info) => console.info('http', message, ...info),
    verbose: (message, ...info) => console.info('verbose', message, ...info),
    input: (message, ...info) => console.info('input', message, ...info),
    silly: (message, ...info) => console.info('silly', message, ...info),
    // for syslog levels only
    emerg: (message, ...info) => console.error('emerg', message, ...info),
    alert: (message, ...info) => console.error('alert', message, ...info),
    crit: (message, ...info) => console.error('crit', message, ...info),
    warning: (message, ...info) => console.warn('warning', message, ...info),
    notice: (message, ...info) => console.info('notice', message, ...info),
};
const clientLogger = (_context, inject) => {
    inject('logger', logger);
    inject('test', () => console.log('test'));
};
export default clientLogger;
