import { defineComponent, ref } from '@nuxtjs/composition-api';
import { ZnLoading } from '~/modules/b2b/components';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
export default defineComponent({
    name: 'ZnModal',
    directives: {
        clickOutside,
    },
    components: {
        ZnLoading,
    },
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Close',
        },
        showFooter: {
            type: Boolean,
            required: false,
            default: true,
        },
        modalClasses: {
            type: String,
            required: false,
            default: '',
        },
        width: {
            type: String,
            required: false,
            default: 'max-w-2xl',
        },
        handleClick: {
            type: Function,
            required: false,
            default: null,
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-modal',
        },
        hasCancelButton: {
            type: Boolean,
            required: false,
            default: false,
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: 'Cancel',
        },
        handleCancelClick: {
            type: Function,
            required: false,
            default: null,
        },
        closeAfterOutClick: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, { emit }) {
        const show = ref(false);
        const open = () => {
            show.value = true;
        };
        const close = () => {
            show.value = false;
            emit('modal:close');
        };
        const handleClickFn = () => {
            if (props.handleClick) {
                props.handleClick();
            }
            else {
                close();
            }
        };
        const handleCancelClickFn = () => {
            if (props.handleCancelClick) {
                props.handleCancelClick();
            }
            else {
                close();
            }
        };
        const onClickOutSide = () => {
            if (props.closeAfterOutClick)
                close();
        };
        return {
            show,
            open,
            close,
            handleClickFn,
            handleCancelClickFn,
            onClickOutSide,
        };
    },
});
