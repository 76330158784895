import { defineComponent, computed, onMounted, useRoute, watch, } from '@nuxtjs/composition-api';
import { ZnBadge } from '~/modules/b2b/components';
import { useCart } from '~/composables';
import { useB2BUiState } from '~/modules/b2b/composables';
export default defineComponent({
    name: 'CartHeader',
    components: {
        ZnBadge,
    },
    setup() {
        const route = useRoute();
        const { cart, load: loadCart } = useCart();
        const totalItems = computed(() => { var _a; return ((_a = cart.value) === null || _a === void 0 ? void 0 : _a.total_quantity) || 0; });
        const { goTo } = useB2BUiState();
        const goToCart = () => {
            goTo({ path: '/cart' });
        };
        watch(() => route.value.fullPath, () => {
            loadCart();
        });
        onMounted(() => {
            loadCart();
        });
        return {
            totalItems,
            goToCart,
        };
    },
});
