import Vue from 'vue';
import { SfAccordion } from '@storefront-ui/vue';
export default Vue.extend({
    name: 'Accordion',
    components: {
        SfAccordion,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
});
