// integration
import { useContentFactory } from '@vue-storefront/core';
import { reactive, computed } from '@nuxtjs/composition-api';
const state = reactive({
    lastError: null,
    available: true,
});
// CONTENT and CONTENT_SEARCH_PARAMS are your CMS-specific types, we advise to have at least 'id' param for search
export const useContentful = useContentFactory({
    // (params: CONTENT_SEARCH_PARAMS) => Promise<CONTENT>;
    async search(_, params) {
        // write your content fetching logic here
        var _a;
        try {
            let content = null;
            if (process.server) {
                const { getContent } = await import('~/serverMiddleware/cntf/get-content');
                content = await getContent(params);
            }
            else {
                const response = await fetch('/cntf/getContent', {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: { 'Content-Type': 'application/json' },
                });
                content = await response.json();
            }
            if (((_a = content.sys) === null || _a === void 0 ? void 0 : _a.type) === 'Error') {
                state.lastError = content;
            }
            return content;
        }
        catch (e) {
            // network errors, etc
            state.available = false;
            state.lastError = e;
            return {
                sys: {
                    type: 'Error',
                },
                message: e.message,
            };
        }
    },
});
/**
 *  Be careful using this global state. SSR and Client state are different.
 *  If you need to handle errors in SSR use it inside the function.
 *  @example
 *   onSSR(() => {
 *     await search({...})
 *     if (lastError.value) { // <-- this is SSR and won't be available on client
 *       // handle error
 *     }
 *   }}
 *
 * @returns reactive state of the composables
 *
 */
export const useContentfulState = () => ({
    lastError: computed(() => state.lastError),
    available: computed(() => state.available),
});
