import { defineComponent, ref, nextTick, } from '@nuxtjs/composition-api';
import imageCompression from 'browser-image-compression';
import { useOcr } from '~/modules/b2b/prescription';
import { ZnCard, ZnModal, ZnButton } from '~/modules/b2b/components';
import { usePrescription } from '~/modules/b2b/prescription/composables';
export default defineComponent({
    name: 'UploadImageCard',
    components: {
        ZnCard,
        ZnModal,
        ZnButton,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    emits: ['click', 'loading'],
    setup(props, { emit }) {
        const uploadInputRef = ref(null);
        const modal = ref(null);
        const imageData = ref(null);
        const fileErrorModal = ref(null);
        const fileErrorMsg = ref('');
        const maximumFileSize = ref(20);
        const { ocrPrescription, prescription, prescriptionHasPrism } = useOcr();
        const { setPrescription, storeImage } = usePrescription();
        const handleClick = () => {
            uploadInputRef.value.click();
        };
        const toBase64 = (file) => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.addEventListener('load', () => resolve(reader.result));
            reader.addEventListener('error', reject);
        });
        const continueWithoutPrism = async () => {
            modal.value.close();
            try {
                const fileSize = Number.parseFloat((imageData.value.size / 1024 / 1024).toFixed(4));
                /* if file size is bigger than 3MB then compress it and save it to storage */
                if (fileSize > 3) {
                    const compressOptions = {
                        maxSizeMB: 3,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true,
                    };
                    const fileWithCompressOption = await imageCompression(imageData.value, compressOptions);
                    if (fileWithCompressOption)
                        storeImage(await toBase64(fileWithCompressOption));
                }
                else {
                    storeImage(await toBase64(imageData.value));
                }
                emit('loading', false);
                setPrescription(prescription.value);
                nextTick(() => emit('click', props.item));
            }
            catch (err) {
                console.log(err);
            }
        };
        const processImage = async (data) => {
            imageData.value = data;
            try {
                await ocrPrescription(imageData.value, true);
            }
            catch (err) {
                console.log(err);
            }
            if (!prescriptionHasPrism.value) {
                continueWithoutPrism();
            }
            else {
                emit('loading', false);
                modal.value.open();
            }
        };
        const onFileChanged = async () => {
            const file = uploadInputRef.value.files[0];
            if (!file)
                return;
            const acceptedFileTypes = [
                'image/gif',
                'image/tiff',
                'image/jpeg',
                'image/png',
                'image/bmp',
                'image/webp',
            ];
            const fileSize = Number.parseFloat((file.size / 1024 / 1024).toFixed(4));
            if (fileSize > maximumFileSize.value || !acceptedFileTypes.includes(file.type)) {
                fileErrorModal.value.open();
                fileErrorMsg.value = fileSize > maximumFileSize.value ? `Maximum file size is ${maximumFileSize.value}MB!` : 'Please upload valid image file!';
                return;
            }
            emit('loading', true);
            try {
                await processImage(file);
            }
            catch (err) {
                console.log(err);
                emit('loading', false);
                fileErrorModal.value.open();
                fileErrorMsg.value = 'Failed to parse prescription! Please upload correct file again.';
            }
        };
        return {
            modal,
            uploadInputRef,
            handleClick,
            onFileChanged,
            processImage,
            continueWithoutPrism,
            maximumFileSize,
            fileErrorModal,
            fileErrorMsg,
        };
    },
});
