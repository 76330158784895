/* eslint-disable @typescript-eslint/naming-convention */
import { State } from 'country-state-city';
import { VerifyStatusEnum, } from './types';
import { iso2ToIso3 } from './constants';
export const useSmarty = () => {
    const isUsAddress = (country) => country.toUpperCase() === 'US';
    const addressEquals = (address1, address2) => {
        var _a;
        return address1.address === address2.address
            && address1.city.toLowerCase() === address2.city.toLowerCase()
            && (address1.state.toLowerCase() === address2.state.toLowerCase()
                || address1.state.toLowerCase() === ((_a = State.getStateByCodeAndCountry(address2.state, address2.country)) === null || _a === void 0 ? void 0 : _a.name.toLowerCase()))
            && address1.zipCode.toLowerCase() === address2.zipCode.toLowerCase()
            && address1.country.toLowerCase() === address2.country.toLowerCase();
    };
    const verifyAddress = async (address) => {
        var _a, _b;
        let status = VerifyStatusEnum.verified;
        const smartyReqBody = {
            countrySearch: address.country,
            queryType: 'verify',
            params: {
                street: address.address,
                street2: address.address2,
                city: address.city,
                state: address.state,
                zipCode: address.zipCode,
                language: address.language,
            },
        };
        const smarty = await fetch('/smarty', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(smartyReqBody),
        });
        let addressVerified = {
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
        };
        let matchCode;
        if (smarty.ok) {
            if (isUsAddress(address.country)) {
                // https://www.smarty.com/docs/cloud/us-street-api#analysis
                const verify = await smarty.json();
                if (((_a = verify === null || verify === void 0 ? void 0 : verify.lookups) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    const { result } = verify.lookups[0]; // Only takes the first one
                    const { components } = result[0];
                    const { dpvMatchCode } = result[0].analysis;
                    matchCode = dpvMatchCode || 'Not Provided';
                    if (matchCode === 'N' || !dpvMatchCode)
                        status = VerifyStatusEnum.notVerified;
                    const zipCode = `${components.zipCode}${components.plus4Code ? `-${components.plus4Code}` : ''}`;
                    // don't suggest if not a valid address. Indeed if it is verified, it can have different data
                    if ((dpvMatchCode && dpvMatchCode !== 'N')) {
                        addressVerified = {
                            address: result[0].deliveryLine1,
                            city: components.cityName,
                            state: components.state,
                            zipCode,
                            country: address.country,
                        };
                        status = VerifyStatusEnum.partial;
                    }
                }
            }
            else {
                const verify = await smarty.json();
                if (((_b = verify === null || verify === void 0 ? void 0 : verify.result) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    const { result } = verify;
                    const { components } = result[0];
                    const { verificationStatus } = result[0].analysis;
                    matchCode = verificationStatus || 'Not Provided';
                    if (matchCode === 'None' || !matchCode)
                        status = VerifyStatusEnum.notVerified;
                    // don't suggest if not a valid address or it is verified and always return the address if language is set
                    if ((verificationStatus && !['None', 'Verified'].includes(verificationStatus))
                        || address.language) {
                        const postalCodeAsAddress1 = result[0].address1 === components.postalCode;
                        const address1 = postalCodeAsAddress1 ? result[0].address2 : result[0].address1; // JP address seems that are switched between address 1 and 2
                        const address2 = postalCodeAsAddress1
                            ? ''
                            : (result[0].address2 !== components.locality && result[0].address2 !== components.administrativeArea ? result[0].address2 || '' : '');
                        const hasAddress3 = result[0].address3
                            && (result[0].address3 !== components.postalCode && result[0].address3 !== components.locality);
                        const address3 = hasAddress3 ? `, ${result[0].address3}` : '';
                        addressVerified = {
                            address: address1,
                            address2: `${address2}${address3}`,
                            city: components.locality,
                            state: components.administrativeArea || '',
                            zipCode: components.postalCode || '',
                            country: address.country,
                        };
                        status = VerifyStatusEnum.partial;
                    }
                }
            }
        }
        return {
            originalAddress: address,
            verifiedAddress: addressVerified.address !== '' ? addressVerified : undefined,
            isEqual: addressVerified.address !== '' ? addressEquals(address, addressVerified) : false,
            matchCode,
            status,
        };
    };
    const autocomplete = async (query, countrySearch, addressId) => {
        const smartyReqBody = {
            countrySearch: iso2ToIso3[countrySearch],
            queryType: 'autocomplete',
            addressId,
            params: {
                query,
            },
        };
        const smarty = await fetch('/smarty', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(smartyReqBody),
        });
        const address = [];
        if (smarty.ok) {
            if (isUsAddress(countrySearch)) {
                const autocompleteResponse = await smarty.json();
                const { result } = autocompleteResponse;
                result.forEach((addressResult) => {
                    const { streetLine, city, state, zipcode, } = addressResult;
                    address.push({
                        city,
                        state,
                        address: streetLine,
                        zipCode: zipcode,
                        country: countrySearch,
                    });
                });
            }
            else {
                const autocompleteResponse = await smarty.json();
                const { candidates, result } = autocompleteResponse;
                (result || candidates).forEach((addressResult) => {
                    const { street, locality, administrativeArea, postalCode, addressId: resultAddressId, addressText, entries, } = addressResult;
                    address.push({
                        city: locality,
                        state: administrativeArea || '',
                        address: street,
                        zipCode: postalCode,
                        country: countrySearch,
                        addressId: resultAddressId,
                        addressText,
                        entries,
                    });
                });
            }
        }
        return address;
    };
    const verifyZipcode = async (address) => {
        var _a;
        if (isUsAddress(address.country)) {
            const smartyReqBody = {
                countrySearch: address.country,
                queryType: 'zipcode',
                params: {
                    city: address.city,
                    state: address.state,
                    zipCode: address.zipCode,
                },
            };
            const smarty = await fetch('/smarty', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(smartyReqBody),
            });
            if (smarty.ok) {
                const zipcode = await smarty.json();
                if (((_a = zipcode === null || zipcode === void 0 ? void 0 : zipcode.lookups) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    const { result } = zipcode.lookups[0]; // Only takes the first one
                    const { reason, status, valid } = result[0];
                    return {
                        status,
                        reason,
                        valid,
                    };
                }
            }
        }
        return {
            status: '',
            reason: '',
            valid: false,
        };
    };
    return {
        verifyAddress,
        autocomplete,
        verifyZipcode,
    };
};
