import { computed } from '@nuxtjs/composition-api';
import { getGallery as getProductGallery } from '~/modules/catalog/product/getters/productGetters';
import { useImage } from '~/composables';
/**
 * The `useProductGallery()` composable allows building product's gallery data structure.
 *
 * See the {@link UseProductGalleryInterface} page for more information.
 */
export function useProductGallery(product, imgPlaceholder = '', maxGallerySize = 4) {
    const { getMagentoImage, imageSizes } = useImage();
    const productGallery = computed(() => getProductGallery(product.value, maxGallerySize).map((img) => ({
        mobile: { url: getMagentoImage(img.small) },
        desktop: { url: getMagentoImage(img.normal) },
        big: { url: getMagentoImage(img.big) },
        alt: img.alt || product.value.name,
        placeholder: imgPlaceholder,
    })));
    return {
        productGallery,
        imageSizes,
    };
}
export default useProductGallery;
export * from './useProductGallery';
