import { merge } from 'lodash-es';
import { Logger } from '~/helpers/logger';
export const createProxiedApi = ({ givenApi, client, tag, nuxtCtx, }) => new Proxy(givenApi, {
    get: (target, prop, receiver) => {
        const functionName = String(prop);
        if (Reflect.has(target, functionName)) {
            return Reflect.get(target, prop, receiver);
        }
        // eslint-disable-next-line @typescript-eslint/require-await
        return async (...args) => client
            .post(`/${tag}/${functionName}`, args)
            .then((r) => r.data)
            .catch((err) => {
            Logger.debug(err);
            nuxtCtx.error({ statusCode: err.statusCode, message: err });
            return {};
        });
    },
});
export const getCookies = (context) => { var _a, _b, _c; return (_c = (_b = (_a = context === null || context === void 0 ? void 0 : context.req) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b.cookie) !== null && _c !== void 0 ? _c : ''; };
export const getIntegrationConfig = (context, configuration) => {
    const cookie = getCookies(context);
    return merge({
        axios: {
            headers: {
                ...(cookie ? { cookie } : {}),
            },
            withCredentials: true,
        },
    }, configuration);
};
