import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import categoryMetaGql from '~/modules/catalog/category/composables/useCategory/categoryMeta.gql';
/**
 * @public
 *
 * Allows loading categories from Magento API. It
 * is commonly used in navigation menus, and provides the load function and
 * refs for the categories, loading and error.
 *
 * See the {@link UseCategoryInterface} for a list of methods and values available in this composable.
 *
 * @remarks
 *
 * Under the hood, it calls the following Server Middleware API method:
 *
 * - {@link @vue-storefront/magento-api#categoryList} for loading category list;
 *
 * It is currently used in:
 *
 * - `components/AppHeader.vue`
 *
 * - `components/MobileMenuSidebar.vue`
 *
 * @example
 *
 * Load categories on client side using the `onMounted` Composition API hook:
 *
 * ```vue
 * <template>
 *   <div v-if="loading">
 *     Loading categories…
 *   </div>
 *   <div v-else-if="error.load">
 *     Error: {{ error.load.message }}
 *   </div>
 *   <div v-else>
 *     <!-- Display 'categories' -->
 *   </div>
 * </template>
 *
 * <script>
 * import { onMounted } from '@nuxtjs/composition-api';
 * import { useCategory } from '~/modules/catalog/category/composables/useCategory';
 *
 * export default {
 *   setup() {
 *     const { categories, error, load, loading } = useCategory();
 *
 *     onMounted(async () => {
 *       await load({ pageSize: 10 });
 *     });
 *
 *     return {
 *       error,
 *       loading,
 *       categories,
 *     };
 *   },
 * };
 * </script>
 * ```
 */
export function useCategory() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({
        load: null,
        loadCategoryMeta: null,
    });
    const categories = ref(null);
    const load = async (params) => {
        var _a, _b, _c;
        Logger.debug('useCategory/load', params);
        try {
            loading.value = true;
            const { data } = await app.context.$vsf.$magento.api.categoryList(params, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            Logger.debug('[Result]:', { data });
            categories.value = (_c = (_b = data === null || data === void 0 ? void 0 : data.categories) === null || _b === void 0 ? void 0 : _b.items) !== null && _c !== void 0 ? _c : [];
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useCategory/load', err);
        }
        finally {
            loading.value = false;
        }
    };
    const loadCategoryMeta = async (params) => {
        var _a, _b;
        Logger.debug('useCategory/loadCategoryMeta', params);
        let categoryMeta = null;
        try {
            loading.value = true;
            const { data } = await app.context.$vsf.$magento.api.customQuery({
                query: categoryMetaGql,
                queryVariables: {
                    filters: {
                        category_uid: {
                            eq: params.category_uid,
                        },
                    },
                },
                customHeaders: params === null || params === void 0 ? void 0 : params.customHeaders,
            });
            Logger.debug('[Result]:', { data });
            categoryMeta = ((_b = (_a = data.categories) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[0]) || null;
            error.value.loadCategoryMeta = null;
        }
        catch (err) {
            error.value.loadCategoryMeta = err;
            Logger.error('useCategory/loadCategoryMeta', err);
        }
        finally {
            loading.value = false;
        }
        return categoryMeta;
    };
    return {
        load,
        loadCategoryMeta,
        loading,
        error,
        categories,
    };
}
export * from './useCategory';
export default useCategory;
