import { computed, nextTick, onMounted, useContext, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
import { extractImage, extractComponents } from '../components/utils/render-helper';
const useCmsLayout = () => {
    // @ts-ignore
    const { $gladlyShow, $gladlyInit, $gladlyHide } = useContext();
    const { search: searchStyleGuide, content: styleGuide } = useContentful('style-guide');
    const { search: searchLayout, content: layout } = useContentful('layout');
    const { showZendeskChat } = useUrlLanguageParser();
    const getLayout = () => Promise.all([
        searchStyleGuide({
            custom: {
                type: 'StyleGuide',
            },
        }),
        searchLayout({
            custom: {
                type: 'layout',
            },
        }),
    ]);
    const header = computed(() => {
        const extractedHeader = layout.value.length > 0 && layout.value[0].fields.header;
        return {
            logo: extractedHeader ? extractImage(extractedHeader.fields.logo) : { url: null, alt: null },
            navigation: extractedHeader
                ? extractedHeader.fields.navigation.filter((item) => item.fields).map(({ fields }) => ({ ...fields }))
                : [],
            searchPlaceholder: extractedHeader ? extractedHeader.fields.searchPlaceholder : undefined,
        };
    });
    const footer = computed(() => {
        const extractedFooter = layout.value.length > 0 && extractComponents([layout.value[0].fields.footer])[0].props;
        return {
            items: extractedFooter
                ? extractedFooter.items.map((item) => ({
                    ...item,
                    items: item.items.map(({ fields }) => fields),
                }))
                : [],
        };
    });
    const favicon = computed(() => {
        const extractedFavicon = layout.value.length > 0 && layout.value[0].fields.favicon;
        return extractedFavicon ? `https:${extractImage(extractedFavicon).url}` : '/favicon.ico';
    });
    onMounted(() => {
        nextTick(() => {
            if (showZendeskChat.value && window.Gladly) {
                $gladlyInit().then(() => {
                    // we call show and hide to have the widget minimized in the UI
                    $gladlyShow();
                    $gladlyHide();
                    return true;
                }).catch((err) => console.log(err));
            }
        });
    });
    return {
        getLayout,
        styleGuide,
        layout,
        favicon,
        header,
        footer,
    };
};
export default useCmsLayout;
