import { reactive, computed, useContext, } from '@nuxtjs/composition-api';
import { useUrlLanguageParser } from '~/modules/b2b/localization';
const state = reactive({
    userId: null,
});
export const useGuestUser = () => {
    const context = useContext();
    const { $cookies } = context;
    const userId = computed(() => state.userId || $cookies.get('userId'));
    const { languageCode } = useUrlLanguageParser();
    const guestLogin = async () => {
        const req = await fetch('/guest-login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                languageCode,
            }),
        });
        return req.json();
    };
    const removeTokens = () => {
        $cookies.remove('token');
        $cookies.remove('fusionToken');
    };
    const isTokenValid = async () => {
        try {
            const req = await fetch('/validate-token');
            if (!req.ok) {
                removeTokens();
                return false;
            }
            const data = await req.json();
            if (data.userId) {
                return true;
            }
            return true;
        }
        catch (error) {
            console.log(error);
            removeTokens();
            return false;
        }
    };
    const validateUser = async () => {
        const token = $cookies.get('token');
        if (token) { // if token is set, verify if is still ok
            if (!(await isTokenValid())) {
                removeTokens();
                const res = await guestLogin();
                state.userId = res.userId;
            }
            // ---------------------------------------------------------------------------------
            // TODO: Investigate why the token is not expired but returns 401
            // ---------------------------------------------------------------------------------
            // const decoded = jsonwebtoken.decode(token) as ZenniJWT;
            // console.log({ exp: decoded.exp * 1000, now: Date.now() });
            // if (decoded.exp * 1000 < Date.now()) { // token is expired, call guest-login api
            //   const res = await guestLogin();
            //   state.userId = res.userId;
            // }
            // ---------------------------------------------------------------------------------
        }
        else { // token is not set, call guest-login api
            removeTokens();
            const res = await guestLogin();
            state.userId = res.userId;
        }
    };
    const updateUser = async (userData) => {
        await validateUser();
        const req = await fetch('/update-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });
        return req.json();
    };
    return {
        userId: userId.value,
        validateUser,
        updateUser,
        isTokenValid,
        removeTokens,
    };
};
