import { computed, readonly, ref, useContext, } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useConfigStore } from '~/stores/config';
/**
 * Allows interacting with the store configuration.
 *
 * See the {@link UseConfigInterface} for a list of methods and values available in this composable.
 */
export function useConfig() {
    const { app } = useContext();
    const loading = ref(false);
    const error = ref({ load: null });
    const configStore = useConfigStore();
    const config = computed(() => configStore.storeConfig);
    const load = async (params) => {
        var _a;
        error.value.load = null;
        loading.value = true;
        Logger.debug('useConfig/load');
        try {
            const { data } = await app.$vsf.$magento.api.storeConfig((_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            configStore.$patch((state) => {
                state.storeConfig = data.storeConfig || {};
            });
        }
        catch (err) {
            Logger.debug('[ERROR] useConfig/load', err);
            error.value.load = err;
        }
        finally {
            loading.value = false;
        }
    };
    return {
        config,
        loading: readonly(loading),
        load,
    };
}
export * from './useConfig';
export default useConfig;
