import { defineComponent, computed } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'ZnCard',
    components: {
        ZnTag: () => import('../zn-tag/index.vue'),
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-card',
        },
        tags: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    emits: ['click'],
    setup(props, { emit }) {
        const cardClasses = computed(() => [
            'rounded-lg p-6 space-y-1 hover:bg-grays-lighter transition-all duration-200 ease-in-out outline',
            {
                'outline-gray-200': !props.item.selected,
                'outline-2 outline-primary': props.item.selected,
                'cursor-not-allowed': props.item.disabled,
                'cursor-pointer': !props.item.disabled,
            },
        ]);
        const labelClasses = computed(() => [
            {
                '!text-gray-500': props.item.disabled,
            },
        ]);
        const handleClick = (item) => {
            if (!props.item.disabled) {
                emit('click', item);
            }
        };
        return {
            cardClasses,
            labelClasses,
            handleClick,
        };
    },
});
