import { computed, useContext } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
/**
 * Allows getting the Magento's major
 * definitions, e.g., the selected currency, store, locale, and config object.
 *
 * See the {@link UseMagentoConfigurationInterface} for a list of methods and values available in this composable.
 */
export function useMagentoConfiguration() {
    const { app: { $vsf: { $magento: { config }, }, }, } = useContext();
    const { config: storeConfig } = useConfig();
    const selectedCurrency = computed(() => config.state.getCurrency());
    const selectedLocale = computed(() => config.state.getLocale());
    const selectedStore = computed(() => config.state.getStore());
    const isRegion = computed(() => storeConfig.value.is_region);
    return {
        storeConfig,
        selectedCurrency,
        selectedLocale,
        selectedStore,
        isRegion,
    };
}
export * from './UseMagentoConfiguration';
export default useMagentoConfiguration;
