import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import { SfLoader } from '@storefront-ui/vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { useSmarty } from '~/modules/b2b/composables';
import { ZnInput } from '..';
export default defineComponent({
    name: 'ZnAddress',
    directives: {
        clickOutside,
    },
    components: {
        ZnInput,
        SfLoader,
    },
    props: {
        withVerify: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: undefined,
            required: false,
        },
        name: {
            type: String,
            default: undefined,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        countrySearch: {
            type: String,
            required: false,
            default: 'US',
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'zn-address',
        },
    },
    setup(props, { emit }) {
        const localValue = ref(props.value || null);
        const results = ref([]);
        const showOptions = ref(false);
        const loading = ref(false);
        const input = ref(null);
        const intAutoCompleteProcessing = ref(false);
        const { autocomplete, verifyAddress } = useSmarty();
        const smartyFind = async (query, addressId) => {
            if (!props.countrySearch)
                return;
            localValue.value = query;
            loading.value = true;
            if (query
                && ((props.countrySearch === 'JP' && query.length > 0) // Allow start searching with only one Kanji
                    || (props.countrySearch !== 'JP' && query.length > 3))) {
                const smartyAutocomplete = await autocomplete(query, props.countrySearch, addressId);
                if (smartyAutocomplete.length > 0) {
                    results.value = smartyAutocomplete;
                    showOptions.value = true;
                }
            }
            loading.value = false;
        };
        watch(() => props.value, (newValue) => {
            localValue.value = newValue;
        });
        watch(() => props.countrySearch, () => {
            results.value = [];
        });
        const emitSelected = async (result) => {
            emit('click');
            if (result.addressId) {
                await smartyFind(result.addressText, result.addressId);
                intAutoCompleteProcessing.value = true;
                return;
            }
            localValue.value = result.address;
            showOptions.value = false;
            intAutoCompleteProcessing.value = false;
            let verifiedAddress;
            try {
                if (props.withVerify) {
                    verifiedAddress = await verifyAddress(result);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally {
                emit('selected', { autocomplete: result, verify: verifiedAddress });
            }
        };
        const debouncedHandleSearch = debounce(smartyFind, 300);
        const toggleOutside = () => {
            if (!intAutoCompleteProcessing.value) {
                showOptions.value = false;
            }
        };
        const handleFocus = () => {
            var _a;
            if (((_a = results.value) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                showOptions.value = true;
            }
        };
        const emitChange = (change) => {
            emit('change', change);
        };
        const formatResult = (resultItem) => {
            if (resultItem.addressText) {
                return resultItem.addressText;
            }
            if (resultItem.address) {
                return `${resultItem.address}, ${resultItem.city}${resultItem.state ? `, ${resultItem.state}` : ''}${resultItem.zipCode ? `, ${resultItem.zipCode}` : ''}`;
            }
            return false;
        };
        const validateRules = (val) => {
            input.value.validateRules(val);
        };
        return {
            input,
            loading,
            results,
            showOptions,
            emitChange,
            handleFocus,
            emitSelected,
            toggleOutside,
            localValue,
            formatResult,
            validateRules,
            debouncedHandleSearch,
        };
    },
});
