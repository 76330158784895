import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { ZnInput } from '~/modules/b2b/components';
import { useClipboard } from '~/modules/b2b/composables';
import ZnPromoCard from './ZnPromoCard.vue';
export default defineComponent({
    name: 'CouponSection',
    components: {
        ZnInput,
        ZnPromoCard,
    },
    props: {
        couponCode: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const { copyToClipboard } = useClipboard();
        const promoCopiedShow = ref(false);
        const promoCode = computed(() => props.couponCode);
        const copyPromoCode = () => {
            if (props.couponCode) {
                copyToClipboard(props.couponCode);
                promoCopiedShow.value = true;
                setTimeout(() => {
                    promoCopiedShow.value = false;
                }, 2000);
            }
        };
        return {
            copyPromoCode,
            promoCode,
            promoCopiedShow,
        };
    },
});
