export const ShippingOptionDefaults = [
    {
        amount: {
            currency: 'USD',
            value: 5,
            __typename: 'Money',
        },
        available: true,
        carrier_code: 'flatrateone',
        carrier_title: 'standard',
        error_message: '',
        method_code: 'flatrate',
        method_title: 'standard',
        price_excl_tax: {
            currency: 'USD',
            value: 5,
            __typename: 'Money',
        },
        price_incl_tax: {
            currency: 'USD',
            value: 5,
            __typename: 'Money',
        },
        __typename: 'AvailableShippingMethod',
    },
    {
        amount: {
            currency: 'USD',
            value: 19,
            __typename: 'Money',
        },
        available: true,
        carrier_code: 'flatratetwo',
        carrier_title: 'expedited',
        error_message: '',
        method_code: 'flatrate',
        method_title: 'expedited',
        price_excl_tax: {
            currency: 'USD',
            value: 19,
            __typename: 'Money',
        },
        price_incl_tax: {
            currency: 'USD',
            value: 19,
            __typename: 'Money',
        },
        __typename: 'AvailableShippingMethod',
    },
];
export const engToKanjiPrefectures = [
    {
        eng: 'Aichi',
        kanji: '愛知県',
    },
    {
        eng: 'Akita',
        kanji: '秋田県',
    },
    {
        eng: 'Aomori',
        kanji: '青森県',
    },
    {
        eng: 'Chiba',
        kanji: '千葉県',
    },
    {
        eng: 'Ehime',
        kanji: '愛媛県',
    },
    {
        eng: 'Fukui',
        kanji: '福井県',
    },
    {
        eng: 'Fukuoka',
        kanji: '福岡県',
    },
    {
        eng: 'Fukushima',
        kanji: '福島県',
    },
    {
        eng: 'Gifu',
        kanji: '岐阜県',
    },
    {
        eng: 'Gunma',
        kanji: '群馬県',
    },
    {
        eng: 'Hiroshima',
        kanji: '広島県',
    },
    {
        eng: 'Hokkaidō',
        kanji: '北海道',
    },
    {
        eng: 'Hyōgo',
        kanji: '兵庫県',
    },
    {
        eng: 'Ibaraki',
        kanji: '茨城県',
    },
    {
        eng: 'Ishikawa',
        kanji: '石川県',
    },
    {
        eng: 'Iwate',
        kanji: '岩手県',
    },
    {
        eng: 'Kagawa',
        kanji: '香川県',
    },
    {
        eng: 'Kagoshima',
        kanji: '鹿児島県',
    },
    {
        eng: 'Kanagawa',
        kanji: '神奈川県',
    },
    {
        eng: 'Kochi',
        kanji: '高知県',
    },
    {
        eng: 'Kumamoto',
        kanji: '熊本県',
    },
    {
        eng: 'Kyōto',
        kanji: '京都府',
    },
    {
        eng: 'Kōchi',
        kanji: '高知県',
    },
    {
        eng: 'Mie',
        kanji: '三重県',
    },
    {
        eng: 'Miyagi',
        kanji: '宮城県',
    },
    {
        eng: 'Miyazaki',
        kanji: '宮崎県',
    },
    {
        eng: 'Nagano',
        kanji: '長野県',
    },
    {
        eng: 'Nagasaki',
        kanji: '長崎県',
    },
    {
        eng: 'Nara',
        kanji: '奈良県',
    },
    {
        eng: 'Niigata',
        kanji: '新潟県',
    },
    {
        eng: 'Ōita',
        kanji: '大分県',
    },
    {
        eng: 'Okayama',
        kanji: '岡山県',
    },
    {
        eng: 'Okinawa',
        kanji: '沖縄県',
    },
    {
        eng: 'Ōsaka',
        kanji: '大阪府',
    },
    {
        eng: 'Saga',
        kanji: '佐賀県',
    },
    {
        eng: 'Saitama',
        kanji: '埼玉県',
    },
    {
        eng: 'Shiga',
        kanji: '滋賀県',
    },
    {
        eng: 'Shimane',
        kanji: '島根県',
    },
    {
        eng: 'Shizuoka',
        kanji: '静岡県',
    },
    {
        eng: 'Tochigi',
        kanji: '栃木県',
    },
    {
        eng: 'Tokushima',
        kanji: '徳島県',
    },
    {
        eng: 'Tokyo',
        kanji: '東京都',
    },
    {
        eng: 'Tottori',
        kanji: '鳥取県',
    },
    {
        eng: 'Toyama',
        kanji: '富山県',
    },
    {
        eng: 'Wakayama',
        kanji: '和歌山県',
    },
    {
        eng: 'Yamagata',
        kanji: '山形県',
    },
    {
        eng: 'Yamaguchi',
        kanji: '山口県',
    },
    {
        eng: 'Yamanashi',
        kanji: '山梨県',
    },
];
