import moji from 'moji';
import { kanaToRomaji, isJapanese, fullStringToHalf } from '../../libs/jp-romanji';
import { engToKanjiPrefectures } from './defaults';
export const sanitizeAddress = (address) => {
    var _a;
    const stateAsJp = (_a = engToKanjiPrefectures.find((prefecture) => address.state.includes(prefecture.eng))) === null || _a === void 0 ? void 0 : _a.kanji;
    const stateRegex = new RegExp(`(${stateAsJp !== null && stateAsJp !== void 0 ? stateAsJp : address.state})`, 'g');
    const cityRegex = new RegExp(`(${address.city})`, 'g');
    return `${address.address}${address.address2 ? ` ${address.address2}` : ''}`
        .replace('〒', '') // remove postal code symbol
        .replace(address.zipCode, '').replace(moji(address.zipCode).convert('ZE', 'HE').toString() || '', '') // remove postal code, might be in plain xxx-xxxx or in special characters 'full-width or double-byte characters' like １２３－４５６７
        .replace(stateRegex, '')
        .replace(cityRegex, '')
        .replace(/^\s+/, '') // trim left spaces
        .replace(/\s{2,}/g, ' '); // replace any +2 spaces with 1 space
};
export const getJpState = (address) => {
    var _a;
    const stateAsJp = (_a = engToKanjiPrefectures.find((prefecture) => address.state.includes(prefecture.eng))) === null || _a === void 0 ? void 0 : _a.kanji;
    return stateAsJp !== null && stateAsJp !== void 0 ? stateAsJp : address.state;
};
export const useJpAddressTranslation = () => {
    /*
      Links used for reference:
        https://wagaya-japan.com/en/journal_detail.php?id=5636
        https://resources.realestate.co.jp/living/how-to-read-a-japanese-address/
        https://www.realestate-tokyo.com/living-in-tokyo/japan-info/japanese-address/
        https://en.wikipedia.org/wiki/Japanese_addressing_system
  
        https://ling-app.com/ja/japanese-addresses/ --> look at kyoto section where it describe above, below, east, west, etc
    */
    const translateAddress = async (address) => {
        var _a;
        /* address field might have the prefecture, city and zipcode included. remove them first and finally trim left spaces
          Example --> Address: 〒106-0044東京都港区東麻布1-8-1 東麻布ISビル4F || 〒106-0044 東京 都港区 東麻布1-8-1 東麻布ISビル4F
                      Address: 〒160-0017東京都新宿区左門町21-2. 左門町 \\ 〒160-0017 東京都 新宿区 左門町21-2. 左門町
                        PostalCode: 〒106-0044
                        Prefecture (State): 東京 // Always ends in one of 県 (ken) ; To (都) ; Fu (府) ; Dou (道)
                        Municipality (City): 都港区 // Always ends in 市 (shi) ; Gun (郡) ; Ku (区)
                        Town (Area): 左門町 // Always ends in 町 (cho) ; Machi (町) ; Mura (村) ; Cho (町) ; Son (村) // Can be empty and is mostly used for rural areas
                        Address: 東麻布1-8-1 // first part is always the town area, second part is the block number and might be using kanji like 1丁目8番地1号 --> 丁目 (chome) ; 番地 (banchi) ; 号 (go)
                        Building Name: 東麻布ISビル
                        Floor Number: 4F // Can be empty and can ends in F or 階 (kai). The floor number will always follow the building name.
        */
        const stateAsJp = (_a = engToKanjiPrefectures.find((prefecture) => address.state.includes(prefecture.eng))) === null || _a === void 0 ? void 0 : _a.kanji;
        const state = await kanaToRomaji(stateAsJp !== null && stateAsJp !== void 0 ? stateAsJp : address.state, 'spaced');
        const city = await kanaToRomaji(address.city, 'spaced');
        const translatedAddress = await kanaToRomaji(sanitizeAddress(address), 'spaced');
        return {
            address: translatedAddress.replace(/\s{2,}/g, ' '),
            zipCode: fullStringToHalf(address.zipCode),
            state: (state === null || state === void 0 ? void 0 : state.replace(' ', '-')) || address.state,
            city: city.replace(' ', '-'),
            firstName: await kanaToRomaji(address.firstName),
            lastName: await kanaToRomaji(address.lastName),
            country: 'JP',
        };
    };
    const needTranslation = (address) => Object.keys(address).some((key) => address[key] && isJapanese(address[key]));
    return {
        getJpState,
        needTranslation,
        sanitizeAddress,
        translateAddress,
    };
};
