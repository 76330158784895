const middleware = async (context) => {
    var _a, _b, _c;
    const { $cookies } = context;
    const cartId = $cookies.get('vsf-cart');
    if (!cartId) {
        context.redirect({ path: '/' });
    }
    const cart = await context.$vsf.$magento.api.cart(cartId);
    if (((_c = (_b = (_a = cart === null || cart === void 0 ? void 0 : cart.data) === null || _a === void 0 ? void 0 : _a.cart) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.length) <= 0) {
        context.redirect({ path: '/' });
    }
};
export default middleware;
