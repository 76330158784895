import { defineComponent, useContext } from '@nuxtjs/composition-api';
import { ZnTextButton } from '..';
export default defineComponent({
    name: 'HaveAQuestion',
    components: {
        ZnTextButton,
    },
    props: {
        chatLink: {
            type: String,
            default: '',
        },
        chatTarget: {
            type: String,
            default: '_blank',
        },
        emailLink: {
            type: String,
            default: 'https://www.zennioptical.com/help/contactsupport',
        },
        emailTarget: {
            type: String,
            default: '_blank',
        },
        contactItems: {
            type: Array,
            default: () => [],
        },
        chatContactItems: {
            type: Array,
            default: () => [],
        },
    },
    setup() {
        // @ts-ignore
        const { $gladlyShow } = useContext();
        const openChat = () => {
            $gladlyShow();
        };
        return {
            openChat,
        };
    },
});
