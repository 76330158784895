import { useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
const cache = new Map();
/**
 * Allows executing arbitrary GraphQL queries and mutations.
 *
 * See the {@link UseApiInterface} for a list of methods and values available in this composable.
 */
export function useApi() {
    const context = useContext();
    // @ts-ignore
    const query = async (request, variables, fetchPolicy) => {
        const reqID = `id${Math.random().toString(16).slice(2)}`;
        Logger.debug(`customQuery/request/${reqID}`, request);
        const { data, errors } = await context.app.$vsf.$magento.api.customQuery({ query: request, queryVariables: variables, fetchPolicy });
        Logger.debug(`customQuery/result/${reqID}`, { data, errors });
        return { data, errors };
    };
    // @ts-ignore
    const mutate = async (request, variables) => {
        const reqID = `id${Math.random().toString(16).slice(2)}`;
        Logger.debug(`customQuery/request/${reqID}`, request);
        const { data, errors } = await context.app.$vsf.$magento.api.customMutation({ mutation: request, mutationVariables: variables });
        Logger.debug(`customQuery/result/${reqID}`, { data, errors });
        return { data, errors };
    };
    return { query, mutate };
}
export default useApi;
