import { Logger } from '~/helpers/logger';
export const loadCustomerReviewsCommand = {
    execute: async (context, params) => {
        var _a, _b;
        Logger.debug('[Magento] load customer review based on:', { params });
        const { data } = await context.$magento.api.customerProductReview(params, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
        Logger.debug('[Result]:', { data });
        return (_b = data === null || data === void 0 ? void 0 : data.customer) !== null && _b !== void 0 ? _b : {};
    },
};
