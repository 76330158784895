import { computed, defineComponent, } from '@nuxtjs/composition-api';
import { useUrlLanguageParser } from '~/modules/b2b/localization/composables/useUrlLanguageParser';
export default defineComponent({
    name: 'AccordionItem',
    props: {
        item: {
            type: Object,
            default: () => { },
        },
        selected: {
            type: Boolean,
            default: false,
        },
        dataTestPrefix: {
            type: String,
            required: false,
            default: 'faq-accordion',
        },
    },
    setup(props) {
        const { storeCode } = useUrlLanguageParser();
        const isVisible = computed(() => { var _a, _b; return (_b = (_a = props.item) === null || _a === void 0 ? void 0 : _a.visibleStores) === null || _b === void 0 ? void 0 : _b.includes(storeCode.toUpperCase()); });
        return {
            isVisible,
        };
    },
});
