import { SfLoader } from '@storefront-ui/vue';
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useReviews } from '~/modules/b2b/composables';
import { ZnRating, ZnButton } from '~/modules/b2b/components';
import { Divider, starSvgPath } from '~/modules/b2b/components/beast';
import ReviewCard from './ReviewCard.vue';
export default defineComponent({
    name: 'ReviewSection',
    components: {
        ReviewCard,
        Divider,
        SfLoader,
        ZnRating,
        ZnButton,
    },
    props: {
        sku: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { reviews, max, score, recommendPercent, totalReviews, loading, getReviews, getNextPage, MIN_REVIEWS_TO_SHOW, } = useReviews(props.sku);
        onMounted(async () => {
            await getReviews();
        });
        const showMoreReviews = async () => {
            await getNextPage();
        };
        return {
            max,
            reviews,
            score,
            loading,
            starSvgPath,
            recommendPercent,
            MIN_REVIEWS_TO_SHOW,
            showMoreReviews,
            totalReviews,
        };
    },
});
