import { ref, defineComponent } from '@nuxtjs/composition-api';
import { useCamera } from '~/modules/b2b/prescription';
export default defineComponent({
    name: 'OcrComponent',
    props: {
        title: {
            type: String,
            default: 'Scan prescription',
        },
        subtitle: {
            type: String,
            default: 'Center the prescription information within the grid and take a picture.',
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        closePage: {
            type: Function,
            default: () => { },
        },
    },
    emits: ['capture', 'error'],
    setup(_, { emit }) {
        const cameraElement = ref(null);
        const { deviceId, onCameras, onCameraChange, onCapture, } = useCamera({ cameraElement, emit });
        return {
            deviceId,
            cameraElement,
            onCameras,
            onCapture,
            onCameraChange,
        };
    },
});
