import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import { setShippingMethodsOnCartCommand } from '~/modules/checkout/composables/useShippingProvider/commands/setShippingMethodsOnCartCommand';
/**
 * Allows loading the shipping provider
 * for the current cart and saving (selecting) other shipping provider for the
 * same cart.
 *
 * See the {@link UseShippingProviderInterface} for a list of methods and values available in this composable.
 */
export function useShippingProvider() {
    const loading = ref(false);
    const error = ref({
        load: null,
        save: null,
    });
    const { cart, setCart, load: loadCart } = useCart();
    const context = useContext();
    const save = async ({ shippingMethod, customQuery = null, customHeaders = {} }) => {
        Logger.debug('useShippingProvider.save');
        let result = null;
        try {
            loading.value = true;
            const shippingMethodParams = {
                cart_id: cart.value.id,
                shipping_methods: [shippingMethod],
            };
            const cartData = await setShippingMethodsOnCartCommand.execute(context, shippingMethodParams, { ...customQuery, setShippingMethodsOnCart: 'setShippingMethodsOnCart' }, customHeaders);
            Logger.debug('[Result]:', { cartData });
            setCart(cartData);
            result = cartData.shipping_addresses[0].selected_shipping_method;
            error.value.save = null;
        }
        catch (err) {
            error.value.save = err;
            Logger.error('useShippingProvider/save', err);
        }
        finally {
            loading.value = false;
        }
        return result;
    };
    const load = async ({ customQuery = null, customHeaders = {} } = {}) => {
        var _a, _b, _c, _d;
        Logger.debug('useShippingProvider.load');
        let result = null;
        try {
            loading.value = true;
            if (!((_b = (_a = cart === null || cart === void 0 ? void 0 : cart.value) === null || _a === void 0 ? void 0 : _a.shipping_addresses[0]) === null || _b === void 0 ? void 0 : _b.selected_shipping_method)) {
                await loadCart({ customQuery, customHeaders });
            }
            result = (_d = (_c = cart.value) === null || _c === void 0 ? void 0 : _c.shipping_addresses[0]) === null || _d === void 0 ? void 0 : _d.selected_shipping_method;
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useShippingProvider/load', err);
        }
        finally {
            loading.value = false;
        }
        return result;
    };
    return {
        load,
        save,
        error: readonly(error),
        loading: readonly(loading),
    };
}
export * from './useShippingProvider';
export default useShippingProvider;
