//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch, computed } from '@nuxtjs/composition-api';
import masker from '../../directives/mask/masker';
import tokens from '../../directives/mask/tokens';

export default {
  name: 'ZnInput',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    title: {
      type: String,
      default: undefined,
      required: false,
    },
    name: {
      type: String,
      default: undefined,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
      required: false,
    },
    id: {
      type: String,
      default: undefined,
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: [Array, Function],
      required: false,
      default: undefined,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredMessage: {
      type: String,
      required: false,
      default: undefined,
    },
    mask: {
      type: [String, Array],
      required: false,
      default: undefined,
    },
    valueMasked: {
      type: Boolean,
      required: false,
      default: false,
    },
    tokens: {
      type: Object,
      required: false,
      default: () => tokens,
    },
    classes: {
      type: String,
      required: false,
      default: undefined,
    },
    inputClass: {
      type: String,
      required: false,
      default: undefined,
    },
    errors: {
      type: Array,
      required: false,
      default: undefined,
    },
    dataTestPrefix: {
      type: String,
      required: false,
      default: 'zn-input',
    },
  },
  emits: ['change', 'input', 'onValidate', 'focus'],
  setup(props, { emit }) {
    const errorsData = ref(props.errors);

    const maskData = ref(props.mask);

    const display = ref(props.value);

    watch(
      () => props.mask,
      (newValue) => {
        if (maskData.value && !newValue) {
          display.value = masker(
            display.value,
            maskData.value,
            false,
            props.tokens,
          );
        } else if (
          (!maskData.value && newValue)
          || maskData.value !== newValue
        ) {
          display.value = masker(display.value, newValue, true, props.tokens);
        }
        maskData.value = newValue;
      },
    );

    watch(
      () => props.value,
      (newValue) => {
        // clean errors
        errorsData.value = [];
        display.value = maskData.value
          ? masker(newValue, maskData.value, true, props.tokens)
          : newValue;
      },
    );

    watch(
      () => props.errors,
      (newValue) => {
        errorsData.value = newValue;
      },
    );

    const validateRules = (val) => {
      errorsData.value = [];
      const value = val || props.value;
      if (props.required && !value) {
        let errorToAdd = 'This field is required';
        if (props.requiredMessage) {
          errorToAdd = props.requiredMessage;
        } else if (props.title) {
          errorToAdd = `${props.title} is required.`;
        }
        errorsData.value.push(errorToAdd);
        emit('onValidate', errorsData.value);
        return;
      }

      if (!props.rules || (!props.required && !value)) {
        emit('onValidate', errorsData.value);
        return;
      }

      if (Array.isArray(props.rules)) {
        props.rules.forEach((rule) => validateRule(rule, value));
      } else {
        validateRule(props.rules, value);
      }
      emit('onValidate', errorsData.value);
    };

    const validateRule = (rule, value) => {
      const ruleReturn = rule(value);
      if (ruleReturn !== true) {
        errorsData.value.push(ruleReturn);
      }
    };

    const onInputEvent = ($event) => {
      let { value } = $event.target;
      if (maskData.value) {
        value = masker(value, maskData.value, props.valueMasked, props.tokens);
        display.value = masker(value, maskData.value, true, props.tokens);
        $event.target.value = display.value;
      } else {
        display.value = value;
      }
      emit('input', value);
    };

    const onChangeEvent = ($event) => {
      let newValue = $event.target.value;
      /* if type is email then remove spaces */
      if (props.type === 'email') {
        newValue = newValue.trim();
        $event.target.value = newValue;
      }
      if (maskData.value && !props.valueMasked) {
        newValue = masker(newValue, maskData.value, false, props.tokens);
      }
      validateRules(newValue);
      emit('change', newValue);
    };

    const onFocusEvent = (event) => {
      errorsData.value = [];
      emit('focus', event);
    };

    return {
      display,
      errorsData,
      maskData,
      onChangeEvent,
      onFocusEvent,
      onInputEvent,
      validateRules,
    };
  },
};
