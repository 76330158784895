import VueScrollTo from 'vue-scrollto';
import { defineComponent } from '@nuxtjs/composition-api';
import { useReviews } from '~/modules/b2b/composables';
import { ZnRating } from '~/modules/b2b/components';
export default defineComponent({
    name: 'PdpRating',
    components: {
        ZnRating,
    },
    props: {
        sku: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const { max, score, totalReviews, MIN_REVIEWS_TO_SHOW, } = useReviews(props.sku);
        const scrollToReviews = () => {
            VueScrollTo.scrollTo('#reviews-section', 500, {
                offset: -100,
            });
        };
        return {
            max,
            score,
            totalReviews,
            scrollToReviews,
            MIN_REVIEWS_TO_SHOW,
        };
    },
});
