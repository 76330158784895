import VueScrollTo from 'vue-scrollto';
import { useContentful } from '~/composables';
import { defineComponent, computed, watch, nextTick, useFetch, ref, } from '@nuxtjs/composition-api';
import { ZnButton } from '~/modules/b2b/components';
import { usePdpFlow } from '~/modules/b2b/prescription/composables';
import { AddPrescriptionForm, PrescriptionManual, PrescriptionTypeSelect, UpgradeLens, } from '~/modules/b2b/prescription/components';
import LensCoatingTypeSelect from '~/modules/b2b/prescription/components/select-lens-coating/index.vue';
export default defineComponent({
    name: 'PdpPrescriptionFlow',
    components: {
        LensCoatingTypeSelect,
        ZnButton,
        AddPrescriptionForm,
        PrescriptionManual,
        PrescriptionTypeSelect,
        UpgradeLens,
    },
    props: {
        product: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    emits: ['loading', 'select', 'data'],
    setup(props, { emit }) {
        const { search, content: pdpFlow } = useContentful('pdpPrescriptionFlow');
        const loading = ref({});
        const { setGroupData, components, clearFlowData, } = usePdpFlow(pdpFlow);
        const groupIds = computed(() => components.value.map((group) => group.id));
        const computedLoading = computed(() => Object.keys(loading.value).some((key) => loading.value[key]));
        useFetch(async () => {
            await search({
                custom: {
                    type: 'pdpPrescriptionFlow',
                },
            });
        });
        watch(components, (newComponents, oldComponents) => {
            // If the user has some components selected, scroll to the last one
            if ((newComponents === null || newComponents === void 0 ? void 0 : newComponents.length) > 1 && (oldComponents === null || oldComponents === void 0 ? void 0 : oldComponents.length) === 0) {
                const lastEnabledComponent = newComponents.filter((component) => component.enabled).slice(-1)[0];
                const index = newComponents.indexOf(lastEnabledComponent);
                // Wait for the DOM to be updated
                if (index !== 0) {
                    nextTick(() => {
                        VueScrollTo.scrollTo(`#${lastEnabledComponent.id}`, 500, {
                            offset: -180, // 180 best fit for both mobile and desktop
                        });
                    });
                }
            }
        });
        watch(computedLoading, (val) => {
            emit('loading', val);
        });
        const goToNext = ({ currentId, skip = false, nextEvent }) => {
            const currentIndex = groupIds.value.indexOf(currentId);
            if (currentIndex === -1) {
                return;
            }
            const nextIndex = skip ? currentIndex + 2 : currentIndex + 1;
            if (nextIndex >= groupIds.value.length) {
                return;
            }
            const nextCardGroup = nextEvent ? `#${nextEvent}` : `#${groupIds.value[nextIndex]}`;
            VueScrollTo.scrollTo(nextCardGroup, 500, {
                offset: -180, // 180 best fit for both mobile and desktop
            });
        };
        const onGroupSelect = ({ select, id, price, nextEvent, }) => {
            setGroupData({
                groupId: id, data: { select }, price, nextEvent,
            });
            goToNext({ currentId: id, nextEvent });
            emit('select', { select, id });
        };
        const onGroupData = ({ data, id, select, nextEvent, }) => {
            setGroupData({ groupId: id, data: { select, data }, nextEvent });
            goToNext({ currentId: id, nextEvent });
            emit('data', { data, id, select });
        };
        const onGroupLoading = ({ id, loading: groupLoading }) => {
            loading.value = {
                ...loading.value,
                [id]: groupLoading,
            };
        };
        const onClear = () => {
            clearFlowData();
            VueScrollTo.scrollTo('body', 500, {
                offset: -100,
            });
        };
        return {
            loading,
            components,
            computedLoading,
            onGroupLoading,
            onGroupSelect,
            onGroupData,
            onClear,
        };
    },
});
