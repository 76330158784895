import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '~/modules/customer/helpers/userAddressManipulator';
/**
 * @public
 *
 * Allows loading and manipulating addresses of the current user. These
 * addresses can be used for both billing and shipping.
 *
 * See the {@link UseAddressesInterface} for a list of methods and values available in this composable.
 */
export function useAddresses() {
    const error = ref({
        load: null,
        save: null,
        remove: null,
        update: null,
    });
    const loading = ref(false);
    const { app } = useContext();
    const context = app.$vsf;
    const load = async (customQuery, customHeaders) => {
        var _a, _b;
        Logger.debug('[Magento] load user addresses');
        let results = null;
        try {
            loading.value = true;
            const { data } = await context.$magento.api.getCustomerAddresses(customQuery, customHeaders);
            results = (_b = (_a = data === null || data === void 0 ? void 0 : data.customer) === null || _a === void 0 ? void 0 : _a.addresses) !== null && _b !== void 0 ? _b : [];
            Logger.debug('[Magento] load user addresses results:', results);
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('Magento] load user addresses error', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    const save = async (params) => {
        var _a, _b;
        Logger.debug('[Magento] save user address:', params.address);
        let results = null;
        try {
            loading.value = true;
            const { data } = await context.$magento.api.createCustomerAddress(transformUserCreateAddressInput(params), (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            results = (_b = data === null || data === void 0 ? void 0 : data.createCustomerAddress) !== null && _b !== void 0 ? _b : {};
            Logger.debug('[Magento] save user address results:', params.address);
            error.value.save = null;
        }
        catch (err) {
            error.value.save = err;
            Logger.error('[Magento] save user address error:', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    const update = async (params) => {
        var _a, _b;
        Logger.debug('[Magento] update user address:', params.address);
        let results = null;
        try {
            loading.value = true;
            const { data } = await context.$magento.api.updateCustomerAddress(transformUserUpdateAddressInput(params), (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            results = (_b = data === null || data === void 0 ? void 0 : data.updateCustomerAddress) !== null && _b !== void 0 ? _b : {};
            Logger.debug('[Magento] update user address results:', results);
            error.value.update = null;
        }
        catch (err) {
            error.value.update = err;
            Logger.error('[Magento] update user address error:', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    const remove = async (params) => {
        var _a;
        Logger.debug('[Magento] remove user address:', params.address);
        let results = null;
        try {
            loading.value = true;
            const { data } = await context.$magento.api.deleteCustomerAddress(params.address.id, (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, params === null || params === void 0 ? void 0 : params.customHeaders);
            results = !!data.deleteCustomerAddress;
            Logger.debug('[Magento] remove user address results:', results);
            error.value.remove = null;
        }
        catch (err) {
            error.value.remove = err;
            Logger.error('[Magento] remove user address error:', err);
        }
        finally {
            loading.value = false;
        }
        return results;
    };
    return {
        error,
        loading,
        load,
        save,
        update,
        remove,
    };
}
export * from './useAddresses';
export default useAddresses;
