const beastHarcodedStores = ['us', 'ca', 'gb', 'jp', 'eu', 'au'];
const findLocaleBasedOnMagentoStoreCode = (storeCode, locales) => [...beastHarcodedStores, ...locales].find((locale) => ((typeof locale === 'object' ? locale.code : locale) === storeCode));
const findCurrencyBasedOnMagentoStoreCode = (storeCode, locales) => {
    const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode);
    return match === null || match === void 0 ? void 0 : match.defaultCurrency;
};
export default ({ app, route }) => app.$vsf.$magento.client.interceptors.request.use(async (request) => {
    var _a;
    const { $vsf: { $magento: { config: { state } } }, i18n, } = app;
    const currentStoreCode = (_a = app.$vsf.$magento.config.state.getStore()) !== null && _a !== void 0 ? _a : route.path.split('/')[1]; // localhost:3000/default
    const shouldSetDefaultLocale = !currentStoreCode || !findLocaleBasedOnMagentoStoreCode(currentStoreCode, i18n.locales);
    if (shouldSetDefaultLocale) {
        await i18n.setLocale(i18n.defaultLocale);
    }
    const i18nCurrentLocaleCode = i18n.locale.replace('/fr', '')
        .replace('/en', '')
        .replace('/ja', '')
        .replace('/gb', '')
        .replace('/au', '')
        .replace('/de', '');
    const shouldLocaleBeRefreshed = i18nCurrentLocaleCode !== state.getLocale();
    if (shouldLocaleBeRefreshed) {
        const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);
        state.setStore(i18nCurrentLocaleCode);
        state.setLocale(i18nCurrentLocaleCode);
        state.setCurrency(currency);
        // in beast project, a locale change is also a store change, we should clean the cart id
        state.removeCartId();
    }
    return request;
});
