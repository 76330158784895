import axios from 'axios';
import { createExtendIntegrationInCtx, createAddIntegrationToCtx } from './context';
import { getIntegrationConfig, createProxiedApi } from './_proxyUtils';
const parseCookies = (cookieString) => Object.fromEntries(cookieString
    .split(';')
    // eslint-disable-next-line unicorn/no-array-callback-reference
    .filter(String)
    .map((item) => item.split('=').map((part) => part.trim()))
    .map(([name, value]) => [name, value]));
const setCookieValues = (cookieValues, cookieString = '') => {
    const parsed = parseCookies(cookieString);
    // eslint-disable-next-line no-return-assign
    Object.entries(cookieValues).forEach(([name, value]) => parsed[name] = value);
    return Object.entries(parsed).map(([name, value]) => `${name}=${value}`).join('; ');
};
export const integrationPlugin = (pluginFn) => (nuxtCtx, inject) => {
    const configure = (tag, configuration) => {
        const injectInContext = createAddIntegrationToCtx({ tag, nuxtCtx, inject });
        const config = getIntegrationConfig(nuxtCtx, configuration);
        const { middlewareUrl, ssrMiddlewareUrl } = nuxtCtx.$config;
        if (middlewareUrl || ssrMiddlewareUrl) {
            config.axios.baseURL = process.server ? ssrMiddlewareUrl || middlewareUrl : middlewareUrl;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const client = axios.create(config.axios);
        const api = createProxiedApi({
            givenApi: configuration.api || {}, client, tag, nuxtCtx,
        });
        if (nuxtCtx.app.i18n.cookieValues) {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            client.defaults.headers.cookie = setCookieValues(nuxtCtx.app.i18n.cookieValues, client.defaults.headers.cookie);
        }
        injectInContext({ api, client, config });
    };
    const extend = (tag, integrationProperties) => {
        createExtendIntegrationInCtx({ tag, nuxtCtx, inject })(integrationProperties);
    };
    const integration = { configure, extend };
    pluginFn({ ...nuxtCtx, integration }, inject);
};
