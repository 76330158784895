export const PrescriptionManualDefaults = {
    title: 'Prescription info',
    odTitle: 'OD (Right Eye)',
    osTitle: 'OS (Left Eye)',
    sphereLabel: 'SPH',
    cylindersLabel: 'CYL',
    axisLabel: 'Axis',
    showSphere: true,
    showCylinders: true,
    showAxis: true,
    buttonColor: 'primary',
    buttonText: 'Continue',
};
export const PrescriptionTypeDefaults = {
    title: '',
    options: [
        {
            fields: {
                title: 'Prescription',
                description: '',
                price: '49.99',
                selected: false,
                disabled: false,
            },
        },
        {
            fields: {
                title: 'Non-prescription',
                description: '',
                price: '24.99',
                selected: false,
                disabled: false,
            },
        },
    ],
};
export const UpgradeLensDefaults = {
    title: 'Upgrade your lens',
    options: [
        {
            fields: {
                title: 'Blue light blocking lenses',
                description: 'Get Single Vision prescription lenses that fit perfectly in your Hypnos headset for a sharper and clearer experience.',
                selected: false,
                disabled: false,
            },
        },
        {
            fields: {
                title: 'Skip',
                selected: false,
                disabled: false,
            },
        },
    ],
};
export const PrescriptionSelectDefaults = {
    title: '',
    options: [
        {
            fields: {
                title: 'Prescription',
                selected: false,
                disabled: false,
            },
        },
        {
            fields: {
                title: 'Non-prescription',
                selected: false,
                disabled: false,
            },
        },
    ],
    autoselect: 0,
};
export const LensCoatingTypeDefaults = {
    title: 'Choose your lens coating',
    options: [
        {
            fields: {
                title: 'Anti-fog',
                description: 'Prevent lens fogging during gameplay & exercise. Includes anti-reflective coating.',
                price: '<span style="color: #007B8F">Free </span><span style="text-decoration: line-through;">+ $9.00</span>',
                selected: false,
                disabled: false,
                nextEvent: 'goToNextCardGroup',
                tags: [
                    {
                        fields: {
                            name: 'Great for active use',
                            label: 'Great for active use',
                            color: 'bg-blue-tag',
                            textColor: 'text-blue-tag-text',
                        },
                    },
                ],
                id: 'anti-fog',
            },
        },
        {
            fields: {
                title: 'Oil-resistant coating',
                description: 'Avoid fingerprints to keep your lenses clear. Includes anti-reflective, water-resistant, and anti-scratch coating.',
                price: '<span style="color: #007B8F">Free </span><span style="text-decoration: line-through;">+ $9.00</span>',
                selected: false,
                disabled: false,
                tags: [
                    {
                        fields: {
                            name: 'Great for families',
                            label: 'Great for families',
                            color: 'bg-blue-tag',
                            textColor: 'text-blue-tag-text',
                        },
                    },
                ],
                id: 'oleophobic-anti-reflective-coating',
            },
        },
    ],
    autoselect: 0,
};
export const AddPrescriptionFormDefaults = {
    name: 'Add Prescription Form',
    title: 'Let’s get your prescription',
    options: [
        {
            fields: {
                title: 'Enter manually',
                id: '32',
                description: 'Enter your glasses prescription manually.',
                component: 'ZnCard',
            },
        },
        {
            fields: {
                title: 'Take a picture',
                id: '1',
                description: 'Scan a prescription using your device camera',
                component: 'TakePictureCard',
            },
        },
        {
            fields: {
                title: 'Upload a prescription',
                id: '2',
                description: 'Upload a prescription from your device',
                component: 'ZnCard',
            },
        },
        {
            fields: {
                title: 'Provide it later',
                id: '2',
                description: 'Upload a prescription from your device',
                component: 'ZnCard',
                nextEvent: 'upgrade-lens',
            },
        },
    ],
};
export const PdpFaqDefaults = {
    title: 'FAQs',
    description: 'Everything you need to know about the product and how it works. Can’t find the answer? Chat with our team',
    items: [
        {
            fields: {
                component: 'accordion 1',
                title: 'What is the prescription range?',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco      ',
            },
        },
        {
            fields: {
                component: 'accordion 2',
                title: 'Do I need my pupillary distance (PD)?',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco      ',
            },
        },
        {
            fields: {
                component: 'accordion 3',
                title: 'What is included in my order?',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
            },
        },
        {
            fields: {
                component: 'accordion 4',
                title: 'How do I install my lens inserts?',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
            },
        },
        {
            fields: {
                component: 'accordion 5',
                title: 'How can I return my order?',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco',
            },
        },
    ],
};
export const ShippingOptionDefaults = {
    title: 'Shipping options',
    options: [
        {
            fields: {
                title: 'Standard',
                description: 'Estimated arrival Friday, May 12',
                price: 'Free',
                selected: true,
                disabled: false,
            },
        },
        {
            fields: {
                title: 'Express',
                description: 'Estimated arrival Tuesday, May 9',
                price: '$8.99',
                selected: false,
                disabled: false,
            },
        },
    ],
};
export const ContactInfoDefaults = {
    name: 'ContactInfo',
    title: 'Contact Info',
    items: [
        {
            fields: {
                label: 'Email',
                type: 'email',
                model: 'email',
            },
            sys: {
                contentType: {
                    sys: {
                        id: 'textInput',
                    },
                },
            },
        },
        {
            fields: {
                label: 'Phone number (optional)',
                type: 'mobile',
                model: 'phoneNumber',
            },
            sys: {
                contentType: {
                    sys: {
                        id: 'textInput',
                    },
                },
            },
        },
        {
            fields: {
                label: 'Yes, I want to receive marketing emails.',
                model: 'marketing',
            },
            sys: {
                contentType: {
                    sys: {
                        id: 'checkbox',
                    },
                },
            },
        },
        {
            fields: {
                label: 'Receive order updates by text (standard rates apply)',
                model: 'updatesByText',
            },
            sys: {
                contentType: {
                    sys: {
                        id: 'checkbox',
                    },
                },
            },
        },
    ],
};
export const ShippingAddressDefaults = {
    title: '',
    name: '',
    subtitle: '',
    totalspan: 6,
    items: [
        {
            fields: {
                name: 'country',
                label: 'Country',
                model: 'country',
                type: 'country',
                colspan: '6',
                options: [],
                component: 'zn-select',
                required: true,
                readonly: true,
            },
        },
        {
            fields: {
                name: 'firstName',
                label: 'First name',
                model: 'firstName',
                type: 'text',
                colspan: '3',
                component: 'zn-input',
                required: true,
            },
        },
        {
            fields: {
                name: 'lastName',
                label: 'Last Name',
                model: 'lastName',
                type: 'text',
                colspan: '3',
                component: 'zn-input',
                required: true,
            },
        },
        {
            fields: {
                name: 'address',
                label: 'Address',
                model: 'address',
                type: 'text',
                colspan: '6',
                component: 'zn-address',
                required: true,
            },
        },
        {
            fields: {
                name: 'address2',
                label: 'Address #2',
                model: 'address2',
                type: 'text',
                colspan: '6',
                component: 'zn-input',
            },
        },
        {
            fields: {
                name: 'city',
                label: 'City',
                model: 'city',
                type: 'text',
                colspan: '2',
                component: 'zn-input',
                required: true,
            },
        },
        {
            fields: {
                name: 'state',
                label: 'State',
                model: 'state',
                type: 'state',
                colspan: '2',
                options: [],
                component: 'zn-select',
                required: true,
            },
        },
        {
            fields: {
                name: 'zipCode',
                label: 'Zip Code',
                model: 'zipCode',
                type: 'text',
                colspan: '2',
                component: 'zn-input',
                required: true,
            },
        },
    ],
};
export const HaveAQuestionDefaults = {
    name: 'Have a question?',
    id: 'have-a-question',
    content: {
        chatLink: 'chat',
        emailLink: 'mailto:vrlens.support@zennioptical.com',
        chatTarget: '_blank',
        emailTarget: '_blank',
        contactItems: [
            '(800) 211- 2105',
            'English 5AM - 9PM PT',
            'Español & Français 5AM - 9PM PT',
        ],
        chatContactItems: [
            '10AM - 10PM PT',
            'Monday - Friday',
        ],
    },
};
export const IntallIstructions = {
    name: 'Install Instructions',
    id: 'install-instructions',
    content: [
        {
            step: 1,
            instructions: 'Position the headset with VR lenses facing you.',
            image: 'install/1.png',
        },
        {
            step: 2,
            instructions: 'Align lens inserts so L (left) and R (right) marks are upright at the bottom of each lens',
            image: 'install/2.png',
        },
        {
            step: 3,
            instructions: 'Gently push the lens inserts into the VR lens until you hear a click.',
            image: 'install/3.png',
        },
    ],
};
export const IntallIstructionsAntiFog = {
    name: 'Install Instructions Anti Fog',
    id: 'install-instructions-anti-fog',
    content: [
        {
            step: 1,
            instructions: 'Make sure both the VR lenses and the lens inserts are thoroughly clean.',
        },
        {
            step: 2,
            instructions: 'With the separate Anti-Fog Activator Cloth, lightly wipe your lens inserts 5-10 times in a circular motion on each side. Please note that the Activator cloth is only meant to be used on the Zenni VR lens inserts and not the Meta Quest 3 headset\'s lenses.',
        },
        {
            step: 3,
            instructions: 'Preserve the Anti-Fog Activator Cloth\'s longevity by storing it in the original, resealable pouch to preserve its performance. Keep the cloth dry and do not wash.',
        },
        {
            step: 4,
            instructions: 'Reactivate the anti-fog coating by repeating these steps whenever the effect diminishes.',
        },
    ],
};
