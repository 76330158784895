import { defineComponent, ref, computed, watch, useFetch, } from '@nuxtjs/composition-api';
import { useContentful } from '~/composables';
import { ZnCard, ZnCurrency } from '~/modules/b2b/components';
import { usePdpFlow } from '~/modules/b2b/prescription/composables';
import { PrescriptionTypeDefaults } from '~/composables/useContentful/defaults';
export default defineComponent({
    name: 'PrescriptionTypeSelect',
    components: {
        ZnCard,
        ZnCurrency,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'prescription-type-select',
        },
        product: {
            type: Object,
            required: false,
            default: undefined,
        },
    },
    setup(props, { emit }) {
        const cards = ref([]);
        const { search, content } = useContentful('selectPrescriptionType');
        const selectedItem = ref(null);
        const syncFlowData = (data) => {
            const state = data.find((d) => d.id === props.id);
            cards.value = cards.value.map((c) => {
                var _a;
                return ({
                    ...c,
                    selected: c.id === ((_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.select),
                });
            });
        };
        const { flowData } = usePdpFlow();
        watch(flowData, (data) => {
            if (data) {
                syncFlowData(data);
            }
        }, { deep: true });
        const fetchContentfulData = async () => {
            var _a, _b, _c, _d;
            if (!((_a = props.product) === null || _a === void 0 ? void 0 : _a.sku))
                return;
            emit('group:loading', { id: props.id, loading: true });
            await search({
                custom: {
                    type: 'pdpCardGroup',
                    field: 'name',
                    value: `select-prescription-type-${(_b = props.product) === null || _b === void 0 ? void 0 : _b.sku}`,
                },
            });
            const data = ((_d = (_c = content.value[0]) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.options) || PrescriptionTypeDefaults.options;
            const cardsTmp = data.map((item) => ({
                id: item.fields.id,
                title: item.fields.title,
                description: item.fields.description,
                component: item.fields.component,
                selected: false,
                disabled: false,
                nextEvent: item.fields.nextEvent,
                tags: item.fields.tags
                    ? item.fields.tags.map((tag) => ({
                        label: tag.fields.label,
                        color: tag.fields.color,
                        textColor: tag.fields.textColor,
                    }))
                    : [],
            }));
            cards.value = cardsTmp;
            if (flowData.value) {
                syncFlowData(flowData.value);
            }
            emit('group:loading', { id: props.id, loading: false });
        };
        useFetch(async () => {
            await fetchContentfulData();
        });
        watch(() => props.product, async (newProduct) => {
            if (!(newProduct === null || newProduct === void 0 ? void 0 : newProduct.sku))
                return;
            await fetchContentfulData();
        }, { deep: true });
        const prescriptionTypes = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.options) || PrescriptionTypeDefaults.options; });
        const prescriptionTypeTitle = computed(() => { var _a, _b; return ((_b = (_a = content.value[0]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.title) || PrescriptionTypeDefaults.title; });
        const getCardItem = (item, index) => {
            var _a;
            return ({
                ...item === null || item === void 0 ? void 0 : item.fields,
                id: item.id,
                selected: selectedItem.value
                    ? selectedItem.value.id === index
                    : (_a = item === null || item === void 0 ? void 0 : item.fields) === null || _a === void 0 ? void 0 : _a.selected,
            });
        };
        const handleClick = (card) => {
            cards.value = cards.value.map((c) => ({
                ...c,
                selected: c.id === card.id,
            }));
            emit('group:select', {
                select: card.id,
                id: props.id,
                price: card.price,
                nextEvent: card.nextEvent,
            });
        };
        const productItemMatched = (item) => {
            var _a, _b;
            const items = (_a = props.product) === null || _a === void 0 ? void 0 : _a.items;
            if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
                const hasItem = (_b = props.product) === null || _b === void 0 ? void 0 : _b.items[0].options.find((option) => option.product.sku === item.id);
                if (hasItem)
                    return hasItem;
            }
            return null;
        };
        const regularItemPrice = (item) => { var _a; return Number((_a = productItemMatched(item)) === null || _a === void 0 ? void 0 : _a.product.price_range.maximum_price.regular_price.value); };
        const finalItemPrice = (item) => { var _a; return Number((_a = productItemMatched(item)) === null || _a === void 0 ? void 0 : _a.product.price_range.maximum_price.final_price.value); };
        const hasDiscountPrice = (item) => {
            if (regularItemPrice(item) !== finalItemPrice(item))
                return true;
            return false;
        };
        return {
            cards,
            prescriptionTypes,
            prescriptionTypeTitle,
            handleClick,
            getCardItem,
            hasDiscountPrice,
            regularItemPrice,
            finalItemPrice,
        };
    },
});
