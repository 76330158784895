import Vue from 'vue';
export default Vue.extend({
    name: 'StyleGuide',
    props: {
        color_primary: {
            type: String,
        },
        color_primary_variant: {
            type: String,
        },
        color_secondary: {
            type: String,
        },
        color_secondary_variant: {
            type: String,
        },
        color_text: {
            type: String,
        },
        color_info: {
            type: String,
        },
        color_success: {
            type: String,
        },
        color_warning: {
            type: String,
        },
        color_danger: {
            type: String,
        },
        font_primary: {
            type: String,
        },
        font_primary_import_url: {
            type: String,
        },
        font_secondary: {
            type: String,
        },
        font_secondary_import_url: {
            type: String,
        },
        font_weight_light: {
            type: String,
        },
        font_weight_normal: {
            type: String,
        },
        font_weight_medium: {
            type: String,
        },
        font_weight_semibold: {
            type: String,
        },
        font_weight_bold: {
            type: String,
        },
        font_size_xs: {
            type: String,
        },
        font_size_sm: {
            type: String,
        },
        font_size_base: {
            type: String,
        },
        font_size_lg: {
            type: String,
        },
        button_radius: {
            type: String,
        },
        button_hover: {
            type: String,
        },
        button_pressed: {
            type: String,
        },
        button_secondary_hover: {
            type: String,
        },
        button_secondary_pressed: {
            type: String,
        },
    },
    head() {
        const link = [];
        if (this.font_primary_import_url) {
            link.push({
                rel: 'stylesheet',
                href: this.font_primary_import_url,
            });
        }
        if (this.font_secondary_import_url) {
            link.push({
                rel: 'stylesheet',
                href: this.font_secondary_import_url,
            });
        }
        return {
            link,
        };
    },
});
