import { Logger } from '~/helpers/logger';
export const updateCartItemsCommand = {
    execute: async (context, { currentCart, product, updates, customQuery = { updateCartItems: 'updateCartItems' }, customHeaders = {}, }) => {
        Logger.debug('[Magento]: Update product items on cart', {
            product,
            updates,
            currentCart,
        });
        const updateCartParams = {
            cart_id: currentCart.id,
            cart_items: [
                {
                    cart_item_uid: product.uid,
                    quantity: updates.quantity,
                    customizable_options: updates.customizable_options.map((customizableOption) => {
                        const { uid } = customizableOption;
                        const decoded = atob(uid);
                        const index = decoded.slice(decoded.lastIndexOf('/') + 1);
                        return {
                            id: Number(index),
                            value_string: customizableOption.value.toString(),
                        };
                    }) || [],
                },
            ],
        };
        const { data } = await context.$magento.api.updateCartItems(updateCartParams, customQuery, customHeaders);
        Logger.debug('[Result]:', { data });
        return data
            .updateCartItems
            .cart;
    },
};
