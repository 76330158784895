import { ref, computed, defineComponent, toRef, watch, onMounted, } from '@nuxtjs/composition-api';
import { getName as getProductName, getPrice as getProductPrice, } from '~/modules/catalog/product/getters/productGetters';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { ZnButton, ZnCarousel, ZnModal, ZnCurrency, } from '~/modules/b2b/components';
import { useB2BUiState } from '~/modules/b2b/composables';
import { PdpPrescriptionFlow } from '~/modules/b2b/prescription/components';
import { usePdpFlow, usePrescription, emptyPrescription } from '~/modules/b2b/prescription/composables';
import ProductSkeleton from '~/modules/catalog/product/components/ProductSkeleton.vue';
import { useApi } from '~/composables';
import { merge } from 'lodash-es';
import getProductPriceBySkuGql from '~/modules/catalog/product/queries/getProductPriceBySku.gql';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
export default defineComponent({
    name: 'SimpleProduct',
    components: {
        ProductSkeleton,
        PdpPrescriptionFlow,
        ZnButton,
        ZnCarousel,
        ZnModal,
        ZnCurrency,
    },
    transition: 'fade',
    props: {
        product: {
            type: [Object, null],
            default: null,
        },
        isFetching: {
            type: Boolean,
            default: true,
        },
        currency: {
            type: [Object, null],
            default: null,
        },
    },
    emits: ['loading'],
    setup(props, { emit }) {
        const qty = ref(1);
        const loading = ref(true);
        const product = toRef(props, 'product');
        const pageTitle = computed(() => 'Prescription VR Lenses for Hypnos headset');
        const { addItem, error: cartError, loading: isCartLoading, canAddToCart, } = useCart();
        const { productGallery, imageSizes } = useProductGallery(product);
        const { isAuthenticated } = useUser();
        const { addOrRemoveItem, isInWishlist } = useWishlist();
        const { goTo } = useB2BUiState();
        const productShortDescription = computed(() => { var _a, _b; return ((_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.short_description) === null || _b === void 0 ? void 0 : _b.html) || ''; });
        const productDescription = computed(() => { var _a, _b; return ((_b = (_a = props.product) === null || _a === void 0 ? void 0 : _a.description) === null || _b === void 0 ? void 0 : _b.html) || ''; });
        const productPrice = computed(() => getProductPrice(props.product).regular);
        const productSpecialPrice = computed(() => getProductPrice(props.product).special);
        const addToCartError = computed(() => { var _a, _b; return (_b = (_a = cartError.value) === null || _a === void 0 ? void 0 : _a.addItem) === null || _b === void 0 ? void 0 : _b.message; });
        const { flowData, clearFlowData, isPrescriptionValid } = usePdpFlow();
        const { setPrescription } = usePrescription();
        const modalData = ref({
            title: '',
            description: '',
        });
        const modal = ref(null);
        const computedLoading = computed(() => loading.value || isCartLoading.value || props.isFetching);
        const bundleProduct = ref(null);
        const addToCartItem = async () => {
            // verify if prescription is validated
            if (!isPrescriptionValid.value) {
                modalData.value.title = 'Prescription not validated';
                modalData.value.description = 'Please validate your prescription before adding to cart';
                modal.value.open();
                return;
            }
            if (flowData.value) {
                emit('loading', true);
                const items = {
                    product: {
                        ...bundleProduct.value,
                        bundle_options: [
                            {
                                uid: bundleProduct.value.items[0].options[0].uid,
                                value: 1,
                            },
                            {
                                uid: bundleProduct.value.options.find((option) => option.title === 'PendingPrescription').uid,
                                value: true,
                            },
                        ],
                    },
                    quantity: 1,
                    uid: bundleProduct.value.uid,
                };
                await addItem(items);
                clearFlowData({ withoutRefresh: true });
                setPrescription(emptyPrescription);
                await goTo({ path: '/cart' });
            }
        };
        const fixed = ref(false);
        const defaultProductGalleryWidth = ref(0);
        const produtInfoWidth = ref(0);
        watch(isCartLoading, (val) => {
            // emit('loading', val);
        });
        const { query } = useApi();
        const { getProductDetails } = useProduct();
        onMounted(async () => {
            var _a, _b;
            const bundleQuery = {
                filter: {
                    sku: {
                        eq: 'vr-lens-bundle',
                    },
                },
            };
            const result = await getProductDetails(bundleQuery);
            const { data } = await query(getProductPriceBySkuGql, bundleQuery);
            bundleProduct.value = merge({}, result.items[0], (_b = (_a = data.products) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[0]);
        });
        return {
            fixed,
            loading,
            addItem,
            addItemToWishlist: addOrRemoveItem,
            isPrescriptionValid,
            canAddToCart,
            computedLoading,
            pageTitle,
            isAuthenticated,
            isInWishlist: computed(() => isInWishlist({ product: props.product })),
            productShortDescription,
            productDescription,
            productGallery,
            getProductName,
            productPrice,
            productSpecialPrice,
            qty,
            imageSizes,
            addToCartError,
            addToCartItem,
            defaultProductGalleryWidth,
            produtInfoWidth,
            modalData,
            bundleProduct,
            modal,
        };
    },
});
