import { defineComponent } from '@nuxtjs/composition-api';
import { SfRating } from '@storefront-ui/vue';
import { starSvgPath } from '../beast/star-sgv-path';
export default defineComponent({
    name: 'ZnRating',
    components: {
        SfRating,
    },
    props: {
        max: {
            type: Number,
            required: true,
        },
        score: {
            type: [Number, String],
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: undefined,
        },
    },
    setup() {
        return {
            starSvgPath,
        };
    },
});
