import { Logger } from '~/helpers/logger';
export const loadTotalQtyCommand = {
    execute: async (context, params) => {
        var _a, _b, _c, _d;
        Logger.debug('[Magento]: Load cart total qty');
        const apiState = context.$magento.config.state;
        if (apiState.getCartId()) {
            const { data } = await context.$magento.api.cartTotalQty(apiState.getCartId(), (_a = params === null || params === void 0 ? void 0 : params.customQuery) !== null && _a !== void 0 ? _a : null, (_b = params === null || params === void 0 ? void 0 : params.customHeaders) !== null && _b !== void 0 ? _b : null);
            return (_d = (_c = data === null || data === void 0 ? void 0 : data.cart) === null || _c === void 0 ? void 0 : _c.total_quantity) !== null && _d !== void 0 ? _d : 0;
        }
        return 0;
    },
};
