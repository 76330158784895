import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
export default defineComponent({
    components: {
        SfButton,
    },
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
});
