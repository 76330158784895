import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
const urlResolverMiddleware = async (context) => {
    var _a;
    const pageStore = usePageStore();
    const { path } = context.route;
    const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
    Logger.debug('middleware/url-resolver', clearUrl);
    const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);
    Logger.debug('middleware/url-resolver/result', { data, errors });
    const results = (_a = data === null || data === void 0 ? void 0 : data.route) !== null && _a !== void 0 ? _a : null;
    if (!results || (errors === null || errors === void 0 ? void 0 : errors.length))
        context.error({ statusCode: 404 });
    pageStore.$patch((state) => {
        state.routeData = results;
    });
};
export default urlResolverMiddleware;
