import { ref, readonly, computed, useContext, } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { useUiNotification } from '~/composables/useUiNotification';
/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser() {
    const customerStore = useCustomerStore();
    // @ts-ignore
    const { app, $recaptcha } = useContext();
    const { setCart } = useCart();
    const { send: sendNotification } = useUiNotification();
    const loading = ref(false);
    const errorsFactory = () => ({
        updateUser: null,
        register: null,
        login: null,
        logout: null,
        changePassword: null,
        load: null,
    });
    const error = ref(errorsFactory());
    const setUser = (newUser) => {
        customerStore.user = newUser;
        Logger.debug('useUserFactory.setUser', newUser);
    };
    const resetErrorValue = () => {
        error.value = errorsFactory();
    };
    const updateCustomerEmail = async (credentials) => {
        const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);
        if (errors) {
            throw errors.map((e) => e.message).join(',');
        }
    };
    // eslint-disable-next-line consistent-return
    const updateUser = async ({ user: providedUser, customQuery, customHeaders }) => {
        var _a;
        Logger.debug('[Magento] Update user information', { providedUser, customQuery, customHeaders });
        resetErrorValue();
        try {
            loading.value = true;
            const { email: oldEmail } = customerStore.user;
            const { email, password, ...updateData } = providedUser;
            const userData = generateUserData(updateData);
            if (email && email !== oldEmail) {
                await updateCustomerEmail({
                    email,
                    password,
                });
            }
            const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData, customQuery, customHeaders);
            Logger.debug('[Result]:', { data });
            if (errors) {
                const allErrorMessages = errors.map((e) => e.message).join(',');
                Logger.error(allErrorMessages);
                error.value.updateUser = allErrorMessages;
            }
            customerStore.user = ((_a = data === null || data === void 0 ? void 0 : data.updateCustomerV2) === null || _a === void 0 ? void 0 : _a.customer) || {};
            error.value.updateUser = null;
        }
        catch (err) {
            error.value.updateUser = err;
            Logger.error('useUser/updateUser', err);
        }
        finally {
            loading.value = false;
        }
    };
    const logout = async ({ customQuery = {}, customHeaders = {} } = {}) => {
        Logger.debug('[Magento] useUserFactory.logout');
        resetErrorValue();
        try {
            const apiState = app.context.$vsf.$magento.config.state;
            await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery, customHeaders);
            apiState.removeCustomerToken();
            apiState.removeCartId();
            setCart(null);
            customerStore.setIsLoggedIn(false);
            error.value.logout = null;
            customerStore.user = null;
        }
        catch (err) {
            error.value.logout = err;
            Logger.error('useUser/logout', err);
        }
    };
    const load = async ({ customQuery = {}, customHeaders = {} } = {}) => {
        var _a;
        Logger.debug('[Magento] useUser.load');
        resetErrorValue();
        try {
            loading.value = true;
            const apiState = app.context.$vsf.$magento.config.state;
            if (!apiState.getCustomerToken()) {
                return null;
            }
            try {
                const { data } = await app.context.$vsf.$magento.api.customer(customQuery, customHeaders);
                Logger.debug('[Result]:', { data });
                customerStore.user = (_a = data === null || data === void 0 ? void 0 : data.customer) !== null && _a !== void 0 ? _a : {};
            }
            catch {
                // eslint-disable-next-line no-void
                // @ts-ignore
                await logout();
            }
            error.value.load = null;
        }
        catch (err) {
            error.value.load = err;
            Logger.error('useUser/load', err);
        }
        finally {
            loading.value = false;
        }
        return customerStore.user;
    };
    // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
    const login = async ({ user: providedUser, customQuery, customHeaders }) => {
        Logger.debug('[Magento] useUser.login', providedUser);
        resetErrorValue();
        try {
            loading.value = true;
            const apiState = app.context.$vsf.$magento.config.state;
            const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken({
                email: providedUser.email,
                password: providedUser.password,
                recaptchaToken: providedUser.recaptchaToken,
            }, customQuery || {}, customHeaders || {});
            Logger.debug('[Result]:', { data });
            if (errors) {
                const joinedErrors = errors.map((e) => e.message).join(',');
                Logger.error(joinedErrors);
                errors.forEach((registerError, i) => sendNotification({
                    icon: 'error',
                    id: Symbol(`registration_error-${i}`),
                    message: registerError.message,
                    persist: true,
                    title: 'Registration error',
                    type: 'danger',
                }));
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                throw new Error(joinedErrors);
            }
            customerStore.setIsLoggedIn(true);
            apiState.setCustomerToken(data.generateCustomerToken.token);
            // merge existing cart with customer cart
            // todo: move this logic to separate method
            const currentCartId = apiState.getCartId();
            const cart = await app.context.$vsf.$magento.api.customerCart();
            const newCartId = cart.data.customerCart.id;
            try {
                if (newCartId && currentCartId && currentCartId !== newCartId) {
                    const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
                        sourceCartId: currentCartId,
                        destinationCartId: newCartId,
                    });
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    setCart(dataMergeCart.mergeCarts);
                    apiState.setCartId(dataMergeCart.mergeCarts.id);
                }
                else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    setCart(cart.data.customerCart);
                }
            }
            catch {
                // Workaround for Magento 2.4.4 mergeCarts mutation error related with Bundle products
                // It can be removed when Magento 2.4.5 will be release
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                setCart(cart.data.customerCart);
            }
            error.value.login = null;
        }
        catch (err) {
            error.value.login = err;
            Logger.error('useUser/login', err);
        }
        finally {
            loading.value = false;
        }
    };
    // eslint-disable-next-line consistent-return
    const register = async ({ user: providedUser, customQuery, customHeaders }) => {
        Logger.debug('[Magento] useUser.register', providedUser);
        resetErrorValue();
        try {
            loading.value = true;
            const { email, password, recaptchaToken, ...baseData } = generateUserData(providedUser);
            const { data, errors } = await app.$vsf.$magento.api.createCustomer({
                email,
                password,
                recaptchaToken,
                ...baseData,
            }, customQuery || {}, customHeaders || {});
            Logger.debug('[Result]:', { data });
            if (errors) {
                const joinedErrors = errors.map((e) => e.message).join(',');
                Logger.error(joinedErrors);
                errors.forEach((registerError, i) => sendNotification({
                    icon: 'error',
                    id: Symbol(`registration_error-${i}`),
                    message: registerError.message,
                    persist: true,
                    title: 'Registration error',
                    type: 'danger',
                }));
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                throw new Error(joinedErrors);
            }
            error.value.register = null;
            let loginRecaptchaToken = '';
            if ($recaptcha && recaptchaToken) {
                loginRecaptchaToken = await $recaptcha.getResponse();
            }
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { customer: { customer_create_account_confirm } } = app.context.$vsf.$magento.config;
            if (customer_create_account_confirm) {
                return await new Promise((resolve) => {
                    sendNotification({
                        id: Symbol('registration_confirmation'),
                        message: app.i18n.t('You must confirm your account. Please check your email for the confirmation link.'),
                        persist: true,
                        title: 'Registration confirmation',
                        type: 'success',
                        icon: 'check',
                    });
                    resolve();
                });
            }
            await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} });
        }
        catch (err) {
            error.value.register = err;
            Logger.error('useUser/register', err);
        }
        finally {
            loading.value = false;
        }
    };
    // eslint-disable-next-line consistent-return
    const changePassword = async (params) => {
        Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
        resetErrorValue();
        try {
            loading.value = true;
            const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
                currentUser: customerStore.user,
                currentPassword: params.current,
                newPassword: params.new,
            }, params.customQuery, params === null || params === void 0 ? void 0 : params.customHeaders);
            let joinedErrors = null;
            if (errors) {
                joinedErrors = errors.map((e) => e.message).join(',');
                Logger.error(joinedErrors);
            }
            Logger.debug('[Result] ', { data });
            customerStore.user = data === null || data === void 0 ? void 0 : data.changeCustomerPassword;
            error.value.changePassword = joinedErrors;
        }
        catch (err) {
            error.value.changePassword = err;
            Logger.error('useUser/changePassword', err);
        }
        finally {
            loading.value = false;
        }
    };
    return {
        setUser,
        updateUser,
        register,
        login,
        logout,
        changePassword,
        load,
        loading: readonly(loading),
        error: readonly(error),
        user: computed(() => customerStore.user),
        isAuthenticated: computed(() => customerStore.isLoggedIn),
    };
}
export default useUser;
export * from './useUser';
